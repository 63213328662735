// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react"
import { submitPost941X, initialState941, prepareForm } from "./APIFunctions"
import Post941XPart1 from "./Basic941Components/Post941XPart1"
import Post941XPart2 from "./Basic941Components/Post941XPart2"
import Post941XPart3 from "./Basic941Components/Post941XPart3"

// Bootstrap
import Button from "react-bootstrap/Button"

export const ERCPost941XContext = React.createContext({})

export default function Post941X({
  url,
  id,
  form_authenticity_token,
  partOne,
  partTwo,
  record,
}) {
  console.log(record)
  const [state, setState] = React.useState({
    ...initialState941({ record }),
    quarter: record.quarter,
  })
  const [form, setForm] = React.useState({})
  const [submitting, setSubmitting] = React.useState(false)
  const formTitleArr = record.quarter.split("_")
  const formTitle = formTitleArr[0] + " " + formTitleArr[1]

  React.useEffect(() => {
    const data = {
      ...state.partOne,
      ...state.partTwo,
      ...state.partThree,
      ...state.calculated.partOne,
      ...state.calculated.partTwo,
    }
    setForm({ form941x: prepareForm(data) })

    console.log("State/Context", state, data)
  }, [state])

  React.useEffect(() => console.log("Form", form), [form])

  const submitForm = () => {
    const values = { ...form }
    const options = { url, form_authenticity_token, values }
    submitPost941X(options)
    setSubmitting(false)
  }

  return (
    <div className="container pb-5 all-font">
      <div className="row">
        <ERCPost941XContext.Provider value={[state, setState]}>
          <div className="col-md-12">
            <h2>
              {formTitle} {record.form}
            </h2>
            <form action={url} method="post">
              <Post941XPart1 />
              <Post941XPart2 />
              <Post941XPart3 />
            </form>
          </div>
          <Button
            type="reset"
            className="btn add-sus my-4 py-2 col-sm-2 offset-5"
            onClick={() => {
              submitForm()
              setSubmitting(true)
            }}
          >
            Submit
          </Button>
          {submitting ? (
            <h4 className="text-center">Submitting, Please Wait.</h4>
          ) : (
            ""
          )}
        </ERCPost941XContext.Provider>
      </div>
    </div>
  )
}
