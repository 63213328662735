import * as React from "react"
import { Form, Dropdown } from "react-bootstrap"
import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"

export default function QualDateRange({
  //dateRange = {},
  //date_ranges,
  dateRangeState,
  setDateRangeState,

  //setdateRangeAPIState,
  //index,
}) {
  const updateFieldDropdown = (event, key) => {
    event.preventDefault()
    const targetValue = event.target.textContent
    setDateRangeState((state) => ({
      ...state,

      [key]: targetValue,
    }))
  }

  const suspensionReasonHandler = (event) => {
    event.preventDefault()
    const targetReason = event.target.value
    setDateRangeState({ ...dateRangeState, suspension_reason: targetReason })
  }

  //   const updateField = (event) => {
  //     event.preventDefault()
  //     const targetValue = parseFloat(event.target.value)
  //     setDateRangeState((state) => ({
  //       ...state,
  //       [event.target.name]: targetValue,
  //     }))
  //   }

  const minDate = new Date(2019, 12, 1)
  const updateDate = (date, key) => {
    setDateRangeState((c) => ({ ...c, [key]: date }))
  }

  // console.log(dateRangeState.suspension_type)

  // React.useEffect(() => setdateRangeAPIState(dateRangeState), [dateRangeState])

  return (
    <Form className=" d-flex justify-content-center">
      <table className="w-75 table table-bordered ">
        <tbody>
          <tr>
            <th className="gr-table-head">
              Please enter the date ranges of all Partial or Full Suspensions
            </th>
            <th className="gr-table-head">Your Info</th>
          </tr>

          <tr>
            <th className="">Partial or Full Suspension</th>
            <td>
              <Dropdown>
                <Dropdown.Toggle className="add-sus" variant="" id="dropdown-basic">
                  {dateRangeState.suspension_type}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    name="Partial"
                    value="Partial"
                    onClick={(e) => updateFieldDropdown(e, "suspension_type")}
                  >
                    Partial Suspension
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="Full"
                    value="Full"
                    onClick={(e) => updateFieldDropdown(e, "suspension_type")}
                  >
                    Full Suspension
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
          <tr>
            <th className="">Start of Suspension</th>
            <td>
              <DatePicker
                selected={dateRangeState.date_range_start}
                onChange={(date) => updateDate(date, "date_range_start")}
                minDate={minDate}
              />
            </td>
          </tr>

          <tr>
            <th className="">End of Suspension</th>
            <td>
              <DatePicker
                selected={dateRangeState.date_range_end}
                onChange={(date) => updateDate(date, "date_range_end")}
                minDate={minDate}
              />
            </td>
          </tr>
          <tr>
            <th className="">Reasoning For Suspension</th>
            <td>
              <Form>
                <Form.Control
                  as="textarea"
                  value={dateRangeState.suspension_reason}
                  placeholder="Explain why you were partially or fully suspended. Please reference government orders, state or local, that impacted your qualification."
                  style={{ height: "100px" }}
                  onChange={(e) => suspensionReasonHandler(e)}
                />
              </Form>
            </td>
          </tr>
        </tbody>
      </table>
    </Form>
  )
}
