// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react"
import { submitBasic9412021 } from "./APIFunctions"
import Basic9412021Part1 from "./Basic941Components/Basic9412021Part1"
import Basic9412021Part2 from "./Basic941Components/Basic9412021Part2"
import Basic9412021Part3 from "./Basic941Components/Basic9412021Part3"

// Bootstrap
import Button from "react-bootstrap/Button"

export const ERCBasic941Context = React.createContext({})

export default function Basic9412021({
  url,
  id,
  form_authenticity_token,
  partOne,
  partTwo,
  partThree,
}) {
  const initialState = {
    partOne,
    partTwo,
    partThree,
    calculated: {
      partOne: {},
      partTwo: {},
      partThree: {},
    },
  }

  const [state, setState] = React.useState(initialState)

  React.useEffect(() => {
    console.log("HOC State", state)
  }, [state])

  const submitForm = () => {
    const values = { ...state.partOne, ...state.partTwo, ...state.partThree }
    const options = { url, form_authenticity_token, values }
    submitBasic9412021(options)
  }

  return (
    <ERCBasic941Context.Provider value={[state, setState]}>
      <Basic9412021Part1 />
      <Basic9412021Part2 />
      <Basic9412021Part3 />
      <Button type="reset" className="btnReset col-sm-1" onClick={submitForm}>
        Submit
      </Button>
    </ERCBasic941Context.Provider>
  )
}
