// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from 'react';
import moment from 'moment'
import Timeline from 'react-calendar-timeline'

import 'react-calendar-timeline/lib/Timeline.css'

const groups = [
  { id: 1, title: 'group 1' },
  { id: 2, title: 'group 2' }
]

const items = [
  {
    id: 1,
    group: 1,
    title: 'item 1',
    start_time: moment(),
    end_time: moment().add(1, 'month')
  },
  {
    id: 2,
    group: 2,
    title: 'item 2',
    start_time: moment().add(-0.5, 'month'),
    end_time: moment().add(0.5, 'month')
  },
  {
    id: 3,
    group: 1,
    title: 'item 3',
    start_time: moment().add(2, 'month'),
    end_time: moment().add(3, 'month')
  }
]

export default function PPPvERC() {
  return (
    <div>
      <Timeline
        groups={groups}
        items={items}
        timeSteps={{ month: 12 }}
        defaultTimeStart={moment().add(2, 'year')}
        defaultTimeEnd={moment().add(1, 'year')}
      />
    </div>
  )
}