// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import React from 'react';

export default function CsvTable({ headers, csvHeaders, records, setHeaders }) {
  const setHeaderIndex = (header, event) => {
    const value = event.target.value
    const newHeader = { ...header, index: value }
    const index = headers.indexOf(header)
    const newHeaders = [...headers]

    newHeaders.splice(index, 1, newHeader)
    setHeaders(newHeaders)
  }

  return (
    <table className="table ">
      <thead className="all-table-head">
        {headers.map(header => (
          <th >
            {header.name}<br />

            <select className="white-text" name="" id="" onChange={(e) => setHeaderIndex(header, e)}>
              <option>Select Field</option>
              {csvHeaders.map(csvHeader =>
                <option className="dark-text" value={csvHeader}>
                  {csvHeader}
                </option>
              )}
            </select>
          </th>
        ))}
      </thead>
      <tbody>
        {records.slice(0, 20).map(record => {
          const firstHeader = headers[0]
          if (firstHeader.index && record.data[firstHeader.index] == "") {
            return;
          } else {
            return <tr>
              {headers.map(header => <td>{header.index && record.data[header.index]}</td>)}
            </tr>
          }
        })}
      </tbody>
    </table>
  )
}