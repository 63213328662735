// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react"
import { submitPre941X, initialState941, prepareForm } from "./APIFunctions"
import Pre941XPart1 from "./Basic941Components/Pre941XPart1"
import Pre941XPart2 from "./Basic941Components/Pre941XPart2"
import Pre941XPart3 from "./Basic941Components/Pre941XPart3"

// Bootstrap
import Button from "react-bootstrap/Button"

export const ERCPre941XContext = React.createContext({})

export default function Pre941X({
  url,
  id,
  form_authenticity_token,
  record,
  partOne,
  partTwo,
}) {
  const [state, setState] = React.useState({
    ...initialState941({ record }),
    quarter: record.quarter,
  })
  const [form, setForm] = React.useState({ form941x: {} })
  const [submitting, setSubmitting] = React.useState(false)
  const formTitleArr = record.quarter.split("_")
  const formTitle = formTitleArr[0] + " " + formTitleArr[1]

  React.useEffect(() => {
    console.log("State/Context", state)

    setForm({
      form941x: {
        ...prepareForm(state.partOne),
        ...prepareForm(state.partTwo),
        ...prepareForm(state.partThree),
        ...prepareForm(state.calculated.partOne),
        ...prepareForm(state.calculated.partTwo),
      },
    })
  }, [state])

  React.useEffect(() => console.log("Form", form), [form])

  const submitForm = () => {
    const values = { ...form }
    const options = { url, form_authenticity_token, values }
    submitPre941X(options)
    setSubmitting(false)
  }

  return (
    <div className="container pb-5 all-font">
      <div className="row">
        <ERCPre941XContext.Provider value={[state, setState]}>
          <div className="col-md-12">
            <h2>
              {formTitle} {record.form}
            </h2>
            <form action={url} method="post">
              <Pre941XPart1 />
              <Pre941XPart2 />
              <Pre941XPart3 />
            </form>
          </div>
          <Button
            type="reset"
            className="btn add-sus my-4 py-2 col-sm-2 offset-5"
            onClick={() => {
              submitForm()
              setSubmitting(true)
            }}
          >
            Submit
          </Button>
          {submitting ? (
            <h4 className="text-center">Submitting, Please Wait.</h4>
          ) : (
            ""
          )}
        </ERCPre941XContext.Provider>
      </div>
    </div>
  )
}
