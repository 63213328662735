// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react"
import { Form } from "react-bootstrap"
import "../Basic941Functions/Basic941.css"

import { subTwoInputs } from "../Basic941Functions/Basic941Math"
import { sumFourInputs } from "../Basic941Functions/Basic941Math"

import ReactTooltip from "react-tooltip"
import { TooltipMessage } from "../Utilities"

import { ERCPre941XContext } from "../Pre941X"

const Pre941XPart2 = () => {
  const [context, setContext]: any = React.useContext(ERCPre941XContext)

  const initialState: any = { ...context.partTwo }
  const [state, setState] = React.useState(initialState)

  const calculatedInitialState: any = {}
  const [calculated, setCalculated] = React.useState(calculatedInitialState)

  const [selectedRadioBtn1, setSelectedRadioBtn1] = React.useState(
    context.quarter.includes("2020") ? "2020" : "2021"
  )

  const isRadioSelected1 = (value: string): boolean =>
    selectedRadioBtn1 === value

  const handleRadioClick1 = (e: React.ChangeEvent<HTMLInputElement>): void => {
    console.log(e)
    setSelectedRadioBtn1(e.currentTarget.value)
  }

  const updateField = event => {
    event.preventDefault()
    const targetValue = parseFloat(event.target.value)

    setState(state => ({
      ...state,
      [event.target.name]: targetValue,
    }))
  }

  const isInputNaN = value => {
    return isNaN(value) ? "" : value
  }
  //Calculates two E
  React.useEffect(() => {
    const { twoA, twoB, twoC, twoD } = state

    const calcTwoE: number = sumFourInputs(twoA, twoB, twoC, twoD)

    setCalculated(c => ({
      ...c,
      calcTwoE,
    }))
  }, [state.twoA, state.twoB, state.twoC, state.twoD])

  //Calculates two F
  React.useEffect(() => {
    const { calcTwoE } = calculated

    if (selectedRadioBtn1 === "2020") {
      const calcTwoF = calcTwoE * 0.5
      setCalculated(c => ({
        ...c,
        calcTwoF,
      }))
    } else if (selectedRadioBtn1 === "2021") {
      const calcTwoF = calcTwoE * 0.7
      // Round to 2 decimal places
      const calcTwoF_Rounded = Math.round(calcTwoF * 100) / 100

      setCalculated(c => ({
        ...c,
        calcTwoF: calcTwoF_Rounded,
      }))
    }
  }, [calculated.calcTwoE, selectedRadioBtn1])

  //Calculates two I
  React.useEffect(() => {
    const { twoH } = state

    const calcTwoI: number = subTwoInputs(
      context.calculated.partOne.calcOneN,
      twoH
    )

    setCalculated(c => ({
      ...c,
      calcTwoI,
    }))
  }, [context.calculated.partOne.calcOneN, state.twoH])

  //Calculates two J
  React.useEffect(() => {
    const { calcTwoF, calcTwoI } = calculated
    if (calcTwoF <= calcTwoI) {
      const calcTwoJ = calcTwoF

      setCalculated(c => ({
        ...c,
        calcTwoJ,
      }))
    } else {
      const calcTwoJ = calcTwoI

      setCalculated(c => ({
        ...c,
        calcTwoJ,
      }))
    }
  }, [calculated.calcTwoF, calculated.calcTwoI])

  React.useEffect(() => {
    const { calcTwoF, calcTwoJ } = calculated

    const calcTwoK = subTwoInputs(calcTwoF, calcTwoJ)
    // Round to 2 decimal places
    const calcTwoK_Rounded = Math.round(calcTwoK * 100) / 100

    setCalculated(c => ({
      ...c,
      calcTwoK: calcTwoK_Rounded,
    }))
  }, [calculated.calcTwoF, calculated.calcTwoJ])

  React.useEffect(() => {
    console.log(selectedRadioBtn1)
  }, [selectedRadioBtn1])

  React.useEffect(() => {
    setContext({ ...context, partTwo: state })
  }, [state])

  React.useEffect(() => {
    setContext({
      ...context,
      calculated: { ...context.calculated, partTwo: calculated },
    })
  }, [calculated])

  return (
    <div className="all-font">
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th className="all-table-head">Part 2</th>
            <th className="all-table-head">
              Figure the Employee Retention Credit: Caution: For qualified wages
              paid before January 1, 2021, the cumulative total for all quarters
              of 2020 that can be claimed on lines 2a, 2b, 2c, and 2d can't
              exceed $10,000 per employee. For qualified wages paid after
              December 31, 2020, the total amount included on lines 2a and 2b
              can't exceed $10,000 per employee each quarter.
            </th>
            <th className="all-table-head">Calculations Left</th>
            <th className="all-table-head">Calculations Right</th>
          </tr>
          <tr>
            <th className=""></th>
            <th className="">
              Caution: For qualified wages paid before January 1, 2021, the
              cumulative total for all quarters of 2020 that can be claimed on
              lines 2a, 2b, 2c, and 2d can't exceed $10,000 per employee. For
              qualified wages paid after December 31, 2020, the total amount
              included on lines 2a and 2b can't exceed $10,000 per employee each
              quarter.
            </th>
            <td className="table-active"></td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 2a</th>
            <th className="">
              Qualified wages (excluding qualified health plan expenses) for the
              employee retention credit (Form 941, Part 3, line 21, or, if
              corrected, Form 941-X, line 30, column 1). Enter this information
              on Form 941-X, line 30.{" "}
              <TooltipMessage
                message={
                  <p>
                    "{" "}
                    <a
                      target="_blank"
                      href="https://www.irs.gov/newsroom/covid-19-related-employee-retention-credits-determining-qualified-wages-faqs"
                    >
                      Qualified wages{" "}
                    </a>
                    are any wages and compentation you pay or incur for services
                    performed by a qualified employee over the entire quarter. "{" "}
                  </p>
                }
              />
            </th>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoA"
                value={isInputNaN(state.twoA)}
                onChange={e => updateField(e)}
              />
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 2b</th>
            <th className="">
              Qualified health plan expenses allocable to qualified wages for
              the employee retention credit (Form 941, Part 3, line 22, or, if
              corrected, Form 941-X, line 31a, column 1). Enter on Form 941-X,
              line 31a.{" "}
              <TooltipMessage
                message={
                  <p>
                    <a
                      target="_blank"
                      href="https://www.irs.gov/newsroom/covid-19-related-employee-retention-credits-amount-of-allocable-qualified-health-plan-expenses-faqs"
                    >
                      Qualified health plan expenses allocable to qualified
                      wages{" "}
                    </a>
                    are amounts paid or incurred by the Eligible Employer to
                    provide and maintain a group health plan, but only to the
                    extent that those amounts are excluded from the gross income
                    of employees.{" "}
                  </p>
                }
              />
            </th>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoB"
                value={isInputNaN(state.twoB)}
                onChange={e => updateField(e)}
              />
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 2c</th>
            <th className="">
              Qualified wages (excluding qualified health plan expenses) paid
              March 13, 2020, through March 31, 2020, for the employee retention
              credit (Form 941, Part 3, line 24, or, if corrected, Form 941-X,
              line 33a, column 1). Enter an amount here only if filing a Form
              941-X to correct the second quarter of 2020. Enter on Form 941-X,
              line 33a if applicable.{" "}
              <TooltipMessage
                message={
                  <p>
                    <a
                      target="_blank"
                      href="https://www.irs.gov/newsroom/covid-19-related-employee-retention-credits-determining-qualified-wages-faqs"
                    >
                      Qualified wages{" "}
                    </a>{" "}
                    are any wages and compentation you pay or incur for services
                    performed by a qualified employee over the entire quarter.{" "}
                  </p>
                }
              />
            </th>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoC"
                value={isInputNaN(state.twoC)}
                onChange={e => updateField(e)}
              />
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 2d</th>
            <th className="">
              Qualified health plan expenses allocable to qualified wages paid
              March 13, 2020, through March 31, 2020, for the employee retention
              credit (Form 941, Part 3, line 25, or, if corrected, Form 941-X,
              line 34, column 1). Enter an amount here only if filing a Form
              941-X to correct the second quarter of 2020. Enter on Form 941-X,
              line 34 if applicable.{" "}
              <TooltipMessage
                message={
                  <p>
                    <a
                      target="_blank"
                      href="https://www.irs.gov/newsroom/covid-19-related-employee-retention-credits-amount-of-allocable-qualified-health-plan-expenses-faqs"
                    >
                      Qualified health plan expenses allocable to qualified
                      wages{" "}
                    </a>
                    are amounts paid or incurred by the Eligible Employer to
                    provide and maintain a group health plan, but only to the
                    extent that those amounts are excluded from the gross income
                    of employees.{" "}
                  </p>
                }
              />
            </th>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoD"
                value={isInputNaN(state.twoD)}
                onChange={e => updateField(e)}
              />
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 2e</th>
            <th className="">Add lines 2a, 2b, 2c, and 2d </th>
            <td className="edit-field">{calculated.calcTwoE}</td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 2f</th>
            <th className="">
              Retention Credit. If you're correcting the second, third, or
              fourth quarter of 2020, multiply line 2e by 50% (0.50). If you're
              correcting the first or second quarter of 2021, multiply line 2e
              by 70% (0.70) 2d{" "}
              <TooltipMessage
                message="In 2020, credit can be claimed for 50% of qualified wages, up to $10,000 per employee paid between March 12 and December 31, 2020, for a maximum credit of $5,000 per employee.

In 2021 – credit can be claimed for 70% of qualified wages, up to $10,000 per employee paid per quarter in 2021, for a maximum credit of $28,000 per employee."
              />
            </th>
            <td className="">
              <Form.Group>
                <Form.Check
                  value="2020"
                  type="radio"
                  label="2020"
                  name="year_retention"
                  checked={isRadioSelected1("2020")}
                  onChange={handleRadioClick1}
                />
                <Form.Check
                  value="2021"
                  type="radio"
                  label="2021"
                  name="year_retention"
                  checked={isRadioSelected1("2021")}
                  onChange={handleRadioClick1}
                />
              </Form.Group>
            </td>
            <td className="edit-field">{calculated.calcTwoF}</td>
          </tr>
          <tr>
            <th className=""> 2g</th>
            <th className="">
              Enter the amount of the employer share of social security tax from
              Step 1, line 1a, or, if applicable, Step 1, line 1n
            </th>
            <td className="edit-field">
              {context.calculated.partOne.calcOneN || 0}
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 2h</th>
            <th className="">
              Enter the amount of the nonrefundable portion of the credit for
              qualified sick and family leave wages for leave taken before April
              1, 2021, from Worksheet 1, Step 2, line 2j
            </th>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoH"
                value={isInputNaN(state.twoH)}
                onChange={e => updateField(e)}
              />
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 2i</th>
            <th className="">Subtract line 2h from line 2g.</th>
            <td className="edit-field">{calculated.calcTwoI}</td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 2j</th>
            <th className="">
              Nonrefundable portion of employee retention credit. Enter the
              smaller of line 2f or line 2i. Enter this amount on Form 941-X,
              line 18a, column 1
            </th>
            <td className="table-active"></td>
            <td className="edit-field">{calculated.calcTwoJ}</td>
          </tr>
          <tr>
            <th className=""> 2k</th>
            <th className="">
              Refundable portion of employee retention credit. Subtract line 2j
              from line 2f and enter this amount on Form 941-X, line 26a, column
              1
            </th>
            <td className="table-active"></td>
            <td className="edit-field">{calculated.calcTwoK}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Pre941XPart2
