// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

// Default headers needed to submit form data via API (PUT POST PATCH)
export function defaultHeaders(form_authenticity_token) {
  return {
    "Content-Type": "application/json",
    "X-CSRF-Token": form_authenticity_token,
  }
}

export function submitToAPI(
  url,
  method,
  form_authenticity_token,
  data,
  callback
) {
  const response = fetch(url, {
    method,
    headers: defaultHeaders(form_authenticity_token),
    body: JSON.stringify(data),
  }).then(response => {
    console.log(response)
    if (response.ok) {
      const json = response.json()
      callback(json)
      return json
    }
  })

  return response
}

export function submitBasic9412021({ url, form_authenticity_token, values }) {
  const options = {
    method: "PUT",
    headers: defaultHeaders(form_authenticity_token),
    body: JSON.stringify(values),
  }

  fetch(url, options)
    .then(response => response.json())
    .then(response => {
      console.log("API Opts", options)
      console.log("API Response Body", response)
    })
}

export function submitBasic9412020({ url, form_authenticity_token, values }) {
  const options = {
    method: "PUT",
    headers: defaultHeaders(form_authenticity_token),
    body: JSON.stringify(values),
  }

  fetch(url, options)
    .then(response => response.json())
    .then(response => {
      console.log("API Opts", options)
      console.log("API Response Body", response)
    })
}

export function submitPre941X({ url, form_authenticity_token, values }) {
  const options = {
    method: "PUT",
    headers: defaultHeaders(form_authenticity_token),
    body: JSON.stringify(values),
  }

  fetch(url, options)
    .then(response => response.json())
    .then(response => {
      console.log("API Opts", options)
      console.log("API Response Body", response)
      const redirect_to = `/ertc_filings/${response.ertc_filing_id}`
      console.log("Redirecting to", redirect_to)
      window.location.href = redirect_to
    })
}

export function submitPost941X({ url, form_authenticity_token, values }) {
  const options = {
    method: "PUT",
    headers: defaultHeaders(form_authenticity_token),
    body: JSON.stringify(values),
  }

  fetch(url, options)
    .then(response => response.json())
    .then(response => {
      console.log("API Opts", options)
      console.log("API Response Body", response)
      const redirect_to = `/ertc_filings/${response.ertc_filing_id}`
      console.log("Redirecting to", redirect_to)
      window.location.href = redirect_to
    })
}

export function initialState941({ record }) {
  // Get attributes from record that start with "one"
  const one = Object.keys(record).filter(key => key.startsWith("one"))
  // Get values from records in array one
  const oneValues = one.map(key => record[key])
  // Change into an object
  const oneObject = one.reduce((acc, key, i) => {
    acc[key] = oneValues[i]
    return acc
  }, {})

  // Change every key in oneObject to replace underscore with ''
  const oneObjectClean = Object.keys(oneObject).reduce((acc, key) => {
    const newKey = key.replace(/_/g, "")
    // Uppercase last letter of newKey
    const newKeyLast =
      newKey.slice(0, -1) + newKey.charAt(newKey.length - 1).toUpperCase()

    acc[newKeyLast] = oneObject[key]
    return acc
  }, {})

  // Get attributes from record that start with "two"
  const two = Object.keys(record).filter(key => key.startsWith("two"))
  // Get values from records in array two
  const twoValues = two.map(key => record[key])
  // Change into an object
  const twoObject = two.reduce((acc, key, i) => {
    acc[key] = twoValues[i]
    return acc
  }, {})

  // Change every key in twoObject to replace underscore with ''
  const twoObjectClean = Object.keys(twoObject).reduce((acc, key) => {
    const newKey = key.replace(/_/g, "")
    // Get all but last letter of newKey
    const newKeyLast =
      newKey.slice(0, -1) + newKey.charAt(newKey.length - 1).toUpperCase()

    acc[newKeyLast] = twoObject[key]
    return acc
  }, {})

  // Get attributes from record that start with "two"
  const three = Object.keys(record).filter(key => key.startsWith("three"))
  // Get values from records in array two
  const threeValues = three.map(key => record[key])
  // Change into an object
  const threeObject = three.reduce((acc, key, i) => {
    acc[key] = threeValues[i]
    return acc
  }, {})

  // Change every key in twoObject to replace underscore with ''
  const threeObjectClean = Object.keys(threeObject).reduce((acc, key) => {
    const newKey = key.replace(/_/g, "")
    // Get all but last letter of newKey
    const newKeyLast =
      newKey.slice(0, -1) + newKey.charAt(newKey.length - 1).toUpperCase()
    acc[newKeyLast] = threeObject[key]
    return acc
  }, {})

  return {
    partOne: { ...oneObjectClean },
    partTwo: { ...twoObjectClean },
    partThree: { ...threeObjectClean },
    calculated: {
      partOne: {},
      partTwo: {},
    },
    recovery: record.recovery,
  }
}

export function prepareForm(obj) {
  const newObj = {}
  Object.keys(obj).forEach(key => {
    // Replace the word "calc" in key name
    const newKey = key.replace(/calc/gi, "").toLowerCase()

    // Get all but last letter of key
    const newKeyFirst = newKey.slice(0, -1)

    // Get last letter of key
    const lastLetter = key.charAt(key.length - 1).toLowerCase()

    newObj[`${newKeyFirst}_${lastLetter}`] = obj[key]
  })

  return newObj
}
