// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react"
import { submitToAPI } from "./APIFunctions"
import PPPNameRelationRow from "./MaximizePPP/PPPNameRelationRow"
import { TooltipMessage } from "./Utilities"

export interface PPPERCRowProp {
  id: number
  name: string
  owner: "No" | "Owner" | "Family"
}

const newPerson = (id, name) => ({
  id, name, owner: "No"
})




const initialPeopleState = (people) => {
  const newPeople = people.map((person, index) => {
    // If person exists, return newPerson(index, person)
    if (person) {
      return newPerson(index, person)
    }
  })

  return newPeople
}

// Upload Wage Records
function uploadEmployees(people, form_authenticity_token, ertc_filing_id) {
  const data = {
    ertc_filing_id: ertc_filing_id,
    employees: people
  }
  console.log("Data", data)


  // Submit the records to the server
  submitToAPI('/employees/import', 'POST', form_authenticity_token, data, (e) => console.log(e))
}

export default function PPPNameRelation({
  existing,
  people = [],
  form_authenticity_token,
  ertc_filing_id
}) {
  const [rows, setRows] = React.useState(initialPeopleState(people))
  const [loading, setLoadingState] = React.useState(false)

  const loadingHandler = () => {
    setLoadingState(true)

  }

  const endLoading = () => {
    setLoadingState(false)
  }

  // Check if row exists in "existing" array
  const rowExists = (name) => {
    console.log(name, existing)
    return existing.find(person => person.name === name)
  }

  const updateRow = (id, event) => {
    let newRows = [...rows]
    const index = rows.findIndex((row) => row.id == id)
    const { name, value } = event.target
    const fieldValue = name === "name" ? value : parseFloat(value)
    newRows[index][`${name}`] = fieldValue
    setRows(newRows)
  }

  const updateRowByKey = (id, values) => {
    let newRows = [...rows]
    const index = rows.findIndex((row) => row.id == id)
    newRows[index] = { ...newRows[index], ...values }
    setRows(newRows)
  }

  // Update rows if people changes
  React.useEffect(() => {
    const newRows = initialPeopleState(people).filter(row => row !== undefined)
    setRows(newRows)
  }, [people])

  return (
    <div >
      <div className="d-flex justify-content-center">
        <table className="table table-bordered w-50">
          <thead>
            <tr>
              <th className="all-table-head">Name</th>
              <th className="all-table-head">
                Owner or Family Considered from Attribution Rules
                <TooltipMessage message="SEE Related Party" />
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.length > 0 && rows.map((row) => (
              <PPPNameRelationRow
                rowExists={rowExists}
                key={row.id}
                {...row}
                updateMethod={(id, event) => updateRow(id, event)}
                updateRowByKey={updateRowByKey}
              />
            ))}
          </tbody>

        </table>
      </div>
      <button className="btn add-sus my-4" onClick={() => { uploadEmployees(rows, form_authenticity_token, ertc_filing_id); loadingHandler(); setTimeout(endLoading, 3000) }} >Submit Employees</button>
      {loading ? <div class="d-flex justify-content-center pb-3">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div> : ""}
    </div>
  )
}
