// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react"
import { SimpleERCWageTableContext } from "../SimpleMaximizePPP"
import { Dropdown, DropdownButton, Button } from "react-bootstrap"
import "../Basic941Functions/Basic941.css"

export default function ERCWageRow(props) {
  const {
    id,
    name,
    mv_wages,
    health_premium,
    total_wages_health,
    eligible_earnings,
    erc_calculation,
    updateMethod,
  } = props

  const [mount, setMount] = React.useState(false)

  const submitValue = (event) => {
    updateMethod(id, event)
  }

  const [context, setContext, extraObject] = React.useContext(
    SimpleERCWageTableContext
  )

  return (
    <tr>
      <td>
        <input
          type="text"
          name="name"
          id=""
          value={name}
          onChange={(event) => submitValue(event)}
        />
      </td>

      <td>
        <input
          type="number"
          name="mv_wages"
          id=""
          value={mv_wages}
          onChange={(event) => submitValue(event)}
        />
      </td>

      <td>
        <input
          type="number"
          name="health_premium"
          id=""
          value={health_premium}
          onChange={(event) => submitValue(event)}
        />
      </td>

      <td>{total_wages_health}</td>

      <td>{eligible_earnings}</td>
      <td>{erc_calculation}</td>
    </tr>
  )
}
