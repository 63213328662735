// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react"
import Button from "react-bootstrap/Button"
import { Form } from "react-bootstrap"
import "./QuantQual.css"
import {
  CalculationSubtract,
  CalculationPercentageInDifference,
  CalculationColorFifty,
  CalculationColorTwenty,
} from "./QuantFunctions/QuantMath"

import { submitToAPI } from "./APIFunctions"

import QualTable from "./QuantFunctions/QualTable"

import QualDateRange from "./QuantFunctions/QualDateRange"

import StepWizard from "react-step-wizard"

import {
  QuantState,
  QualifiedStateProps,
  QualifiedReasonProps,
  QualifiedReasonStateProps,
  CalculatedStateProps,
  PercentageStateProps,
  deltaAndPercentage21Props,
  deltaAndPercentageProps,
} from "./QuantFunctions/QuantInterfaces"

import GRTable from "./QuantFunctions/GRTable"
import SuspensionTable from "./QuantFunctions/SuspensionTable"
import Recovery from "./QuantFunctions/Recovery"

const defaultState: QuantState = {
  q1_2019: undefined,
  q1_2020: undefined,
  q1_2021: undefined,
  q2_2019: undefined,
  q2_2020: undefined,
  q2_2021: undefined,
  q3_2019: undefined,
  q3_2020: undefined,
  q3_2021: undefined,
  q4_2020: undefined,
  q4_2019: undefined,
  q4_2021: undefined,
}

export function QualifiedList({ qualified }) {
  const result = Object.keys(qualified)
    .filter((key) => qualified[key] !== false)
    .map((key) => key.replace(/_/g, " "))

  const result_list = result.map((item) => <li>{item.toUpperCase()}</li>)

  return (
    <div className="col-md-3 m-auto d-flex justify-content-center flex-column">
      <h3 className="text-center">You will qualify for:</h3>
      <ul className="mx-auto justify-content-center">{result_list}</ul>
    </div>
  )
}

const initialState = (quarters, ertc_filing_qualification): QuantState => {
  // Get keys from defaultState
  const keys = Object.keys(defaultState)

  // Create a new object with the keys and the values from ertc_filing_qualification that exist
  const newState = keys.reduce((acc, key) => {
    if (ertc_filing_qualification && ertc_filing_qualification[key]) {
      acc[key] = ertc_filing_qualification[key]
    }
    return acc
  }, {})

  console.log("initial", defaultState, quarters, ertc_filing_qualification, newState)

  return ({
    ...defaultState,
    ...quarters,
    ...newState
  })
}

const qualifiedInitialState: QualifiedStateProps = {
  q2_2020: false,
  q3_2020: false,
  q4_2020: false,
  q1_2021: false,
  q2_2021: false,
  q3_2021: false,
  q4_2021: false,
}

const qualifiedReasonInitialState: QualifiedReasonStateProps = {
  q2_2020_qualified_reason: "",
  q3_2020_qualified_reason: "",
  q4_2020_qualified_reason: "",
  q1_2021_qualified_reason: "",
  q2_2021_qualified_reason: "",
  q3_2021_qualified_reason: "",
  q4_2021_qualified_reason: "",
}

const qualifiedInitialReason: QualifiedReasonProps = {
  q2_2020_recovery: "",
  q3_2020_recovery: "",
  q4_2020_recovery: "",
  q1_2021_recovery: "",
  q2_2021_recovery: "",
  q3_2021_recovery: "",
  q4_2021_recovery: "",
}

const calculatedInitialState: CalculatedStateProps = {}
const percentageInitialState: PercentageStateProps = {}

function prepareForAPI({
  ertc_filing_qualification,
  state,
  qualified,
  override = {},
  reason = {},
  tableState = {},
  recovery,
  suspensions,
  percentage
}) {
  // Add _qualified to the end of the key for qualified and make into object
  const qualifiedStateMap = Object.keys(qualified).reduce((acc, key) => {
    acc[`${key}_qualified`] = qualified[key]
    return acc
  }, {})

  // Set all overrides to true or false and eliminate falsey values
  const overrides = Object.keys(override).reduce((acc, key) => {
    if (override[key] !== "No") {
      const newKey = key.slice(0, 7) + "_qualified"
      acc[newKey] = override[key] !== "No"
    }
    return acc
  }, {})

  // Remap the recovery values to the API
  const recoveryValues = {
    q3_2021_recovery: recovery.q3_2021,
    q4_2021_recovery: recovery.q4_2021,
  }

  // Remap suspension table to match API
  const suspensionValues = suspensions.map(s => ({
    start_date: s.date_range_start,
    end_date: s.date_range_end,
    suspended: s.suspension_type,
    suspension_reason: s.suspension_reason,
  }))

  const combinedForAPI = {
    ertc_filing_qualification: {
      ...ertc_filing_qualification,
      ...state,
      ...qualifiedStateMap,
      ...overrides,
      ...tableState,
      ...recoveryValues,
      'suspension_values': suspensionValues,
      ...reason,
      ...percentage
    },
    'suspension_values': suspensionValues,
  }

  return combinedForAPI
}

function createOverrideState(state) {
  return Object.keys(state).reduce((acc, key) => {
    acc[key] = "No"
    return acc
  }, {})
}

const initialTableState = (questions) => {
  const defaultValues = {
    q2_2020_qualified_reason: "No",
    q3_2020_qualified_reason: "No",
    q4_2020_qualified_reason: "No",
    q1_2021_qualified_reason: "No",
    q2_2021_qualified_reason: "No",
    q3_2021_qualified_reason: "No",
    q4_2021_qualified_reason: "No",
  }

  return { ...defaultValues, ...questions }
}

export const dateRangeInitialState = (dateRange) => {
  const defaultValues = {
    id: 0,
    date_range_start: new Date(),
    suspension_type: "Partial Suspension",
    date_range_end: new Date(),
    suspension_reason: "",
  }

  return { ...defaultValues, ...dateRange }
}

const initialReason = (reasonings, paras) => {
  const defaultValues = {
    q2_2020_para: "",
    q3_2020_para: "",
    q4_2020_para: "",
    q1_2021_para: "",
    q2_2021_para: "",
    q3_2021_para: "",
    q4_2021_para: "",
  }

  // Select all keys in paras that contain "para"
  const paraKeys = Object.keys(paras).filter(key => key.includes("para"))

  // Create a new object with the keys and the values from paras that exist
  const newState = paraKeys.reduce((acc, key) => {
    if (paras[key]) {
      acc[key] = paras[key]
    }
    return acc
  }, {})

  return { ...defaultValues, ...reasonings, ...newState }
}
//This code below is the Quantitative Qualification
export default function QuantQual({
  ertc_filing,
  quarters,
  ertc_filing_qualification,
  form_authenticity_token,
  suspensions
}) {

  console.log(suspensions)
  const [state, setState] = React.useState(initialState(quarters, ertc_filing_qualification))

  const [calculated, setCalculated] = React.useState({})
  const [percentage, setPercentage] = React.useState(percentageInitialState)
  const [qualified, setQualified] = React.useState({
    ...qualifiedInitialState,
    ...quarters,
  })

  const [recovery, setRecovery] = React.useState({
    q3_2021_recovery: false,
    q4_2021_recovery: false,
    q3_2021: ertc_filing_qualification.q3_2021_recovery || false,
    q4_2021: ertc_filing_qualification.q4_2021_recovery || false,
  })

  // Qualified Reason
  const [qualifyReason, setQualifyReason] = React.useState(qualifiedInitialReason)

  // Table State
  const [tableState, setTableState] = React.useState(initialTableState({}))

  // Reason State
  const [reason, setReason] = React.useState(initialReason({}, ertc_filing_qualification))

  // Suspension Dates
  const [dateRangeState, setDateRangeState] = React.useState(dateRangeInitialState({}))

  const dateRangeArrayInitialState = suspensions.map(s => ({
    date_range_start: new Date(s.start_date),
    date_range_end: new Date(s.end_date),
    suspension_type: s.suspended,
    suspension_reason: s.suspension_reason,
  }))
  const [dateRangeArray, setDateRangeArray] = React.useState(dateRangeArrayInitialState || [])

  // Map all values in iState to "No"
  const initialOverrideState = createOverrideState(qualifiedReasonInitialState)
  const [override, setOverride] = React.useState(initialOverrideState)

  const [suspensionRadio, setSuspensionRadio] = React.useState("")

  // Prepare dataa for API
  const preparedForAPI = prepareForAPI({
    ertc_filing_qualification,
    state,
    qualified,
    override,
    reason,
    tableState,
    recovery,
    suspensions: dateRangeArray,
    percentage
  })

  // Send data to API
  function sendData(
    url_to_ertc_filing,
    form_authenticity_token,
    data,
    callback
  ) {
    submitToAPI(
      url_to_ertc_filing,
      "PUT",
      form_authenticity_token,
      data,
      callback
    )
  }

  // Submit data to API from button
  const submitForm = (event) => {
    event.preventDefault()
    sendData(
      ertc_filing_qualification.url,
      form_authenticity_token,
      preparedForAPI,
      () => {
        window.location.href = ertc_filing.url.replace(".json", "")
      }
    )
  }

  const percentColorTwenty = (value): string => {
    if (CalculationColorTwenty(value)) {
      return "table-success border-left border-right tbl-border "
    } else {
      return "table-danger border-left border-right tbl-border "
    }
  }

  const percentColorBoolean = (value): string =>
    value ? "table-success border-left border-right tbl-border " : "table-danger border-left border-right tbl-border "

  const updateField = (event) => {
    event.preventDefault()
    const targetName = event.target.name
    const targetValue = parseFloat(event.target.value)
    setState((state) => ({ ...state, [targetName]: targetValue }))
  }

  const checkChange = (e) => {
    if (e === "q3_2021") {
      setRecovery((recovery) => ({ ...recovery, q3_2021: !recovery.q3_2021 }))
    } else {
      setRecovery((recovery) => ({ ...recovery, q4_2021: !recovery.q4_2021 }))
    }
  }

  const addDateRange = (event) => {
    event.preventDefault()
    dateRangeArray.push(dateRangeState)
    dateRangeArray.forEach((item, i) => {
      item.id = i + 1
    })
    setDateRangeState(dateRangeInitialState({}))
  }

  const deleteDateRange = (event, id) => {
    event.preventDefault()
    const newDateRangeArray = [...dateRangeArray]
    const index = dateRangeArray.findIndex((range) => range.id === id)
    newDateRangeArray.splice(index, 1)
    setDateRangeArray(newDateRangeArray)
  }

  const isSusRadioSelected = (value: string): boolean =>
    suspensionRadio === value

  const suspensionRadioHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setSuspensionRadio(e.currentTarget.value)
  }

  const suspensionRangeVisibility = () =>
    suspensionRadio === "suspensionRadioYes" ? "" : "d-none"

  React.useEffect(() => {
    console.log(dateRangeArray)
  }, [dateRangeArray, dateRangeState])

  React.useEffect(() => {
    console.log(dateRangeState)
  }, [dateRangeState])

  React.useEffect(() => {
    const { q3_2021_recovery, q4_2021_recovery } = qualifyReason

    if (
      q3_2021_recovery !== "gross_reason" ||
      q3_2021_recovery !== "alt_gross_reason" ||
      q3_2021_recovery !== "Partial Suspension" ||
      q3_2021_recovery !== "Full Suspension"
    ) {
      if (q3_2021_recovery === "" && recovery.q3_2021) {
        setQualifyReason((reason) => ({
          ...reason,
          q3_2021_recovery: "recovery_startup",
        }))
      } else if (q3_2021_recovery === "recovery_startup" && !recovery.q3_2021) {
        setQualifyReason((reason) => ({
          ...reason,
          q3_2021_recovery: "",
        }))
      }
    }

    if (
      q4_2021_recovery !== "gross_reason" ||
      q4_2021_recovery !== "alt_gross_reason" ||
      q4_2021_recovery !== "Partial Suspension" ||
      q4_2021_recovery !== "Full Suspension"
    ) {
      if (q4_2021_recovery === "" && recovery.q4_2021) {
        setQualifyReason((reason) => ({
          ...reason,
          q4_2021_recovery: "recovery_startup",
        }))
      } else if (q4_2021_recovery === "recovery_startup" && !recovery.q4_2021) {
        setQualifyReason((reason) => ({
          ...reason,
          q4_2021_recovery: "",
        }))
      }
    }
  }, [recovery, qualifyReason, qualified])

  React.useEffect(() => {
    console.log("here", qualifyReason)
  }, [qualifyReason])

  const resetForms = () => {
    setState(initialState)
    setCalculated({})
    setPercentage({})
  }

  const deltaAndPercentage = ({
    firstQuarter,
    secondQuarter,
    calculatedName,
    percentageName,
    altPercentageName,
  }: deltaAndPercentageProps) => {
    const delta_v = CalculationSubtract(firstQuarter, secondQuarter)
    const per_delta_v = CalculationPercentageInDifference(
      firstQuarter,
      secondQuarter
    )
    const alt_method = per_delta_v

    if (
      (firstQuarter && secondQuarter) ||
      (firstQuarter && secondQuarter === 0)
    ) {
      setCalculated((calculated) => ({
        ...calculated,
        [calculatedName]: delta_v,
      }))
      setPercentage((percentage) => ({
        ...percentage,
        [percentageName]: per_delta_v,
      }))
      setPercentage((percentage) => ({
        ...percentage,
        [altPercentageName]: alt_method,
      }))
    } else {
      setCalculated((calculated) => ({ ...calculated, [calculatedName]: 0 }))
      setPercentage((percentage) => ({ ...percentage, [percentageName]: 0 }))
      setPercentage((percentage) => ({
        ...percentage,
        [altPercentageName]: 0,
      }))
    }
  }
  const deltaAndPercentage21 = ({
    firstQuarter,
    secondQuarter,
    thirdQuarter,
    calculatedName,
    percentageName,
    altPercentageName,
  }) => {
    if (
      (firstQuarter && thirdQuarter) ||
      (firstQuarter && thirdQuarter === 0)
    ) {
      const delta_v = CalculationSubtract(firstQuarter, thirdQuarter)
      const per_delta_v = CalculationPercentageInDifference(
        firstQuarter,
        thirdQuarter
      )
      const alt_method = per_delta_v
      setCalculated((calculated) => ({
        ...calculated,
        [calculatedName]: delta_v,
      }))
      setPercentage((percentage) => ({
        ...percentage,
        [percentageName]: per_delta_v,
      }))
      setPercentage((percentage) => ({
        ...percentage,
        [altPercentageName]: alt_method,
      }))
    } else if (
      (!firstQuarter && secondQuarter && thirdQuarter) ||
      (!firstQuarter && secondQuarter && thirdQuarter === 0)
    ) {
      const delta_v = CalculationSubtract(secondQuarter, thirdQuarter)
      const per_delta_v = CalculationPercentageInDifference(
        secondQuarter,
        thirdQuarter
      )
      const alt_method = per_delta_v
      setCalculated((calculated) => ({
        ...calculated,
        [calculatedName]: delta_v,
      }))
      setPercentage((percentage) => ({
        ...percentage,
        [percentageName]: per_delta_v,
      }))
      setPercentage((percentage) => ({
        ...percentage,
        [altPercentageName]: alt_method,
      }))
    } else {
      setCalculated((calculated) => ({ ...calculated, [calculatedName]: 0 }))
      setPercentage((percentage) => ({ ...percentage, [percentageName]: 0 }))
      setPercentage((percentage) => ({
        ...percentage,
        [altPercentageName]: 0,
      }))
    }
  }

  React.useEffect(() => {
    // Our 2020 Calculations
    const performCalculations: Array<deltaAndPercentageProps> = [
      {
        firstQuarter: state.q2_2019,
        secondQuarter: state.q2_2020,
        calculatedName: "delta_v_q220",
        percentageName: "per_delta_v_q220",
        altPercentageName: "non-exist_q220",
      },
      {
        firstQuarter: state.q3_2019,
        secondQuarter: state.q3_2020,
        calculatedName: "delta_v_q320",
        percentageName: "per_delta_v_q320",
        altPercentageName: "non-exist_q320",
      },
      {
        firstQuarter: state.q4_2019,
        secondQuarter: state.q4_2020,
        calculatedName: "delta_v_q420",
        percentageName: "per_delta_v_q420",
        altPercentageName: "per_alt_mthod_q121",
      },
    ]

    performCalculations.forEach((calc) => deltaAndPercentage(calc))

    // Our 2021 Calculations
    const performCalculations21: Array<deltaAndPercentage21Props> = [
      {
        firstQuarter: state.q1_2019,
        secondQuarter: state.q1_2020,
        thirdQuarter: state.q1_2021,
        calculatedName: "delta_v_q121",
        percentageName: "per_delta_v_q121",
        altPercentageName: "per_alt_mthod_q221",
      },
      {
        firstQuarter: state.q2_2019,
        secondQuarter: state.q2_2020,
        thirdQuarter: state.q2_2021,
        calculatedName: "delta_v_q221",
        percentageName: "per_delta_v_q221",
        altPercentageName: "per_alt_mthod_q321",
      },
      {
        firstQuarter: state.q3_2019,
        secondQuarter: state.q3_2020,
        thirdQuarter: state.q3_2021,
        calculatedName: "delta_v_q321",
        percentageName: "per_delta_v_q321",
        altPercentageName: "per_alt_mthod_q421",
      },
      {
        firstQuarter: state.q4_2019,
        secondQuarter: state.q4_2020,
        thirdQuarter: state.q4_2021,
        calculatedName: "delta_v_q421",
        percentageName: "per_delta_v_q421",
        altPercentageName: "non-exist_q421",
      },
    ]

    performCalculations21.forEach((calc) => deltaAndPercentage21(calc))
  }, [state])

  React.useEffect(() => {
    // 2020 and 2021 Checks
    const q2_2020_qualifies = percentage.per_delta_v_q220 >= 50
    const q3_2020_qualifies = percentage.per_delta_v_q320 >= 20
    const q3_2020_qualifies_50 = percentage.per_delta_v_q320 >= 50
    const q4_2020_qualifies = percentage.per_delta_v_q420 >= 50
    const q1_2021_qualifies =
      percentage.per_delta_v_q121 >= 20 || percentage.per_alt_mthod_q121 >= 20
    const q2_2021_qualifies =
      percentage.per_delta_v_q221 >= 20 || percentage.per_alt_mthod_q221 >= 20
    const q3_2021_qualifies =
      percentage.per_delta_v_q321 >= 20 || percentage.per_alt_mthod_q321 >= 20
    const q4_2021_qualifies =
      percentage.per_delta_v_q421 >= 20 || percentage.per_alt_mthod_q421 >= 20
    if (q2_2020_qualifies) {
      setQualified((qualified) => ({
        ...qualified,
        q2_2020: true,
        q3_2020: true,
      }))

      setReason((reason) => ({
        ...reason,
        q2_2020_qualified_reason: "gross_reason",
        q3_2020_qualified_reason: "gross_reason",
      }))
    } else {
      setQualified((qualified) => ({
        ...qualified,
        q2_2020: q2_2020_qualifies,

        q3_2020: q3_2020_qualifies_50,
      }))

      // setReason((reason) => ({
      //   ...reason,
      //   q2_2020_qualified_reason: "",
      // }))

      if (q3_2020_qualifies_50) {
        setReason((reason) => ({
          ...reason,
          q3_2020_qualified_reason: "gross_reason",
        }))
      } else {
        // setQualifyReason((reason) => ({
        //   ...reason,
        //   q3_2020_recovery: "",
        // }))
      }
    }

    if ((q2_2020_qualifies && q3_2020_qualifies) || q3_2020_qualifies_50) {
      setQualified((qualified) => ({
        ...qualified,
        q4_2020: true,
      }))

      setReason((reason) => ({
        ...reason,
        q4_2020_qualified_reason: "gross_reason",
      }))
    } else {
      setQualified((qualified) => ({
        ...qualified,
        q4_2020: q4_2020_qualifies,
      }))

      if (q4_2020_qualifies) {
        setReason((reason) => ({
          ...reason,
          q4_2020_qualified_reason: "gross_reason",
        }))
      } else {
        // setQualifyReason((reason) => ({
        //   ...reason,
        //   q4_2020_recovery: "",
        // }))
      }
    }

    if (q1_2021_qualifies) {
      setQualified((qualified) => ({
        ...qualified,
        q1_2021: true,
      }))
      if (
        percentage.per_delta_v_q121 < 20 &&
        percentage.per_alt_mthod_q121 > 20
      ) {
        setReason((reason) => ({
          ...reason,
          q1_2021_qualified_reason: "alt_gross_reason",
        }))
      } else {
        setReason((reason) => ({
          ...reason,
          q1_2021_qualified_reason: "gross_reason",
        }))
      }
    } else {
      setQualified((qualified) => ({
        ...qualified,
        q1_2021: q1_2021_qualifies,
      }))
      // setQualifyReason((reason) => ({
      //   ...reason,
      //   q1_2021_recovery: "",
      // }))
    }

    if (q2_2021_qualifies) {
      setQualified((qualified) => ({
        ...qualified,
        q2_2021: true,
      }))

      if (
        percentage.per_delta_v_q221 < 20 &&
        percentage.per_alt_mthod_q221 > 20
      ) {
        setReason((reason) => ({
          ...reason,
          q2_2021_qualified_reason: "alt_gross_reason",
        }))
      } else {
        setReason((reason) => ({
          ...reason,
          q2_2021_qualified_reason: "gross_reason",
        }))
      }
    } else {
      setQualified((qualified) => ({
        ...qualified,
        q2_2021: q2_2021_qualifies,
      }))
      // setQualifyReason((reason) => ({
      //   ...reason,
      //   q2_2021_recovery: "",
      // }))
    }

    if (q3_2021_qualifies) {
      setQualified((qualified) => ({
        ...qualified,
        q3_2021: true,
      }))
      if (
        percentage.per_delta_v_q321 < 20 &&
        percentage.per_alt_mthod_q321 > 20
      ) {
        setReason((reason) => ({
          ...reason,
          q3_2021_qualified_reason: "alt_gross_reason",
        }))
      } else {
        setReason((reason) => ({
          ...reason,
          q3_2021_qualified_reason: "gross_reason",
        }))
      }
    } else {
      setQualified((qualified) => ({
        ...qualified,
        q3_2021: q3_2021_qualifies,
      }))
      // setQualifyReason((reason) => ({
      //   ...reason,
      //   q3_2021_recovery: "",
      // }))

      if (recovery.q3_2021) {
        setQualified((qualify) => {
          return { ...qualify, q3_2021: true }
        })
      } else {
        setQualified((qualify) => {
          return { ...qualify, q3_2021: false }
        })
      }
    }

    if (recovery.q4_2021) {
      setQualified((qualify) => {
        return { ...qualify, q4_2021: true }
      })
    } else {
      setQualified((qualify) => {
        return { ...qualify, q4_2021: false }
      })
    }
  }, [percentage, recovery])

  // React.useEffect(() => {
  //   console.log("Qualified", qualified)
  // }, [qualified])

  //This code is executed if the form is submitted

  const onFormKeyPress = (e) => e.key === "Enter" && e.preventDefault()
  const revFieldOptions = {
    type: "number",
    placeholder: "$",
    min: "0",
    onChange: (e) => updateField(e),
  }

  // Name of each quarter for input fields
  // const quarterKeys = Object.keys(initialState(quarters))

  // React.useEffect(() => {
  //   console.log(percentage)
  // }, [percentage])

  // React.useEffect(() => {
  //   console.log("overRide", override)
  // }, [override])

  // React.useEffect(() => {
  //   console.log(qualifyReason)
  // }, [qualifyReason])

  React.useEffect(() => {
    console.log("percentage", percentage)
  }, [percentage])

  React.useEffect(() => {
    console.log("Qualified", qualified)
  }, [qualified])

  // React.useEffect(() => {
  //   console.log("Table State", tableState)
  // }, [tableState])

  // React.useEffect(() => {
  //   console.log("Reason", reason)
  // }, [reason])

  React.useEffect(() => {
    console.log("preparedForAPI", preparedForAPI)
  }, [preparedForAPI])

  return (
    <Form onKeyPress={onFormKeyPress}>
      <StepWizard>
        <GRTable
          state={state}
          updateField={updateField}
          percentColorBoolean={percentColorBoolean}
          percentColorTwenty={percentColorTwenty}
          percentage={percentage}
          qualified={qualified}
        />
        <SuspensionPeriods
          dateRangeState={dateRangeState}
          setDateRangeState={setDateRangeState}
          addDateRange={addDateRange}
          deleteDateRange={deleteDateRange}
          dateRangeArray={dateRangeArray}
          suspensions={suspensions}
        />
        <Recovery recovery={recovery} checkChange={checkChange} />
        <QualTable
          qualified={qualified}
          setOverride={setOverride}
          setReason={setReason}
          reason={reason}
          setTableState={setTableState}
          tableState={tableState}
          dateRangeArray={dateRangeArray}
          dateRangeState={dateRangeState}
          setDateRangeState={setDateRangeState}
          submitForm={submitForm}
          resetForms={resetForms}
        />
      </StepWizard>
    </Form>
  )
}

export function SuspensionPeriods({
  dateRangeState,
  setDateRangeState,
  addDateRange,
  deleteDateRange,
  dateRangeArray,
  nextStep,
  previousStep,
  suspensions
}) {
  const [showForm, setShowForm] = React.useState(false)

  const suspensionRadioIntitialState = suspensions.length > 0 ? "suspensionRadioYes" : ""
  const [suspensionRadio, setSuspensionRadio] = React.useState(suspensionRadioIntitialState)

  const isSusRadioSelected = (value: string): boolean =>
    suspensionRadio === value

  const suspensionRadioHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setSuspensionRadio(e.currentTarget.value)
  }

  const suspensionRangeVisibility = () =>
    suspensionRadio === "suspensionRadioYes" ? "" : "d-none"

  return (
    <div className="text-center gr-table">
      <div className="d-flex justify-content-center">
        <table className=" w-50 table ">
          <tbody>
            <tr >
              <td className="input-border gr-table-head">
                At any time since March of 2020 has your business been{" "}
                <a
                  target="_blank"
                  href="https://www.irs.gov/newsroom/covid-19-related-employee-retention-credits-determining-when-an-employers-trade-or-business-operations-are-considered-to-be-fully-or-partially-suspended-due-to-a-governmental-order-faqs#are-an-employer-s-operations-considered-to-be-partially-suspended-for-purposes-of-the-employee-retention-credit"
                  className="text-decoration-none text-info"
                >
                  partially
                </a>{" "}
                or{" "}
                <a
                  target="_blank"
                  href="https://www.irs.gov/newsroom/covid-19-related-employee-retention-credits-determining-when-an-employers-trade-or-business-operations-are-considered-to-be-fully-or-partially-suspended-due-to-a-governmental-order-faqs#if-governmental-order-causes-suppliers-essential-business-suspend-their-operations-essential-business-considered-have-suspension-operations"
                  className="text-decoration-none text-info"
                >
                  fully
                </a>{" "}
                suspended?
              </td>
              <td className="input-border">
                <Form.Group>
                  <Form.Check
                    value="suspensionRadioYes"
                    type="radio"
                    label="Yes"
                    checked={isSusRadioSelected("suspensionRadioYes")}
                    onChange={suspensionRadioHandler}
                  />
                  <Form.Check
                    value="suspensionRadioNo"
                    type="radio"
                    label="No"
                    checked={isSusRadioSelected("suspensionRadioNo")}
                    onChange={suspensionRadioHandler}
                  />
                </Form.Group>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
      <div className={suspensionRangeVisibility()}>
        <SuspensionTable
          dateRangeArray={dateRangeArray}
          deleteDateRange={deleteDateRange}
        />
        {!showForm && (
          <a className="btn add-sus" onClick={() => setShowForm(true)}>
            Add Suspension Period
          </a>
        )}
        {showForm && (
          <>
            <QualDateRange
              dateRangeState={dateRangeState}
              setDateRangeState={setDateRangeState}
            />
            <Button
              id="addDateRange"
              type="Submit"
              className="add-sus col-2 offset-4"
              onClick={(e) => {
                addDateRange(e)
                setShowForm(false)
              }}
            >
              Add Suspension
            </Button>
          </>
        )}
      </div>
      <div className="mt-5">
        <button
          className="btn back-btn"
          onClick={(e) => {
            e.preventDefault()
            previousStep()
          }}
        >
          Go Back
        </button>
        <button
          className="btn next-btn"
          onClick={(e) => {
            e.preventDefault()
            nextStep()
          }}
        >
          Next
        </button>
      </div>
    </div>
  )
}
