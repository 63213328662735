// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react"
import { Form } from "react-bootstrap"
import "../Basic941Functions/Basic941.css"

import { sumTwoInputs } from "../Basic941Functions/Basic941Math"
import { subTwoInputs } from "../Basic941Functions/Basic941Math"

import ReactTooltip from "react-tooltip"
import { TooltipMessage } from "../Utilities"

import { ERCPost941XContext } from "../Post941X"

const Post941XPart2 = () => {
  const [context, setContext]: any = React.useContext(ERCPost941XContext)

  console.log("Context", context)

  const initialState: any = { ...context.partTwo }
  const [state, setState] = React.useState(initialState)

  const calculatedInitialState: any = {}
  const [calculated, setCalculated] = React.useState(calculatedInitialState)
  const [selectedRadioBtn1, setSelectedRadioBtn1] = React.useState(
    context.recovery ? "Yes" : "No"
  )

  const isRadioSelected1 = (value: string): boolean =>
    selectedRadioBtn1 === value

  const handleRadioClick1 = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedRadioBtn1(e.currentTarget.value)
  }

  const updateField = event => {
    event.preventDefault()
    const targetValue = parseFloat(event.target.value)

    setState(state => ({
      ...state,
      [event.target.name]: targetValue,
    }))
  }

  const isInputNaN = value => {
    return isNaN(value) ? "" : value
  }

  //Calculates two C
  React.useEffect(() => {
    const { twoA, twoB } = state

    const calcTwoC: number = sumTwoInputs(twoA, twoB)

    setCalculated(c => ({
      ...c,
      calcTwoC,
    }))
  }, [state.twoA, state.twoB])

  //Calculates two D
  React.useEffect(() => {
    const { calcTwoC } = calculated

    const calcTwoD = parseFloat((calcTwoC * 0.7).toFixed(2)) || 0
    if (calcTwoD > 50000 && selectedRadioBtn1 === "Yes") {
      setCalculated(c => ({
        ...c,
        calcTwoD: 50000,
      }))
    } else {
      setCalculated(c => ({
        ...c,
        calcTwoD,
      }))
    }
  }, [calculated.calcTwoC, selectedRadioBtn1])

  //Calculates two G
  React.useEffect(() => {
    const { twoF } = state

    const calcTwoG = parseFloat(
      subTwoInputs(context.calculated.partOne.calcOneG, twoF).toFixed(2)
    )
    setCalculated(c => ({
      ...c,
      calcTwoG,
    }))
  }, [state.twoF, context.calculated.partOne.calcOneG])

  //Calculates two H
  React.useEffect(() => {
    const { calcTwoD, calcTwoG } = calculated

    if (calcTwoD <= calcTwoG) {
      const calcTwoH = calcTwoD

      setCalculated(c => ({
        ...c,
        calcTwoH,
      }))
    } else {
      const calcTwoH = calcTwoG

      setCalculated(c => ({
        ...c,
        calcTwoH,
      }))
    }
  }, [calculated.calcTwoD, calculated.calcTwoG])

  //Calculates two I
  React.useEffect(() => {
    const { calcTwoD, calcTwoH } = calculated

    const calcTwoI = subTwoInputs(calcTwoD, calcTwoH)
    setCalculated(c => ({
      ...c,
      calcTwoI,
    }))
  }, [calculated.calcTwoD, calculated.calcTwoH])

  React.useEffect(() => {
    console.log(selectedRadioBtn1)
  }, [selectedRadioBtn1])

  React.useEffect(() => {
    setContext({ ...context, partTwo: state })
  }, [state])

  React.useEffect(() => {
    setContext({
      ...context,
      calculated: { ...context.calculated, partTwo: calculated },
    })
  }, [calculated])

  return (
    <div className="all-font">
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th className="all-table-head">Part 2</th>
            <th className="all-table-head">
              Figure the Employee Retention Credit:
            </th>
            <th className="all-table-head">Calculations</th>
            <th className="all-table-head">Calculations</th>
          </tr>
          <tr>
            <th className=""></th>
            <th className="">
              Caution: The total amount included on lines 2a and 2b can't exceed
              $10,000 per employee each quarter
            </th>
            <td className="table-active"></td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 2a</th>
            <th className="">
              Qualified wages (excluding qualified health plan expenses) for the
              employee retention credit (Form 941, Part 3, line 21, or, if
              corrected, Form 941-X, line 30, column 1). Enter this information
              on Form 941-X, line 30.{" "}
              <TooltipMessage
                message={
                  <p>
                    <a
                      target="_blank"
                      href="https://www.irs.gov/newsroom/covid-19-related-employee-retention-credits-determining-qualified-wages-faqs"
                    >
                      Qualified wages{" "}
                    </a>{" "}
                    are any wages and compentation you pay or incur for services
                    performed by a qualified employee over the entire quarter.{" "}
                  </p>
                }
              />
            </th>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoA"
                value={isInputNaN(state.twoA)}
                onChange={e => updateField(e)}
              />
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 2b</th>
            <th className="">
              Qualified health plan expenses allocable to qualified wages for
              the employee retention credit (Form 941, Part 3, line 22, or, if
              corrected, Form 941-X, line 31a, column 1). Enter on Form 941-X,
              line 31a.{" "}
              <TooltipMessage
                message={
                  <p>
                    <a
                      target="_blank"
                      href="https://www.irs.gov/newsroom/covid-19-related-employee-retention-credits-amount-of-allocable-qualified-health-plan-expenses-faqs"
                    >
                      {" "}
                      Qualified health plan expenses allocable to qualified
                      wages{" "}
                    </a>
                    are amounts paid or incurred by the Eligible Employer to
                    provide and maintain a group health plan, but only to the
                    extent that those amounts are excluded from the gross income
                    of employees.{" "}
                  </p>
                }
              />
            </th>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoB"
                value={isInputNaN(state.twoB)}
                onChange={e => updateField(e)}
              />
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 2c</th>
            <th className="">Add lines 2a and 2b</th>
            <td className="edit-field">{calculated.calcTwoC}</td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 2d</th>
            <th className="">
              Retention credit. Multiply line 2c by 70% (0.70). If you qualify
              for the employee retention credit solely because your business is
              a recovery startup business, don't enter more than $50,000 per
              quarter. If Recovery Start Up Business, Check box on line 31b.{" "}
              <TooltipMessage message="In 2021 – credit can be claimed for 70% of qualified wages, up to $10,000 per employee paid per quarter in 2021, for a maximum credit of $28,000 per employee." />
            </th>
            <td className="">
              <Form.Group>
                <Form.Check
                  value="Yes"
                  type="radio"
                  label="Recovery Start Up"
                  name="recovery_startup"
                  checked={isRadioSelected1("Yes")}
                  onChange={handleRadioClick1}
                />
                <Form.Check
                  value="No"
                  type="radio"
                  label="Not, Recovery Start Up"
                  name="recovery_startup"
                  checked={isRadioSelected1("No")}
                  onChange={handleRadioClick1}
                />
              </Form.Group>
            </td>
            <td className="edit-field">{calculated.calcTwoD}</td>
          </tr>
          <tr>
            <th className=""> 2e</th>
            <th className="">
              Enter the amount of the employer share of Medicare tax from Step
              1, line 1a, or, if applicable, Step 1, line 1g
            </th>
            <td className="edit-field">
              {context.calculated.partOne.calcOneG || 0}
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 2f</th>
            <th className="">
              Enter the amount of the nonrefundable portion of the credit for
              qualified sick and family leave wages for leave taken after March
              31, 2021, from Worksheet 3, Step 2, line 2r
            </th>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoF"
                value={isInputNaN(state.twoF)}
                onChange={e => updateField(e)}
              />
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 2g</th>
            <th className="">Subtract line 2f from line 2e</th>
            <td className="edit-field">{calculated.calcTwoG}</td>
            <td
              className={
                calculated.calcTwoG < 0 ? "table-danger" : "table-active"
              }
            >
              {calculated.calcTwoG < 0
                ? "2g cannot be negative, please check your inputs."
                : ""}
            </td>
          </tr>
          <tr>
            <th className=""> 2h</th>
            <th className="">
              Nonrefundable portion of employee retention credit. Nonrefundable
              portion of employee retention credit. Enter the smaller of line 2d
              or line 2g. Enter this amount on Form 941-X, line 18a, column 1
            </th>
            <td className="table-active"></td>
            <td className="edit-field">{calculated.calcTwoH}</td>
          </tr>
          <tr>
            <th className=""> 2i</th>
            <th className="">
              Refundable portion of employee retention credit. Refundable
              portion of employee retention credit. Subtract line 2h from line
              2d and enter this amount on Form 941-X, line 26a, column 1 .
            </th>
            <td className="table-active"></td>
            <td className="edit-field">{calculated.calcTwoI}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Post941XPart2
