export const OWNER_PPP_CAP = 20833
export const NON_OWNER_PPP_CAP = 46154
export const TEN_ERC_CRED = 10000

export function pppWageMaximization({
  owner,
  days_between,
  total_wages_ppp,
  eligible_ppp_outside_overlap,
}) {
  if (owner.includes("Owner")) {
    if (total_wages_ppp > OWNER_PPP_CAP) {
      return OWNER_PPP_CAP
    } else {
      return total_wages_ppp
    }
  } else if (owner.includes("Family")) {
    if (total_wages_ppp > NON_OWNER_PPP_CAP) {
      return NON_OWNER_PPP_CAP
    } else {
      return total_wages_ppp
    }
  } else if (
    total_wages_ppp / (days_between / 365) > 100000 &&
    eligible_ppp_outside_overlap > NON_OWNER_PPP_CAP
  ) {
    return NON_OWNER_PPP_CAP
  } else if (
    total_wages_ppp / (days_between / 365) > 100000 &&
    eligible_ppp_outside_overlap <= NON_OWNER_PPP_CAP
  ) {
    return eligible_ppp_outside_overlap
  } else if (
    owner.includes("Owner") &&
    eligible_ppp_outside_overlap > OWNER_PPP_CAP
  ) {
    return OWNER_PPP_CAP
  } else {
    return eligible_ppp_outside_overlap
  }
}

export function pppWageInsideOverlap({
  owner,
  total_wages_ppp,
  adjust_ppp_caps_max,
}) {
  if (owner.includes("No")) {
    if (total_wages_ppp > NON_OWNER_PPP_CAP) {
      return NON_OWNER_PPP_CAP - adjust_ppp_caps_max
    } else {
      return total_wages_ppp - adjust_ppp_caps_max
    }
  } else {
    return 0
  }
}
export function maxPotentialERC({
  owner,
  wages_used,
  total_wages_erc_covered,
}) {
  if (owner.includes("Owner")) {
    return 0
  } else {
    if (total_wages_erc_covered - wages_used < TEN_ERC_CRED - wages_used) {
      return total_wages_erc_covered - wages_used
    } else {
      return TEN_ERC_CRED - wages_used
    }
  }
}

export function wagesBeyondERC({
  owner,
  wages_used,
  wages_ppp_inside_overlap,
}) {
  if (owner.includes("No")) {
    if (wages_ppp_inside_overlap > TEN_ERC_CRED) {
      return wages_ppp_inside_overlap - (TEN_ERC_CRED - wages_used)
    } else {
      return 0
    }
  } else {
    return 0
  }
}

export function adjustedWagesOne({
  owner,
  wages_used,
  total_wages_erc_covered,
  calc_wages_beyond_erc_cap,
}) {
  if (owner.includes("No")) {
    if (total_wages_erc_covered - calc_wages_beyond_erc_cap - wages_used < 0) {
      return 0
    } else {
      return total_wages_erc_covered - calc_wages_beyond_erc_cap - wages_used
    }
  } else {
    return 0
  }
}

export function potentialWagesPPP({
  total_health_cost_each_emp,
  adjusted_erc_wages_1,
}) {
  if (total_health_cost_each_emp + adjusted_erc_wages_1 > TEN_ERC_CRED) {
    const calculatedValue =
      total_health_cost_each_emp + adjusted_erc_wages_1 - TEN_ERC_CRED
    return calculatedValue
  } else {
    return 0
  }
}

export function adjustedWagesThree({
  owner,
  wages_used,
  total_health_cost_each_emp,
  adjusted_erc_wages_1,
  potential_add_wages_ppp,
}) {
  if (owner.includes("No")) {
    const calculatedValue =
      adjusted_erc_wages_1 +
      total_health_cost_each_emp -
      wages_used -
      potential_add_wages_ppp
    return calculatedValue
  } else {
    return 0
  }
}

export function totalEligibleEarnings({ total_wages_health }) {
  if (total_wages_health >= TEN_ERC_CRED) {
    return TEN_ERC_CRED
  } else {
    return total_wages_health
  }
}
