// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

//I believe we do not need this file

import * as React from "react"
import Button from "react-bootstrap/Button"
import { Form } from "react-bootstrap"
import {
  CalculateOnePointFive,
  CalculationAddition,
  CalculationLinePointFour,
} from "../Basic941Functions/Basic941Math"

// export interface QuantStateProps {}

// export interface CalculatedStateProps {}

const Basic9412021 = () => {
  const initialState: any = {}
  const [state, setState] = React.useState(initialState)

  const calculatedInitialState: any = {}
  const [calculated, setCalculated] = React.useState(calculatedInitialState)

  const [selectedRadioBtn1, setSelectedRadioBtn1] = React.useState("")
  const [selectedRadioBtn2, setSelectedRadioBtn2] = React.useState("")

  const updateField = (event) => {
    event.preventDefault()
    const targetValue = parseFloat(event.target.value)

    setState((state) => ({
      ...state,
      [event.target.name]: targetValue,
    }))
  }
  const isRadioSelected1 = (value: string): boolean =>
    selectedRadioBtn1 === value

  const handleRadioClick1 = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedRadioBtn1(e.currentTarget.value)
  }

  const isRadioSelected2 = (value: string): boolean =>
    selectedRadioBtn2 === value

  const handleRadioClick2 = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedRadioBtn2(e.currentTarget.value)
  }

  const onePointFiveDisable = () =>
    selectedRadioBtn1 === "radioYesOne" ? false : true

  const onePointSixDisable = () =>
    selectedRadioBtn2 === "radioYesTwo" ? false : true

  React.useEffect(() => {
    const { onePointOne, onePointTwo } = state

    if (onePointOne && onePointTwo) {
      const calcOnePointThree: number = CalculationAddition(
        onePointOne,
        onePointTwo
      )
      const calcOnePointFour: number =
        CalculationLinePointFour(calcOnePointThree)
      const calcOnePointSeven: number = calcOnePointFour

      setCalculated((c) => ({
        ...c,
        calcOnePointThree,
        calcOnePointFour,
        calcOnePointSeven,
      }))
    } else {
      setCalculated((c) => ({
        ...c,
        calcOnePointThree: 0,
        calcOnePointFour: 0,
        calcOnePointSeven: 0,
      }))
    }
  }, [state.onePointOne, state.onePointTwo])

  React.useEffect(() => {
    const { onePointFiveI, onePointFiveII } = state
    const calcOnePointFive: number = CalculateOnePointFive(
      onePointFiveI,
      onePointFiveII
    )
    setCalculated((c) => ({ ...c, calcOnePointFive }))
  }, [state.onePointFiveI, state.onePointFiveII])

  React.useEffect(() => {
    const { onePointSixI } = state
    const { calcOnePointFour, calcOnePointFive } = calculated

    if (calcOnePointFour && onePointSixI && calcOnePointFive) {
      const calcOnePointSeven: number =
        calcOnePointFour + onePointSixI + calcOnePointFive
      setCalculated((c) => ({ ...c, calcOnePointSeven }))
    } else if (calcOnePointFour && onePointSixI && calcOnePointFive === 0) {
      const calcOnePointSeven: number = calcOnePointFour + onePointSixI
      setCalculated((c) => ({ ...c, calcOnePointSeven }))
    } else if (calcOnePointFour && !onePointSixI && calcOnePointFive > 0) {
      const calcOnePointSeven: number = calcOnePointFour + calcOnePointFive
      setCalculated((c) => ({ ...c, calcOnePointSeven }))
    } else {
      if (onePointSixI) {
        const calcOnePointSeven: number = calcOnePointFour + onePointSixI
        setCalculated((c) => ({ ...c, calcOnePointSeven }))
      } else {
        const calcOnePointSeven: number = calcOnePointFour
        setCalculated((c) => ({ ...c, calcOnePointSeven }))
      }
    }
  }, [
    state.onePointSixI,
    calculated.calcOnePointFour,
    calculated.calcOnePointFive,
  ])

  React.useEffect(() => {
    const { onePointEight, onePointNine, onePointTen } = state
    const { calcOnePointSeven } = calculated

    if (onePointEight && onePointNine && onePointTen) {
      const calcOnePointEleven = onePointEight + onePointNine + onePointTen
      const calcOnePointTwelve = calcOnePointEleven + calcOnePointSeven
      setCalculated((c) => ({ ...c, calcOnePointEleven, calcOnePointTwelve }))
    } else {
      setCalculated((c) => ({
        ...c,
        calcOnePointEleven: 0,
        calcOnePointTwelve: 0,
      }))
    }
  }, [
    state.onePointEight,
    state.onePointNine,
    state.onePointTen,
    calculated.calcOnePointSeven,
  ])

  // For Debug
  // React.useEffect(() => console.log(state), [state]);
  // React.useEffect(() => console.log(calculated), [calculated]);

  return (
    <Form>
      <h2 className="text-center">Basic 941 2021 Form</h2>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th className="table-primary">Part 1</th>
            <th className="table-primary">
              Corrective Share of Social Security
            </th>
            <th className="table-primary">Input Your Data</th>
          </tr>
          <tr>
            <th className="table-primary"> 1.01</th>
            <th className="table-warning">
              Enter SS Wages from Form 941 (line 5a, Column 1)
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="onePointOne"
                value={state.onePointOne}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 1.02</th>
            <th className="table-warning">
              Enter SS Tips from Form 941 (line 5b, Column 1)
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="onePointTwo"
                value={state.onePointTwo}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 1.03</th>
            <th className="table-warning">Add lines 1.01 and 1.02</th>
            <td className="table-active">{calculated.calcOnePointThree}</td>
          </tr>
          <tr>
            <th className="table-primary"> 1.04</th>
            <th className="table-warning">Multiply line 1.03 by 6.2%</th>
            <td className="table-active">{calculated.calcOnePointFour}</td>
          </tr>
          <tr>
            <th className="table-primary"> 1.05</th>
            <th className="table-warning">
              Also Apply for Sick Pay Credit this Quarter?
            </th>
            <td className="table-active">
              <Form.Group>
                <Form.Check
                  value="radioYesOne"
                  type="radio"
                  label="Yes"
                  name="sick_pay_credit"
                  checked={isRadioSelected1("radioYesOne")}
                  onChange={handleRadioClick1}
                />
                <Form.Check
                  value="radioNoOne"
                  type="radio"
                  label="No"
                  name="sick_pay_credit"
                  checked={isRadioSelected1("radioNoOne")}
                  onChange={handleRadioClick1}
                />
              </Form.Group>
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 1.05 I</th>
            <th className="table-warning">
              Amounts of Sick Pay being claimed for Credit from Form 941 (line
              8) - Enter as a Negative Number
            </th>
            <td className="table-dark">
              <Form.Control
                disabled={onePointFiveDisable()}
                type="number"
                name="onePointFiveI"
                placeholder="$"
                value={state.onePointFiveI}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 1.05 II</th>
            <th className="table-warning">
              Employer Share of SS included on Form 941-X (line 20, Column 4)
            </th>
            <td className="table-dark">
              <Form.Control
                disabled={onePointFiveDisable()}
                type="number"
                name="onePointFiveII"
                placeholder="$"
                value={state.onePointFiveII}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>

          <tr>
            <th className="table-primary"> 1.06</th>
            <th className="table-warning">
              Did you Recieve a Section 3121(q)Notice
            </th>
            <td className="table-active">
              <Form.Group>
                <Form.Check
                  value="radioYesTwo"
                  type="radio"
                  label="Yes"
                  name="section_3121"
                  checked={isRadioSelected2("radioYesTwo")}
                  onChange={handleRadioClick2}
                />
                <Form.Check
                  value="radioNoTwo"
                  type="radio"
                  label="No"
                  name="section_3121"
                  checked={isRadioSelected2("radioNoTwo")}
                  onChange={handleRadioClick2}
                />
              </Form.Group>
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 1.06 I</th>
            <th className="table-warning">
              Enter the amoung of the employer Share of SS Tax from the 3121
              Notice
            </th>
            <td className="table-dark">
              <Form.Control
                disabled={onePointSixDisable()}
                type="number"
                name="onePointSixI"
                placeholder="$"
                value={state.onePointSixI}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 1.07</th>
            <th className="table-warning">Employer Share of SS Tax</th>
            <td className="table-active">{calculated.calcOnePointSeven}</td>
          </tr>
          <tr>
            <th className="table-primary"> 1.08</th>
            <th className="table-warning">
              Enter the amount of Form 941 (line 11a)
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="onePointEight"
                value={state.onePointEight}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 1.09</th>
            <th className="table-warning">
              Enter the amount of Form 941 (line 23)
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="onePointNine"
                value={state.onePointNine}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 1.10</th>
            <th className="table-warning">
              Enter the amount from Form 5884-D (line 12) if applicable
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="onePointTen"
                value={state.onePointTen}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 1.11</th>
            <th className="table-warning">
              Total non-refundable credits already used against employer share
            </th>
            <td className="table-active">{calculated.calcOnePointEleven}</td>
          </tr>
          <tr>
            <th className="table-primary"> 1.12</th>
            <th className="table-warning">
              Employer Share of Social Security Tax Remaning
            </th>
            <td className="table-active">{calculated.calcOnePointTwelve}</td>
          </tr>
        </tbody>
      </table>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th className="table-primary">Part 2</th>
            <th className="table-primary">
              Determine Sick and Family Leave Credits
            </th>
            <th className="table-primary">Input Your Data</th>
          </tr>
          <tr>
            <th className="table-primary"> 2.01</th>
            <th className="table-warning">
              Qualified Sick Leave Wages from Form 941 (line 5a(i), column 1)
            </th>
            <td className="table-active">
              <Form.Control type="number" placeholder="$" name="twoPointOne" />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 2.02</th>
            <th className="table-warning">
              Qualified Sick Leave Wages not included on previous entry but
              included in the Form 941 (line 5c). This is a portion of total
              wages that were Sick Leave Wages, but not accounted for above.
            </th>
            <td className="table-active">
              <Form.Control type="number" placeholder="$" name="twoPointTwo" />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 2.03</th>
            <th className="table-warning">Total Qualified Sick Leave Wages</th>
            <td className="table-active">2.03</td>
          </tr>
          <tr>
            <th className="table-primary"> 2.04</th>
            <th className="table-warning">
              Qualified Sick Leave Excluded from 3121(b) Employment Link:
              http://tiny.cc/ck3iuz
            </th>
            <td className="table-active">
              <Form.Control type="number" placeholder="$" name="twoPointFour" />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 2.05</th>
            <th className="table-warning">
              Qualified Health Plan Expenses allocable to Qualified Sick Leave
              Form 941 (line 19)
            </th>
            <td className="table-active">
              <Form.Control type="number" placeholder="$" name="twoPointFive" />
            </td>
          </tr>
          <tr>
            <th className="table-primary">2.06</th>
            <th className="table-warning">
              Employer Share of Medicare Tax on Qualified Sick Leave
            </th>
            <td className="table-active">2.06</td>
          </tr>
          <tr>
            <th className="table-primary">2.07</th>
            <th className="table-warning">
              Credit for Qualified Sick Leave Wages
            </th>
            <td className="table-active">2.07</td>
          </tr>

          <tr>
            <th className="table-primary"> 2.08</th>
            <th className="table-warning">
              Qualified Family Leave Wages Reported from Form 941 (line 5a(ii),
              column 1)
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoPointEight"
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 2.09</th>
            <th className="table-warning">
              Qualified Family Leave Wages not included on previous entry but
              included in the Form 941 (line 5c). This is a portion of total
              wages that were Family Leave, but not accounted for above.
            </th>
            <td className="table-active">
              <Form.Control type="number" placeholder="$" name="twoPointNine" />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 2.10</th>
            <th className="table-warning">
              Total Qualified Family Leave Wages
            </th>
            <td className="table-active">2.10</td>
          </tr>
          <tr>
            <th className="table-primary"> 2.11</th>
            <th className="table-warning">
              Qualified Family Leave Excluded from 3121(b) Employment Link:
              http://tiny.cc/ck3iuz
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoPointEleven"
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 2.12</th>
            <th className="table-warning">
              Qualified Health Plan Expenses allocable to Qualified Family Leave
              Wages from Form 941 (line 20)
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoPointTweleve"
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 2.13</th>
            <th className="table-warning">
              Employer Share of Medicare Tax on Qualified Family Leave Wages
            </th>
            <td className="table-active">2.13</td>
          </tr>
          <tr>
            <th className="table-primary"> 2.14</th>
            <th className="table-warning">
              Credit for Qualified Family Leave Wages
            </th>
            <td className="table-active">2.14</td>
          </tr>
          <tr>
            <th className="table-primary"> 2.15</th>
            <th className="table-warning">
              Credit for Qualifed Sick and Family Leave Wages
            </th>
            <td className="table-active">2.15</td>
          </tr>
          <tr>
            <th className="table-primary"> 2.16</th>
            <th className="table-warning">
              Nonrefundable Portion of The Sick and Family Leave Credit
            </th>
            <td className="table-active">2.16</td>
          </tr>
          <tr>
            <th className="table-primary"> 2.17</th>
            <th className="table-warning">
              Refundable Portion of The Sick and Family Leave Credit
            </th>
            <td className="table-active">2.17</td>
          </tr>
        </tbody>
      </table>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th className="table-primary">Part 3</th>
            <th className="table-primary">Employee Retention Credit</th>
            <th className="table-primary">Input Your Data</th>
          </tr>
          <tr>
            <th className="table-primary"> 3.01</th>
            <th className="table-warning">
              Qualified Wages for the Quarter (Excluding Health Plan Expenses) -
              Must be Calculated Separately
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="threePointOne"
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 3.02</th>
            <th className="table-warning">
              Qualified Health Expenses for the Quarter Allocate to Wages - Must
              be Calculated Separately
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="threePointTwo"
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 3.03</th>
            <th className="table-warning">
              Which 2021 Quarter are you applying for the Credit?
            </th>
            <td className="table-active">
              <Form.Group>
                <Form.Check
                  value="Q1 2021"
                  type="radio"
                  label="Q1 2021"
                  name="quarter_2021"
                />
                <Form.Check
                  value="Q2 2021"
                  type="radio"
                  label="Q2 2021"
                  name="quarter_2021"
                />
                <Form.Check
                  value="Q3 2021"
                  type="radio"
                  label="Q3 2021"
                  name="quarter_2021"
                />
                <Form.Check
                  value="Q4 2021"
                  type="radio"
                  label="Q4 2021"
                  name="quarter_2021"
                />
              </Form.Group>
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 3.04</th>
            <th className="table-warning">Total of Calculations</th>
            <td className="table-active">3.04</td>
          </tr>
          <tr>
            <th className="table-primary"> 3.05</th>
            <th className="table-warning">
              Retention Credit Calculation (70%)
            </th>
            <td className="table-active">3.05</td>
          </tr>
          <tr>
            <th className="table-primary"> 3.06</th>
            <th className="table-warning">Employer Social Security Tax</th>
            <td className="table-active">3.06</td>
          </tr>
          <tr>
            <th className="table-primary"> 3.07</th>
            <th className="table-warning">
              Nonrefundable Credit for Sick and Family Leave
            </th>
            <td className="table-active">3.07</td>
          </tr>
          <tr>
            <th className="table-primary"> 3.08</th>
            <th className="table-warning">Reduction in Calculation</th>
            <td className="table-active">3.08</td>
          </tr>
          <tr>
            <th className="table-primary"> 3.09</th>
            <th className="table-warning">
              Total Nonrefundable Amount on Line 23
            </th>
            <td className="table-active">3.09</td>
          </tr>
          <tr>
            <th className="table-primary"> 3.10</th>
            <th className="table-warning">
              Nonrefundable Portion of Employee Retention Credit
            </th>
            <td className="table-active">3.10</td>
          </tr>
          <tr>
            <th className="table-primary"> 3.11</th>
            <th className="table-warning">
              Refundable Portion of Employee Retention Credit
            </th>
            <td className="table-active">3.11</td>
          </tr>
        </tbody>
      </table>
    </Form>
  )
}

export default Basic9412021
