import * as React from "react"
import { Form } from "react-bootstrap"

export default function GRTable({
  state,
  updateField,
  percentColorBoolean,
  percentColorTwenty,
  percentage,
  qualified,
  nextStep,
  previousStep,
}) {
  return (
    <div className="gr-table text-center">
      <h2 className="text-center">Gross Receipts</h2>
      <table className="table table-borderless ">
        <tbody>
          <tr className="border-0">
            <th className="tbl-border border-right gr-table-head">
              Quarter vs Qualification Method
            </th>
            <th className="tbl-border border-right gr-table-head">Q1 2019</th>
            <th className="tbl-border border-right gr-table-head">Q2 2019</th>
            <th className="tbl-border border-right gr-table-head">Q3 2019</th>
            <th className="tbl-border border-right gr-table-head">Q4 2019</th>
            <th className="tbl-border border-right gr-table-head">Q1 2020</th>
            <th className="tbl-border border-right gr-table-head">Q2 2020</th>
            <th className="tbl-border border-right gr-table-head">Q3 2020</th>
            <th className="tbl-border border-right gr-table-head">Q4 2020</th>
            <th className="tbl-border border-right gr-table-head">Q1 2021</th>
            <th className="tbl-border border-right gr-table-head">Q2 2021</th>
            <th className="tbl-border border-right gr-table-head">Q3 2021</th>
            <th className="gr-table-head">Q4 2021</th>
          </tr>
          <tr className="border-0">
            <th className="input-border border-right ">Quarterly Revenue</th>

            <td className="input-border border-right border-bottom ">
              <Form.Control
                style={{ fontSize: 10 }}
                type="number"
                placeholder="$"
                min="0"
                value={state.q1_2019}
                name="q1_2019"
                id="Q1_2019"
                onChange={e => updateField(e)}
              />
            </td>
            <td className="input-border border-right border-bottom">
              <Form.Control
                style={{ fontSize: 10 }}
                type="number"
                min="0"
                value={state.q2_2019}
                placeholder="$"
                name="q2_2019"
                id="Q2_2019"
                onChange={e => updateField(e)}
              />
            </td>
            <td className="input-border border-right border-bottom">
              <Form.Control
                value={state.q3_2019}
                style={{ fontSize: 10 }}
                type="number"
                min="0"
                placeholder="$"
                name="q3_2019"
                id="Q3_2019"
                onChange={e => updateField(e)}
              />
            </td>
            <td className="input-border border-right border-bottom">
              <Form.Control
                value={state.q4_2019}
                style={{ fontSize: 10 }}
                type="number"
                min="0"
                placeholder="$"
                name="q4_2019"
                id="Q4_2019"
                onChange={e => updateField(e)}
              />
            </td>
            <td className="input-border border-right border-bottom">
              <Form.Control
                value={state.q1_2020}
                style={{ fontSize: 10 }}
                type="number"
                min="0"
                placeholder="$"
                name="q1_2020"
                id="Q1_2020"
                onChange={e => updateField(e)}
              />
            </td>
            <td className="input-border border-right border-bottom">
              <Form.Control
                value={state.q2_2020}
                style={{ fontSize: 10 }}
                type="number"
                min="0"
                placeholder="$"
                name="q2_2020"
                id="Q2_2020"
                onChange={e => updateField(e)}
              />
            </td>
            <td className="input-border border-right border-bottom">
              <Form.Control
                value={state.q3_2020}
                style={{ fontSize: 10 }}
                type="number"
                min="0"
                placeholder="$"
                name="q3_2020"
                id="Q3_2020"
                onChange={e => updateField(e)}
              />
            </td>
            <td className="input-border border-right border-bottom">
              <Form.Control
                value={state.q4_2020}
                style={{ fontSize: 10 }}
                type="number"
                min="0"
                placeholder="$"
                name="q4_2020"
                id="Q4_2020"
                onChange={e => updateField(e)}
              />
            </td>
            <td className="input-border border-right border-bottom">
              <Form.Control
                value={state.q1_2021}
                style={{ fontSize: 10 }}
                type="number"
                min="0"
                placeholder="$"
                name="q1_2021"
                id="Q1_2021"
                onChange={e => updateField(e)}
              />
            </td>
            <td className="input-border border-right border-bottom">
              <Form.Control
                value={state.q2_2021}
                style={{ fontSize: 10 }}
                type="number"
                min="0"
                placeholder="$"
                name="q2_2021"
                id="Q2_2021"
                onChange={e => updateField(e)}
              />
            </td>
            <td className="input-border border-right border-bottom">
              <Form.Control
                value={state.q3_2021}
                style={{ fontSize: 10 }}
                type="number"
                min="0"
                placeholder="$"
                name="q3_2021"
                id="Q3_2021"
                onChange={e => updateField(e)}
              />
            </td>
            <td className="input-border table-active"></td>
            {/* <td className="table-active">
              <Form.Control
                style={{ fontSize: 10 }}
                type="number"
                min="0"
                placeholder="$"
                name="q4_2021"
                id="Q4_2021"
                onChange={(e) => updateField(e)}
              />
            </td> */}
          </tr>
          {/* this code would show the Actual dollar amount for Delta V*/}
          {/* <tr>
            <th className="table-primary">Delta v (2019 / 2020)</th>
            <td className="table-dark"></td>
            <td className="table-dark"></td>
            <td className="table-dark"></td>
            <td className="table-dark"></td>
            <td>{calculated.delta_v_q120}</td>
            <td>{calculated.delta_v_q220}</td>
            <td>{calculated.delta_v_q320}</td>
            <td>{calculated.delta_v_q420}</td>
            <td>{calculated.delta_v_q121}</td>
            <td>{calculated.delta_v_q221}</td>
            <td>{calculated.delta_v_q321}</td>
            <td>{calculated.delta_v_q421}</td>
          </tr> */}
          <tr className="border-0">
            <th className="border-right input-border">
              % Delta v (2019 / 2020)
            </th>
            <td className="table-active"></td>
            <td className="table-active"></td>
            <td className="table-active"></td>
            <td className="table-active"></td>
            <td className="table-active"></td>
            <td className={percentColorBoolean(qualified.q2_2020)}>
              {percentage.per_delta_v_q220 * -1}%
            </td>
            <td className={percentColorBoolean(qualified.q3_2020)}>
              {percentage.per_delta_v_q320 * -1}%
            </td>
            <td className={percentColorBoolean(qualified.q4_2020)}>
              {percentage.per_delta_v_q420 * -1}%
            </td>
            <td className={percentColorTwenty(percentage.per_delta_v_q121)}>
              {percentage.per_delta_v_q121 * -1}%
            </td>
            <td className={percentColorTwenty(percentage.per_delta_v_q221)}>
              {percentage.per_delta_v_q221 * -1}%
            </td>
            <td className={percentColorTwenty(percentage.per_delta_v_q321)}>
              {percentage.per_delta_v_q321 * -1}%
            </td>
            <td className="table-active"></td>
            {/* <td className={percentColorTwenty(percentage.per_delta_v_q421)}>
              {percentage.per_delta_v_q421 * -1}%
            </td> */}
          </tr>
          {/*This code would show the actual $ amount for alternative*/}
          {/* <tr>
            <th className="table-primary">Alternative Method</th>
            <td className="table-dark"></td>
            <td className="table-dark"></td>
            <td className="table-dark"></td>
            <td className="table-dark"></td>
            <td className="table-dark"></td>
            <td className="table-dark"></td>
            <td className="table-dark"></td>
            <td className="table-dark"></td>
            <td>$$$$$</td>
            <td>$$$$$</td>
            <td>$$$$$</td>
            <td>$$$$$</td>
          </tr> */}
          <tr className="border-0">
            <th className=" border-right input-border">% Alt Method</th>
            <td className="table-active"></td>
            <td className="table-active"></td>
            <td className="table-active"></td>
            <td className="table-active"></td>
            <td className="table-active"></td>
            <td className="table-active"></td>
            <td className="table-active"></td>
            <td className="table-active"></td>
            <td className={percentColorTwenty(percentage.per_alt_mthod_q121)}>
              {percentage.per_alt_mthod_q121 * -1}%
            </td>
            <td className={percentColorTwenty(percentage.per_alt_mthod_q221)}>
              {percentage.per_alt_mthod_q221 * -1}%
            </td>
            <td className={percentColorTwenty(percentage.per_alt_mthod_q321)}>
              {percentage.per_alt_mthod_q321 * -1}%
            </td>
            <td className="table-active"></td>
            {/* <td className={percentColorTwenty(percentage.per_alt_mthod_q421)}>
              {percentage.per_alt_mthod_q421 * -1}%
            </td> */}
          </tr>
        </tbody>
      </table>
      <button
        className="btn back-btn"
        onClick={e => {
          e.preventDefault()
          previousStep()
        }}
      >
        Go Back
      </button>
      <button
        className="btn next-btn"
        onClick={e => {
          e.preventDefault()
          nextStep()
        }}
      >
        Next
      </button>
    </div>
  )
}
