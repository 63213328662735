// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

// export const CalculationAddition = (x = 0, y = 0): number => x + y || 0

// export const CalculationLinePointFour = (x = 0): number => x * 0.062 || 0

// export function CalculateOnePointFive(a, b): number {
//   return a + b || 0
// }
// export const CalculationTwoPointSix = (x): number =>
//   parseFloat((x * 0.0145).toFixed(2))

// export const CalculationThreePointFive = (x): number =>
//   parseFloat((x * 0.7).toFixed(2))

// export const CalculationThreePointSeven = (x): number =>
//   parseFloat((x * 0.5).toFixed(2))

// //The functions below ensure that empty inputs can still be summed
// export const sumTwoInputs = (first, second) => (first || 0) + (second || 0)

// export const subTwoInputs = (first, second) => (first || 0) - (second || 0)

// export const sumThreeInputs = (first, second, third) =>
//   (first || 0) + (second || 0) + (third || 0)

// export const sumFourInputs = (first, second, third, fourth) =>
//   (first || 0) + (second || 0) + (third || 0) + (fourth || 0)


export const CalculationAddition = (x: string = '0', y: string = '0'): number =>
  parseFloat(x) + parseFloat(y) || 0;

export const CalculationLinePointFour = (x: string = '0'): number =>
  parseFloat(x) * 0.062 || 0;

export function CalculateOnePointFive(a: string, b: string): number {
  return parseFloat(a) + parseFloat(b) || 0;
}

export const CalculationTwoPointSix = (x: string): number =>
  parseFloat(x) * 0.0145;

export const CalculationThreePointFive = (x: string): number =>
  parseFloat(x) * 0.7;

export const CalculationThreePointSeven = (x: string): number =>
  parseFloat(x) * 0.5;

// The functions below ensure that empty inputs can still be summed
export const sumTwoInputs = (first: string, second: string): number =>
  (parseFloat(first) || 0) + (parseFloat(second) || 0);

export const subTwoInputs = (first: string, second: string): number =>
  (parseFloat(first) || 0) - (parseFloat(second) || 0);

export const sumThreeInputs = (
  first: string,
  second: string,
  third: string
): number =>
  (parseFloat(first) || 0) + (parseFloat(second) || 0) + (parseFloat(third) || 0);

export const sumFourInputs = (
  first: string,
  second: string,
  third: string,
  fourth: string
): number =>
  (parseFloat(first) || 0) +
  (parseFloat(second) || 0) +
  (parseFloat(third) || 0) +
  (parseFloat(fourth) || 0);

