// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react"
import { ERCWageTableContext } from "../MaximizePPP"
import { Dropdown, DropdownButton, Button } from "react-bootstrap"
import "../Basic941Functions/Basic941.css"

export default function ERCWageRow(props) {
  const {
    id,
    name,
    owner,
    wages_used,
    total_wages_ppp,
    eligible_ppp_outside_overlap,
    total_wages_erc_covered,
    total_health_cost_each_emp,
    adjust_ppp_caps_max,
    wages_ppp_inside_overlap,
    max_potential_erc,
    calc_wages_beyond_erc_cap,
    adjusted_erc_wages_1,
    potential_add_wages_ppp,
    modify_erc_wage_3,
    updateMethod,
    updateRowByKey,
    days_between,
  } = props

  const [mount, setMount] = React.useState(false)

  const submitValue = (event) => {
    updateMethod(id, event)
  }

  const [context, setContext, extraObject] =
    React.useContext(ERCWageTableContext)

  //this updates the name of the dropdown
  const updateDropdown = (value): any => {
    updateRowByKey(id, { owner: value })
  }

  return (
    <tr>
      <td>
        <input
          type="text"
          name="name"
          id=""
          value={name}
          onChange={(event) => submitValue(event)}
        />
      </td>

      <td>
        <DropdownButton id={`dropdown-basic-button-${id}`} title={`${owner}`}>
          <Dropdown.Item
            value="Owner"
            name={`Owner-${id}`}
            onClick={() => updateDropdown("Owner")}
          >
            Owner
          </Dropdown.Item>
          <Dropdown.Item
            value="Family"
            name={`Family-${id}`}
            onClick={() => updateDropdown("Family")}
          >
            Family
          </Dropdown.Item>
          <Dropdown.Item
            value="No"
            name={`No-${id}`}
            onClick={() => updateDropdown("No")}
          >
            No
          </Dropdown.Item>
        </DropdownButton>
      </td>

      <td>
        <input
          type="number"
          name="wages_used"
          id=""
          value={wages_used}
          onChange={(event) => submitValue(event)}
        />
      </td>

      <td>
        <input
          type="number"
          name="total_wages_ppp"
          id=""
          value={total_wages_ppp}
          onChange={(event) => submitValue(event)}
        />
      </td>

      <td>
        <input
          type="number"
          name="eligible_ppp_outside_overlap"
          id=""
          value={eligible_ppp_outside_overlap}
          onChange={(event) => submitValue(event)}
        />
      </td>

      <td>
        <input
          type="number"
          name="total_wages_erc_covered"
          id=""
          value={total_wages_erc_covered}
          onChange={(event) => submitValue(event)}
        />
      </td>

      <td>
        <input
          type="number"
          name="total_health_cost_each_emp"
          id=""
          value={total_health_cost_each_emp}
          onChange={(event) => submitValue(event)}
        />
      </td>

      <td>{adjust_ppp_caps_max}</td>

      <td>{wages_ppp_inside_overlap}</td>

      <td>{max_potential_erc}</td>

      <td>{calc_wages_beyond_erc_cap}</td>

      <td>{adjusted_erc_wages_1}</td>

      <td>{potential_add_wages_ppp}</td>

      <td>{modify_erc_wage_3}</td>
    </tr>
  )
}
