import * as React from "react"
import { Dropdown, Form, Button } from "react-bootstrap"

export default function QualTable({
  qualified,
  setOverride,
  setTableState,
  tableState,
  setReason,
  dateRangeArray,
  dateRangeState,
  reason,
  previousStep,
  setDateRangeState,
  nextStep,
  submitForm,
  resetForms
}) {
  // Loop through keys of QualifiedState and get values
  const QualifiedStateKeys = Object.keys(qualified)
  const QualifiedStateValues = Object.values(qualified)

  // const updateFieldDropdown = (event, key) => {
  //   event.preventDefault()

  //   const textContent = event.target.innerHTML
  //   console.log(textContent)
  //   const qualifiedReasonState = key + "_qualified_reason"
  //   console.log(qualifiedReasonState)
  //   setTableState((tableState) => ({
  //     ...tableState,
  //     [qualifiedReasonState]: textContent,
  //   }))
  // }

  // const textBoxReasons = (e, key) => {
  //   e.preventDefault()
  //   const textContent = e.target.value
  //   const reasonQuarter = key + "_para"

  //   setReason((reason) => ({ ...reason, [reasonQuarter]: textContent }))
  // }

  // const textBoxVisibility = (key) => {
  //   if (tableState[key] === "No") {
  //     return "visually-hidden"
  //   } else {
  //     return "pt-3"
  //   }
  // }

  // React.useEffect(() => {
  //   console.log(dateRangeArray)
  //   for (let i = 0; i < dateRangeArray.length; i++) {
  //     const startDate =
  //       dateRangeArray[i].date_range_start.toLocaleDateString("en-US")

  //     const endDate = dateRangeArray[i].end.toLocaleDateString("en-US")

  //     console.log(startDate)
  //   }
  // })

  //This runs through each of the suspension periods and checks if they qualify for a quarter
  React.useEffect(() => {
    //this is the range for each individual quarter
    //You'll notice the start dates are one day ahead of the actual qualification, this is due to the way the getTime() function and the date picker give different values for the same date
    const s_e_ranges = {
      q2_2020_range: {
        start: new Date("03/14/2020").getTime(),
        end: new Date("06/30/2020").getTime(),
      },
      q3_2020_range: {
        start: new Date("07/02/2020").getTime(),
        end: new Date("09/30/2020").getTime(),
      },
      q4_2020_range: {
        start: new Date("10/02/2020").getTime(),
        end: new Date("12/31/2020").getTime(),
      },
      q1_2021_range: {
        start: new Date("01/02/2021").getTime(),
        end: new Date("03/31/2021").getTime(),
      },
      q2_2021_range: {
        start: new Date("04/02/2021").getTime(),
        end: new Date("06/30/2021").getTime(),
      },
      q3_2021_range: {
        start: new Date("07/02/2021").getTime(),
        end: new Date("09/30/2021").getTime(),
      },
      // q4_2021_range: {
      //   start: new Date("10/02/2021").getTime(),
      //   end: new Date("12/31/2021").getTime(),
      // },
    }

    //This will loop through each active Suspension and if the quarter qualifies, will set the value equal to what type of suspension it is
    for (let i = 0; i < dateRangeArray.length; i++) {
      //start date of suspension
      const startDate = dateRangeArray[i].date_range_start.getTime()

      //end date of suspension
      const endDate = dateRangeArray[i].date_range_end.getTime()

      console.log("march13", s_e_ranges.q2_2020_range.start)
      console.log("start date", startDate)

      //these if statements check to see if the current start date or end date is within any of the valid date ranges
      if (
        (startDate >= s_e_ranges.q2_2020_range.start &&
          startDate <= s_e_ranges.q2_2020_range.end) ||
        (endDate >= s_e_ranges.q2_2020_range.start &&
          endDate <= s_e_ranges.q2_2020_range.end) ||
        (startDate <= s_e_ranges.q2_2020_range.start &&
          endDate >= s_e_ranges.q2_2020_range.end)
      ) {
        console.log("Qualifies for q2_2020")

        //if the dates are within the valid ranges they will be set to the type of suspension and become qualified
        setTableState((tableState) => ({
          ...tableState,
          q2_2020_qualified_reason: dateRangeArray[i].suspension_type,
        }))
      } else {
        console.log("DisQualifies q2_2020")

        //if the current state does not qualify for the date range of the if statment this else statement will be ran
        let verification = false

        //this will check if any date is within the suspension object is in a  qualifiying date range
        for (let x = 0; x < dateRangeArray.length; x++) {
          const startDateVeri = dateRangeArray[x].date_range_start.getTime()
          const endDateVeri = dateRangeArray[x].date_range_end.getTime()
          if (
            (startDateVeri >= s_e_ranges.q2_2020_range.start &&
              startDateVeri <= s_e_ranges.q2_2020_range.end) ||
            (endDateVeri >= s_e_ranges.q2_2020_range.start &&
              endDateVeri <= s_e_ranges.q2_2020_range.end) ||
            (startDateVeri <= s_e_ranges.q2_2020_range.start &&
              endDateVeri >= s_e_ranges.q2_2020_range.end)
          ) {
            //if a suspension date range qualifies the "verification" will be set to true, this will prevent the value from being reset to no
            verification = true
          }
        }

        //if there is not a single date within the suspension list that leads to qualification, then the qualified can be set back to "No"
        if (!verification) {
          setTableState((tableState) => ({
            ...tableState,
            q2_2020_qualified_reason: "No",
          }))
        }
      }

      if (
        (startDate >= s_e_ranges.q3_2020_range.start &&
          startDate <= s_e_ranges.q3_2020_range.end) ||
        (endDate >= s_e_ranges.q3_2020_range.start &&
          endDate <= s_e_ranges.q3_2020_range.end) ||
        (startDate <= s_e_ranges.q3_2020_range.start &&
          endDate >= s_e_ranges.q3_2020_range.end)
      ) {
        console.log("Qualifies for q3_2020")

        setTableState((tableState) => ({
          ...tableState,
          q3_2020_qualified_reason: dateRangeArray[i].suspension_type,
        }))
      } else {
        console.log("DisQualifies q3_2020")

        let verification = false

        for (let x = 0; x < dateRangeArray.length; x++) {
          const startDateVeri = dateRangeArray[x].date_range_start.getTime()
          const endDateVeri = dateRangeArray[x].date_range_end.getTime()
          if (
            (startDateVeri >= s_e_ranges.q3_2020_range.start &&
              startDateVeri <= s_e_ranges.q3_2020_range.end) ||
            (endDateVeri >= s_e_ranges.q3_2020_range.start &&
              endDateVeri <= s_e_ranges.q3_2020_range.end) ||
            (startDateVeri <= s_e_ranges.q3_2020_range.start &&
              endDateVeri >= s_e_ranges.q3_2020_range.end)
          ) {
            verification = true
          }
        }

        if (!verification) {
          setTableState((tableState) => ({
            ...tableState,
            q3_2020_qualified_reason: "No",
          }))
        }
      }

      if (
        (startDate >= s_e_ranges.q4_2020_range.start &&
          startDate <= s_e_ranges.q4_2020_range.end) ||
        (endDate >= s_e_ranges.q4_2020_range.start &&
          endDate <= s_e_ranges.q4_2020_range.end) ||
        (startDate <= s_e_ranges.q4_2020_range.start &&
          endDate >= s_e_ranges.q4_2020_range.end)
      ) {
        console.log("Qualifies for q4_2020")

        setTableState((tableState) => ({
          ...tableState,
          q4_2020_qualified_reason: dateRangeArray[i].suspension_type,
        }))
      } else {
        console.log("DisQualifies q4_2020")

        let verification = false

        for (let x = 0; x < dateRangeArray.length; x++) {
          const startDateVeri = dateRangeArray[x].date_range_start.getTime()
          const endDateVeri = dateRangeArray[x].date_range_end.getTime()
          if (
            (startDateVeri >= s_e_ranges.q4_2020_range.start &&
              startDateVeri <= s_e_ranges.q4_2020_range.end) ||
            (endDateVeri >= s_e_ranges.q4_2020_range.start &&
              endDateVeri <= s_e_ranges.q4_2020_range.end) ||
            (startDateVeri <= s_e_ranges.q4_2020_range.start &&
              endDateVeri >= s_e_ranges.q4_2020_range.end)
          ) {
            verification = true
          }
        }

        if (!verification) {
          setTableState((tableState) => ({
            ...tableState,
            q4_2020_qualified_reason: "No",
          }))
        }
      }

      if (
        (startDate >= s_e_ranges.q1_2021_range.start &&
          startDate <= s_e_ranges.q1_2021_range.end) ||
        (endDate >= s_e_ranges.q1_2021_range.start &&
          endDate <= s_e_ranges.q1_2021_range.end) ||
        (startDate <= s_e_ranges.q1_2021_range.start &&
          endDate >= s_e_ranges.q1_2021_range.end)
      ) {
        console.log("Qualifies for q1_2021")

        setTableState((tableState) => ({
          ...tableState,
          q1_2021_qualified_reason: dateRangeArray[i].suspension_type,
        }))
      } else {
        console.log("DisQualifies q1_2021")

        let verification = false

        for (let x = 0; x < dateRangeArray.length; x++) {
          const startDateVeri = dateRangeArray[x].date_range_start.getTime()
          const endDateVeri = dateRangeArray[x].date_range_end.getTime()
          if (
            (startDateVeri >= s_e_ranges.q1_2021_range.start &&
              startDateVeri <= s_e_ranges.q1_2021_range.end) ||
            (endDateVeri >= s_e_ranges.q1_2021_range.start &&
              endDateVeri <= s_e_ranges.q1_2021_range.end) ||
            (startDateVeri <= s_e_ranges.q1_2021_range.start &&
              endDateVeri >= s_e_ranges.q1_2021_range.end)
          ) {
            verification = true
          }
        }

        if (!verification) {
          setTableState((tableState) => ({
            ...tableState,
            q1_2021_qualified_reason: "No",
          }))
        }
      }

      if (
        (startDate >= s_e_ranges.q2_2021_range.start &&
          startDate <= s_e_ranges.q2_2021_range.end) ||
        (endDate >= s_e_ranges.q2_2021_range.start &&
          endDate <= s_e_ranges.q2_2021_range.end) ||
        (startDate <= s_e_ranges.q2_2021_range.start &&
          endDate >= s_e_ranges.q2_2021_range.end)
      ) {
        console.log("Qualifies for q2_2021")

        setTableState((tableState) => ({
          ...tableState,
          q2_2021_qualified_reason: dateRangeArray[i].suspension_type,
        }))
      } else {
        console.log("DisQualifies q2_2021")

        let verification = false

        for (let x = 0; x < dateRangeArray.length; x++) {
          const startDateVeri = dateRangeArray[x].date_range_start.getTime()
          const endDateVeri = dateRangeArray[x].date_range_end.getTime()
          if (
            (startDateVeri >= s_e_ranges.q2_2021_range.start &&
              startDateVeri <= s_e_ranges.q2_2021_range.end) ||
            (endDateVeri >= s_e_ranges.q2_2021_range.start &&
              endDateVeri <= s_e_ranges.q2_2021_range.end) ||
            (startDateVeri <= s_e_ranges.q2_2021_range.start &&
              endDateVeri >= s_e_ranges.q2_2021_range.end)
          ) {
            verification = true
          }
        }

        if (!verification) {
          setTableState((tableState) => ({
            ...tableState,
            q2_2021_qualified_reason: "No",
          }))
        }
      }

      if (
        (startDate >= s_e_ranges.q3_2021_range.start &&
          startDate <= s_e_ranges.q3_2021_range.end) ||
        (endDate >= s_e_ranges.q3_2021_range.start &&
          endDate <= s_e_ranges.q3_2021_range.end) ||
        (startDate <= s_e_ranges.q3_2021_range.start &&
          endDate >= s_e_ranges.q3_2021_range.end)
      ) {
        console.log("Qualifies for q3_2021")

        setTableState((tableState) => ({
          ...tableState,
          q3_2021_qualified_reason: dateRangeArray[i].suspension_type,
        }))
      } else {
        console.log("DisQualifies q3_2021")

        let verification = false

        for (let x = 0; x < dateRangeArray.length; x++) {
          const startDateVeri = dateRangeArray[x].date_range_start.getTime()
          const endDateVeri = dateRangeArray[x].date_range_end.getTime()
          if (
            (startDateVeri >= s_e_ranges.q3_2021_range.start &&
              startDateVeri <= s_e_ranges.q3_2021_range.end) ||
            (endDateVeri >= s_e_ranges.q3_2021_range.start &&
              endDateVeri <= s_e_ranges.q3_2021_range.end) ||
            (startDateVeri <= s_e_ranges.q3_2021_range.start &&
              endDateVeri >= s_e_ranges.q3_2021_range.end)
          ) {
            verification = true
          }
        }

        if (!verification) {
          setTableState((tableState) => ({
            ...tableState,
            q3_2021_qualified_reason: "No",
          }))
        }
      }

      // if (
      //   (startDate >= s_e_ranges.q4_2021_range.start &&
      //     startDate <= s_e_ranges.q4_2021_range.end) ||
      //   (endDate >= s_e_ranges.q4_2021_range.start &&
      //     endDate <= s_e_ranges.q4_2021_range.end) ||
      //   (startDate <= s_e_ranges.q4_2021_range.start &&
      //     endDate >= s_e_ranges.q4_2021_range.end)
      // ) {
      //   console.log("Qualifies for q4_2021")

      //   setTableState((tableState) => ({
      //     ...tableState,
      //     q4_2021_qualified_reason: dateRangeArray[i].suspension_type,
      //   }))
      // } else {
      //   console.log("DisQualifies q4_2021")

      //   let verification = false

      //   for (let x = 0; x < dateRangeArray.length; x++) {
      //     const startDateVeri = dateRangeArray[x].date_range_start.getTime()
      //     const endDateVeri = dateRangeArray[x].date_range_end.getTime()
      //     if (
      //       (startDateVeri >= s_e_ranges.q4_2021_range.start &&
      //         startDateVeri <= s_e_ranges.q4_2021_range.end) ||
      //       (endDateVeri >= s_e_ranges.q4_2021_range.start &&
      //         endDateVeri <= s_e_ranges.q4_2021_range.end) ||
      //       (startDateVeri <= s_e_ranges.q4_2021_range.start &&
      //         endDateVeri >= s_e_ranges.q4_2021_range.end)
      //     ) {
      //       verification = true
      //     }
      //   }

      //   if (!verification) {
      //     setTableState((tableState) => ({
      //       ...tableState,
      //       q4_2021_qualified_reason: "No",
      //     }))
      //   }
      // }
    }
  }, [dateRangeArray, dateRangeState])

  //This will reset all of the values from the tableState if the dateRangeArray is empty
  React.useEffect(() => {
    if (dateRangeArray.length === 0) {
      Object.keys(tableState).forEach((key) => {
        setTableState((tableState) => ({
          ...tableState,
          [key]: "No",
        }))
      })
    }
  }, [dateRangeArray, dateRangeState])

  const dropdownName = (i) => {
    return Object.values(tableState)[i]
  }

  const formatKeyName = (key) => {
    return key.replace(/_/g, " ").toUpperCase()
  }

  const formatKeyValue = (i) => {
    const condition = QualifiedStateValues[i] || dropdownName(i) !== "No"
    return condition ? "Yes" : "No"
  }

  React.useEffect(() => setOverride(tableState), [tableState])

  React.useEffect(() => {
    console.log(dateRangeArray)
  }, [dateRangeArray])

  return (
    <div className="text-center gr-table">
    <div className="QualTable d-flex justify-content-center my-5">
      <table className=" w-50 table table-bordered">
        <tbody>
          <tr>
            <th className="gr-table-head">Quarter</th>
            {/* <th className="table-primary">
              During this quarter was your business either{" "}
              <a
                target="_blank"
                href="https://www.irs.gov/newsroom/covid-19-related-employee-retention-credits-determining-when-an-employers-trade-or-business-operations-are-considered-to-be-fully-or-partially-suspended-due-to-a-governmental-order-faqs#are-an-employer-s-operations-considered-to-be-partially-suspended-for-purposes-of-the-employee-retention-credit"
              >
                partially
              </a>{" "}
              or{" "}
              <a
                target="_blank"
                href="https://www.irs.gov/newsroom/covid-19-related-employee-retention-credits-determining-when-an-employers-trade-or-business-operations-are-considered-to-be-fully-or-partially-suspended-due-to-a-governmental-order-faqs#if-governmental-order-causes-suppliers-essential-business-suspend-their-operations-essential-business-considered-have-suspension-operations"
              >
                fully
              </a>{" "}
              suspended?
            </th> */}
            <th className="gr-table-head">Qualified</th>
          </tr>
          {QualifiedStateKeys.map((key, i) => {
            const keyName = formatKeyName(key)
            const keyValue = formatKeyValue(i)

            return (
              <tr key={i}>
                <td className="">{keyName}</td>
                {/* <td>
                  {" "}
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {dropdownName(i)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        name="No"
                        value="No"
                        onClick={(e) => updateFieldDropdown(e, key)}
                      >
                        No
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Partial"
                        value="Partial"
                        onClick={(e) => updateFieldDropdown(e, key)}
                      >
                        Partial Suspension
                      </Dropdown.Item>
                      <Dropdown.Item
                        name="Full"
                        value="Full"
                        onClick={(e) => updateFieldDropdown(e, key)}
                      >
                        Full Suspension
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Form
                    className={textBoxVisibility(key + "_qualified_reason")}
                  >
                    <Form.Control
                      as="textarea"
                      placeholder="Explain why you were partially or fully suspended. Please reference government orders, state or local, that impacted your qualification."
                      style={{ height: "100px" }}
                      onChange={(e) => textBoxReasons(e, key)}
                    />
                  </Form>
                </td> */}
                <td
                  className={
                    keyValue === "Yes" ? "table-success" : "table-danger"
                  }
                >
                  {keyValue}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      </div>
      <div className="container row text-center">
      <button
        className="btn back-btn col-md-2 offset-md-4"
        onClick={(e) => {
          e.preventDefault()
          previousStep()
        }}
      >
        Go Back
      </button>
      {/* This is commented out because there isn't functionality yet */}
      {/* <Button type="reset" className=" delete-sus col-md-2" onClick={resetForms}>
        Reset Form
      </Button>{" "} */}
      <Button
        id="submit"
        type="Submit"
        variant=""
        className=" col-md-2 add-sus"
        onClick={submitForm}
      >
        Proceed
      </Button>{" "}
     
      </div>
    </div>
  )
}
