// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from 'react'

function maximizeApi(id, setComplete) {
  fetch(`/ertc_filings/${`${id}`}/generate_package`, {
    method: 'GET',
  }).then(response => {
    const json = response.json()
    console.log("Response", json)
    setComplete(true)
  })
}

// Get Request to '/maximize_filing/${`${id}`}'
export default function GeneratePDF({ id }) {
  const [complete, setComplete] = React.useState(false)

  return (
    <div class="all-font">
      <h3>Generate PDFs</h3>
      {complete && (
        <a href={`/ertc_filings/${id}`} className="btn next-btn">
          Continue
        </a>
      )}
      {!complete && (
        <button className="btn add-sus" onClick={() => maximizeApi(id, setComplete)}>
          Generate
        </button>
      )}
    </div>
  )
}
