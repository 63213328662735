// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react"
import jsPDF from "jspdf"
import "jspdf-autotable"

export default function PDF({
  docname,
  title,
  headers,
  data,
  totals,
  notes,
  qualified,
  recovery,
  suspensions,
}) {

  const mapped_headers = headers[0].map(header => header.replace(/_/g, " "))
  console.log("Props", docname, title, mapped_headers, data, totals, notes)
  const generatePDF = () => {
    const unit = "pt"
    const size = "A4"

    // portrait or landscape
    const orientation = "landscape"

    const marginLeft = 40

    const doc = new jsPDF(orientation, unit, size)

    // Replace the _ with a space in each header
    doc.setFontSize(8)
    const tableStyles = startY => ({
      startY: startY,
      styles: { fontSize: 8 },
      headStyles: { fontSize: 8 },
      bodyStyles: { fontSize: 7 },
      columnStyles: { fontSize: 8 }
    })

    const orange = [243, 149, 57] as const
    const green = [140, 255, 50] as const

    doc.text(title, marginLeft, 40)

    // if totals object has keys
    if (totals) {
      const totalKeys = Object.keys(totals)

      let totalsContent = {
        ...tableStyles(50),
        head: [totalKeys],
        body: [Object.values(totals)],
        willDrawCell: function (hook) {
          console.log("Row", hook)

          // Wages for PPP
          const wagesForPPP = Object.keys(totals).findIndex(header =>
            header.includes("Earnings for PPP")
          )

          console.log(wagesForPPP)
          if (
            hook.column.index == wagesForPPP &&
            hook.row.index == 0 &&
            hook.row.section == "body"
          ) {
            // doc.setFillColor(...orange)
          }
        },
      }

      doc.autoTable(totalsContent)
    }

    let content = {
      ...tableStyles(totals ? 100 : 50),
      head: [mapped_headers],
      body: data,
      willDrawCell: function (hook) {
        console.log("Row", hook)
        // if (hook.row.raw[0].includes("Part 1")) {
        //   doc.setFillColor(...orange)
        // }

        if (hook.row.section == "body") {
          // ERC Calc Total
          const ercCalc = mapped_headers.findIndex(header =>
            header.includes("ERC Calc")
          )

          if (
            hook.column.index == ercCalc &&
            hook.row.index == data.length - 1
          ) {
            doc.setFillColor(...green)
          }

          const excessCol = mapped_headers.findIndex(header =>
            header.includes("Eligible Earnings for PPP Without ERC Overlap")
          )

          if (
            hook.column.index == excessCol &&
            hook.row.index == data.length - 1
          ) {
            doc.setFillColor(...orange)
          }

          const wageRemCol = mapped_headers.findIndex(header =>
            header.includes("Wages Removed From ERC")
          )

          if (
            hook.column.index == wageRemCol &&
            hook.row.index == data.length - 1
          ) {
            doc.setFillColor(...orange)
          }

          const surEarnCol = mapped_headers.findIndex(header =>
            header.includes("Surplus Earnings Available for PPP")
          )

          if (
            hook.column.index == surEarnCol &&
            hook.row.index == data.length - 1
          ) {
            doc.setFillColor(...orange)
          }

          const excessCol2 = mapped_headers.findIndex(header =>
            header.includes("Excess Available for PPP Forgiveness")
          )

          if (
            hook.column.index == excessCol2 &&
            hook.row.index == data.length - 1
          ) {
            doc.setFillColor(...orange)
          }

          // Insurance
          const insuranceCol = mapped_headers.findIndex(header =>
            header.includes("Employee Insurance")
          )
          if (hook.column.index == insuranceCol && hook.cell.raw != "$0.00") {
            doc.setFillColor(...orange)
          }

          // Retirement
          const retireCol = mapped_headers.findIndex(header =>
            header.includes("Employee Retirement")
          )
          if (hook.column.index == retireCol && hook.cell.raw != "$0.00") {
            doc.setFillColor(...orange)
          }
        }
      },
    }

    doc.autoTable(content)

    if (qualified) {
      const qualifiedKeys = Object.keys(qualified[0])
      console.log("Qualified", qualifiedKeys, qualified)

      let qualifiedContent = {
        ...{
          ...tableStyles(130),
          ...{
            minCellWidth: 200,
          },
        },
        tableWidth: "wrap",
        head: [qualifiedKeys],
        body: qualified.map(row => Object.values(row)),
      }

      doc.autoTable(qualifiedContent)
    }

    if (recovery) {
      const recoveryKeys = Object.keys(recovery[0])
      console.log("Recovery", recoveryKeys, recovery)

      let recoveryContent = {
        ...{
          ...tableStyles(130),
          ...{
            minCellWidth: 200,

            margin: 130,
          },
        },
        tableWidth: "wrap",
        head: [recoveryKeys],
        body: recovery.map(row => Object.values(row)),
      }

      doc.autoTable(recoveryContent)
    }

    if (suspensions) {
      const suspensionKeys = Object.keys(suspensions[0])
      console.log("Suspensions", suspensionKeys, suspensions)

      let suspensionContent = {
        ...{
          ...tableStyles(280),
          ...{
            minCellWidth: 200,
          },
        },
        tableWidth: "wrap",
        head: [suspensionKeys],
        body: suspensions.map(row => Object.values(row)),
      }

      doc.autoTable(suspensionContent)
    }

    doc.save(docname)
  }

  return <TaskCard title={title} generatePDF={generatePDF} />
}


function TaskCard({ title, generatePDF }) {
  return (
    <div className="card mb-3 hover-shadow-lg clearfix col-lg-6 offset-lg-3">
      <div className="card-body row d-flex align-items-center flex-wrap flex-lg-nowrap py-3 clearfix">
        <div className="col-lg-6 col-8 pl-0 pl-md-2 pt-3 pt-lg-0 ps-3 text-start clearfix">
          <span className="h6 text-md task-name">{title}</span>
        </div>
        <div className="col-12 col-lg-4 offset-lg-2">
          <button
            className="btn btn-sm download-btn pull-right"
            onClick={generatePDF}
          >
            Download Worksheet
          </button>
        </div>
      </div>
    </div>
  )
}