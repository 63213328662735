// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import React from 'react';
import { submitToAPI } from './APIFunctions';
import QuestionPPP from './QuestionPPP';
import { initialState as initialStatePPP } from './QuestionPPP';

// Return an array with ppp_loans if it exists, otherwise return an empty array
function initialState(ppp_loans) {
  return ppp_loans ? ppp_loans : []
}

// Send data to API
function sendData(url_to_ertc_filing, form_authenticity_token, data, callback) {
  submitToAPI(url_to_ertc_filing, 'POST', form_authenticity_token, data, callback)
}

export default function PPP({ ppp_loans, ertc_filing, form_authenticity_token }) {
  const [pppLoansState, setPPPLoansState] = React.useState(initialState(ppp_loans))

  // Console log pppLoansState
  React.useEffect(() => {
    console.log("PPP", pppLoansState)
  }, [pppLoansState])

  // Size of ppp_loans array stored in a state variable
  const [pppLoansStateSize, setPPPLoansStateSize] = React.useState(ppp_loans.length || 0)

  // function to go back to ertc_filing.url
  const goBack = () => {
    const url = ertc_filing.url.replace(/\.json$/, '')
    window.location.href = url
  }

  // Function to replace pppLoanState element by index
  const replacePPPLoan = (index, pppLoanState) => {
    const newPPPLoansState = [...pppLoansState]
    newPPPLoansState[index] = pppLoanState
    setPPPLoansState(newPPPLoansState)
  }

  function setLoanCount(event) {
    const loanCount = event.target.value
    let newPPPLoansState = []

    for (let i = 0; i < loanCount; i++) {
      // Get element from pppLoansState array matching i
      const pppLoanState = pppLoansState[i]
      console.log(pppLoanState)
      newPPPLoansState.push(initialStatePPP({}, pppLoanState))
    }
    setPPPLoansState(newPPPLoansState)
    setPPPLoansStateSize(loanCount)

    console.log("New", newPPPLoansState);
  }

  const preparedForAPI = {
    ppp_loans: pppLoansState,
    ertc_filing_id: ertc_filing.id
  }

  // Submit data to API from button
  const submitForm = (event) => {
    event.preventDefault()
    sendData('/ppp_loans/submit', form_authenticity_token, preparedForAPI, () => {
      window.location.href = ertc_filing.url.replace('.json', '')
    })
  }

  // UseEffect to console log pppLoansState
  React.useEffect(() => {
    console.log("PPP", pppLoansState)
  }, [pppLoansState])


  return (
    <div className="col-6 offset-3 all-font">
      {/* Label and Dropdown "How many PPP loans did you recieve?" */}
      <div>
        <div className="form-group">
          <label htmlFor="ppp_loans_count">How many PPP loans did you recieve?</label>
          <select className="form-select" id="ppp_loans_count" onChange={e => setLoanCount(e)}>
            <option value="0" selected={pppLoansStateSize === 0}>I did not recieve any PPP loans.</option>
            <option value="1" selected={pppLoansStateSize == 1}>1</option>
            <option value="2" selected={pppLoansStateSize == 2}>2</option>
          </select>
        </div>
      </div>

      {/* QuestionPPP component for each pppLoansState element */}
      {(pppLoansState.length > 0) && pppLoansState.map((pppLoan, index) => (
        <QuestionPPP
          key={index}
          index={index}
          ppp_loan={pppLoan}
          setPPPLoansState={ppp => replacePPPLoan(index, ppp)}
        />
      ))}

      {/* Buttons */}
      <div className="text-center pb-3">
        <button className="btn back-btn" onClick={goBack}>Go Back</button>
        <button className="btn next-btn" onClick={submitForm}>Continue</button>
      </div>
    </div>
  );
}