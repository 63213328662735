// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react"
import { Form } from "react-bootstrap"

// https://github.com/wwayne/react-tooltip
import ReactTooltip from "react-tooltip"
import { TooltipMessage } from "../Utilities"

import "../Basic941Functions/Basic941.css"
import {
  CalculateOnePointFive,
  CalculationAddition,
  CalculationLinePointFour,
} from "../Basic941Functions/Basic941Math"

import { ERCBasic941Context } from "../Basic9412021"

// export interface QuantStateProps {}
// export interface CalculatedStateProps {}

const Basic9412021Part1 = () => {
  const [context, setContext]: any = React.useContext(ERCBasic941Context)

  const initialState: any = { ...context.partOne }
  const [state, setState] = React.useState(initialState)

  const calculatedInitialState: any = {}
  const [calculated, setCalculated] = React.useState(calculatedInitialState)

  const [selectedRadioBtn1, setSelectedRadioBtn1] = React.useState("")
  const [selectedRadioBtn2, setSelectedRadioBtn2] = React.useState("")

  const updateField = (event) => {
    event.preventDefault()
    const targetValue = parseFloat(event.target.value)

    setState((state) => ({
      ...state,
      [event.target.name]: targetValue,
    }))
  }
  const isRadioSelected1 = (value: string): boolean =>
    selectedRadioBtn1 === value

  const handleRadioClick1 = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedRadioBtn1(e.currentTarget.value)
  }

  const isRadioSelected2 = (value: string): boolean =>
    selectedRadioBtn2 === value

  const handleRadioClick2 = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedRadioBtn2(e.currentTarget.value)
  }

  const onePointFiveDisable = () =>
    selectedRadioBtn1 === "radioYesOne" ? false : true

  const onePointSixDisable = () =>
    selectedRadioBtn2 === "radioYesTwo" ? false : true

  React.useEffect(() => {
    const { onePointOne, onePointTwo } = state

    if (
      (onePointOne && onePointTwo) ||
      onePointOne === 0 ||
      onePointTwo === 0
    ) {
      const calcOnePointThree: number = CalculationAddition(
        onePointOne,
        onePointTwo
      )
      const calcOnePointFour: number =
        CalculationLinePointFour(calcOnePointThree)
      const calcOnePointSeven: number = calcOnePointFour

      setCalculated((c) => ({
        ...c,
        calcOnePointThree,
        calcOnePointFour,
        calcOnePointSeven,
      }))
    } else {
      setCalculated((c) => ({
        ...c,
        calcOnePointThree: 0,
        calcOnePointFour: 0,
        calcOnePointSeven: 0,
      }))
    }
  }, [state.onePointOne, state.onePointTwo])

  React.useEffect(() => {
    const { onePointFiveI, onePointFiveII } = state
    const calcOnePointFive: number = CalculateOnePointFive(
      onePointFiveI,
      onePointFiveII
    )
    setCalculated((c) => ({ ...c, calcOnePointFive }))
  }, [state.onePointFiveI, state.onePointFiveII])

  React.useEffect(() => {
    const { onePointSixI } = state
    const { calcOnePointFour, calcOnePointFive } = calculated

    if (calcOnePointFour && onePointSixI && calcOnePointFive) {
      const calcOnePointSeven: number =
        calcOnePointFour + onePointSixI + calcOnePointFive
      setCalculated((c) => ({ ...c, calcOnePointSeven }))
    } else if (calcOnePointFour && onePointSixI && calcOnePointFive === 0) {
      const calcOnePointSeven: number = calcOnePointFour + onePointSixI
      setCalculated((c) => ({ ...c, calcOnePointSeven }))
    } else if (calcOnePointFour && !onePointSixI && calcOnePointFive > 0) {
      const calcOnePointSeven: number = calcOnePointFour + calcOnePointFive
      setCalculated((c) => ({ ...c, calcOnePointSeven }))
    } else {
      if (onePointSixI) {
        const calcOnePointSeven: number = calcOnePointFour + onePointSixI
        setCalculated((c) => ({ ...c, calcOnePointSeven }))
      } else {
        const calcOnePointSeven: number = calcOnePointFour
        setCalculated((c) => ({ ...c, calcOnePointSeven }))
      }
    }
  }, [
    state.onePointSixI,
    calculated.calcOnePointFour,
    calculated.calcOnePointFive,
  ])

  React.useEffect(() => {
    const { onePointEight, onePointNine, onePointTen } = state
    const { calcOnePointSeven } = calculated

    if (
      (onePointEight && onePointNine && onePointTen) ||
      onePointEight === 0 ||
      onePointNine === 0 ||
      onePointTen === 0
    ) {
      const calcOnePointEleven = onePointEight + onePointNine + onePointTen
      const calcOnePointTwelve =
        calcOnePointEleven >= calcOnePointSeven
          ? calcOnePointEleven - calcOnePointSeven
          : calcOnePointSeven - calcOnePointEleven
      setCalculated((c) => ({ ...c, calcOnePointEleven, calcOnePointTwelve }))
    } else {
      setCalculated((c) => ({
        ...c,
        calcOnePointEleven: 0,
        calcOnePointTwelve: 0,
      }))
    }
  }, [
    state.onePointEight,
    state.onePointNine,
    state.onePointTen,
    calculated.calcOnePointSeven,
  ])

  // For Debug
  // Any time our local state changes in Basic 941 2021 Part 1
  // Set HOC State to HOC State + our updated local state
  React.useEffect(() => {
    setContext({ ...context, partOne: state })
  }, [state])

  React.useEffect(() => {
    setContext({
      ...context,
      calculated: { ...context.calculated, partOne: calculated },
    })
  }, [calculated])

  return (
    <Form>
      <ReactTooltip />
      <h2 className="text-center">Basic 941 2021 Form</h2>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th className="table-primary">Part 1</th>
            <th className="table-primary">
              Corrective Share of Social Security
            </th>
            <th className="table-primary">Input Your Data</th>
          </tr>
          <tr>
            <th className="table-primary"> 1.01</th>
            <th className="table-warning">
              Enter SS Wages from Form 941 (line 5a, Column 1)
              <TooltipMessage message="Hello!!" />
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="onePointOne"
                value={state.onePointOne}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 1.02</th>
            <th className="table-warning">
              Enter SS Tips from Form 941 (line 5b, Column 1)
              <TooltipMessage message="Hello Again!!" />
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="onePointTwo"
                value={state.onePointTwo}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 1.03</th>
            <th className="table-warning">Add lines 1.01 and 1.02</th>
            <td className="table-active">{calculated.calcOnePointThree}</td>
          </tr>
          <tr>
            <th className="table-primary"> 1.04</th>
            <th className="table-warning">Multiply line 1.03 by 6.2%</th>
            <td className="table-active">{calculated.calcOnePointFour}</td>
          </tr>
          <tr>
            <th className="table-primary"> 1.05</th>
            <th className="table-warning">
              Also Apply for Sick Pay Credit this Quarter?
            </th>
            <td className="table-active">
              <Form.Group>
                <Form.Check
                  value="radioYesOne"
                  type="radio"
                  label="Yes"
                  name="sick_pay_credit"
                  checked={isRadioSelected1("radioYesOne")}
                  onChange={handleRadioClick1}
                />
                <Form.Check
                  value="radioNoOne"
                  type="radio"
                  label="No"
                  name="sick_pay_credit"
                  checked={isRadioSelected1("radioNoOne")}
                  onChange={handleRadioClick1}
                />
              </Form.Group>
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 1.05 I</th>
            <th className="table-warning">
              Amounts of Sick Pay being claimed for Credit from Form 941 (line
              8) - Enter as a Negative Number
            </th>
            <td className="table-dark">
              <Form.Control
                disabled={onePointFiveDisable()}
                type="number"
                name="onePointFiveI"
                placeholder="$"
                value={state.onePointFiveI}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 1.05 II</th>
            <th className="table-warning">
              Employer Share of SS included on Form 941-X (line 20, Column 4)
            </th>
            <td className="table-dark">
              <Form.Control
                disabled={onePointFiveDisable()}
                type="number"
                name="onePointFiveII"
                placeholder="$"
                value={state.onePointFiveII}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>

          <tr>
            <th className="table-primary"> 1.06</th>
            <th className="table-warning">
              Did you Recieve a Section 3121(q)Notice
            </th>
            <td className="table-active">
              <Form.Group>
                <Form.Check
                  value="radioYesTwo"
                  type="radio"
                  label="Yes"
                  name="section_3121"
                  checked={isRadioSelected2("radioYesTwo")}
                  onChange={handleRadioClick2}
                />
                <Form.Check
                  value="radioNoTwo"
                  type="radio"
                  label="No"
                  name="section_3121"
                  checked={isRadioSelected2("radioNoTwo")}
                  onChange={handleRadioClick2}
                />
              </Form.Group>
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 1.06 I</th>
            <th className="table-warning">
              Enter the amount of the employer Share of SS Tax from the 3121
              Notice
            </th>
            <td className="table-dark">
              <Form.Control
                disabled={onePointSixDisable()}
                type="number"
                name="onePointSixI"
                placeholder="$"
                value={state.onePointSixI}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 1.07</th>
            <th className="table-warning">Employer Share of SS Tax</th>
            <td className="table-active">{calculated.calcOnePointSeven}</td>
          </tr>
          <tr>
            <th className="table-primary"> 1.08</th>
            <th className="table-warning">
              Enter the amount of Form 941 (line 11a)
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="onePointEight"
                value={state.onePointEight}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 1.09</th>
            <th className="table-warning">
              Enter the amount of Form 941 (line 23)
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="onePointNine"
                value={state.onePointNine}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 1.10</th>
            <th className="table-warning">
              Enter the amount from Form 5884-D (line 12) if applicable
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="onePointTen"
                value={state.onePointTen}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 1.11</th>
            <th className="table-warning">
              Total non-refundable credits already used against employer share
            </th>
            <td className="table-active">{calculated.calcOnePointEleven}</td>
          </tr>
          <tr>
            <th className="table-primary"> 1.12</th>
            <th className="table-warning">
              Employer Share of Social Security Tax Remaning
            </th>
            <td className="table-active">{calculated.calcOnePointTwelve}</td>
          </tr>
        </tbody>
      </table>
    </Form>
  )
}

export default Basic9412021Part1
