// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import { CalculationThreePointFive } from "../Basic941Functions/Basic941Math";
import { ERCBasic941Context } from "../Basic9412021";

const Basic9412021Part3 = () => {
  const [context, setContext]: any = React.useContext(ERCBasic941Context);

  const initialState: any = { ...context.partThree };
  const [state, setState] = React.useState(initialState);

  const calculatedInitialState: any = {};
  const [calculated, setCalculated] = React.useState(calculatedInitialState);

  const [selectedRadioBtn, setSelectedRadioBtn] = React.useState("");

  const isRadioSelected = (value: string): boolean =>
    selectedRadioBtn === value;

  const handleRadioClick = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedRadioBtn(e.currentTarget.value);
  };

  const updateField = (event) => {
    event.preventDefault();
    const targetValue = parseFloat(event.target.value);

    setState((state) => ({
      ...state,
      [event.target.name]: targetValue,
    }));
  };

  React.useEffect(() => {
    const { threePointOne, threePointTwo } = state;
    if (
      (threePointOne && threePointTwo) ||
      threePointOne === 0 ||
      threePointTwo === 0
    ) {
      const calcThreePointFour = threePointOne + threePointTwo;
      setCalculated((c) => ({ ...c, calcThreePointFour }));
    } else {
      setCalculated((c) => ({ ...c, calcThreePointFour: 0 }));
    }
  }, [state.threePointOne, state.threePointTwo]);

  React.useEffect(() => {
    const { calcThreePointFour } = calculated;
    if (calcThreePointFour) {
      const calcThreePointFive = CalculationThreePointFive(calcThreePointFour);
      setCalculated((c) => ({ ...c, calcThreePointFive }));
    } else {
      setCalculated((c) => ({ ...c, calcThreePointFive: 0 }));
    }
  }, [calculated.calcThreePointFour]);

  React.useEffect(() => {
    const calcThreePointSix = context.calculated.partOne.calcOnePointTwelve;
    if (calcThreePointSix) {
      setCalculated((c) => ({ ...c, calcThreePointSix }));
    } else {
      setCalculated((c) => ({ ...c, calcThreePointSix: 0 }));
    }
  }, [context.calculated.partOne.calcOnePointTwelve]);

  React.useEffect(() => {
    const calcThreePointSeven = context.calculated.partTwo.calcTwoPointSixteen;
    if (calcThreePointSeven) {
      setCalculated((c) => ({ ...c, calcThreePointSeven }));
    } else {
      setCalculated((c) => ({ ...c, calcThreePointSeven: 0 }));
    }
  }, [context.calculated.partTwo.calcTwoPointSixteen]);

  React.useEffect(() => {
    const { calcThreePointSix, calcThreePointSeven } = calculated;
    if (
      calcThreePointSix >= calcThreePointSeven ||
      calcThreePointSeven === 0 ||
      calcThreePointSix === 0
    ) {
      const calcThreePointEight =
        calcThreePointSix >= calcThreePointSeven
          ? calcThreePointSix - calcThreePointSeven
          : calcThreePointSeven - calcThreePointSix;

      setCalculated((c) => ({ ...c, calcThreePointEight }));
    } else {
      setCalculated((c) => ({ ...c, calcThreePointEight: 0 }));
    }
  }, [calculated.calcThreePointSix, calculated.calcThreePointSeven]);

  React.useEffect(() => {
    const { calcThreePointFive, calcThreePointEight } = calculated;
    if (
      (calcThreePointFive && calcThreePointEight) ||
      calcThreePointFive === 0 ||
      calcThreePointEight === 0
    ) {
      const calcThreePointTen =
        calcThreePointFive < calcThreePointEight
          ? calcThreePointFive
          : calcThreePointEight;
      setCalculated((c) => ({ ...c, calcThreePointTen }));
    } else {
      setCalculated((c) => ({ ...c, calcThreePointTen: 0 }));
    }
  }, [calculated.calcThreePointFive, calculated.calcThreePointEight]);

  React.useEffect(() => {
    const { calcThreePointSeven, calcThreePointTen } = calculated;
    if (
      (calcThreePointSeven && calcThreePointTen) ||
      calcThreePointSeven === 0 ||
      calcThreePointTen === 0
    ) {
      const calcThreePointNine = calcThreePointSeven + calcThreePointTen;

      setCalculated((c) => ({ ...c, calcThreePointNine }));
    } else {
      setCalculated((c) => ({ ...c, calcThreePointNine: 0 }));
    }
  }, [calculated.calcThreePointSeven, calculated.calcThreePointTen]);

  React.useEffect(() => {
    const { calcThreePointFive, calcThreePointTen } = calculated;
    if (
      (calcThreePointFive && calcThreePointTen) ||
      calcThreePointFive === 0 ||
      calcThreePointTen === 0
    ) {
      const calcThreePointEleven =
        calcThreePointFive >= calcThreePointTen
          ? calcThreePointFive - calcThreePointTen
          : calcThreePointTen - calcThreePointFive;

      setCalculated((c) => ({ ...c, calcThreePointEleven }));
    } else {
      setCalculated((c) => ({ ...c, calcThreePointEleven: 0 }));
    }
  }, [calculated.calcThreePointFive, calculated.calcThreePointTen]);
  // For Debug
  React.useEffect(() => {
    console.log(selectedRadioBtn);
  }, [selectedRadioBtn]);

  React.useEffect(() => {
    setContext({ ...context, partThree: state });
  }, [state]);

  React.useEffect(() => {
    setContext({
      ...context,
      calculated: { ...context.calculated, partThree: calculated },
    });
  }, [calculated]);

  return (
    <Form>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th className="table-primary">Part 3</th>
            <th className="table-primary">Employee Retention Credit</th>
            <th className="table-primary">Input Your Data</th>
          </tr>
          <tr>
            <th className="table-primary"> 3.01</th>
            <th className="table-warning">
              Qualified Wages for the Quarter (Excluding Health Plan Expenses) -
              Must be Calculated Separately
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="threePointOne"
                value={state.threePointOne}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 3.02</th>
            <th className="table-warning">
              Qualified Health Expenses for the Quarter Allocate to Wages - Must
              be Calculated Separately
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="threePointTwo"
                value={state.threePointTwo}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 3.03</th>
            <th className="table-warning">
              Which 2021 Quarter are you applying for the Credit?
            </th>
            <td className="table-active">
              <Form.Group>
                <Form.Check
                  value="Q1 2021"
                  type="radio"
                  label="Q1 2021"
                  name="quarter_2021"
                  checked={isRadioSelected("Q1 2021")}
                  onChange={handleRadioClick}
                />
                <Form.Check
                  value="Q2 2021"
                  type="radio"
                  label="Q2 2021"
                  name="quarter_2021"
                  checked={isRadioSelected("Q2 2021")}
                  onChange={handleRadioClick}
                />
                <Form.Check
                  value="Q3 2021"
                  type="radio"
                  label="Q3 2021"
                  name="quarter_2021"
                  checked={isRadioSelected("Q3 2021")}
                  onChange={handleRadioClick}
                />
                <Form.Check
                  value="Q4 2021"
                  type="radio"
                  label="Q4 2021"
                  name="quarter_2021"
                  checked={isRadioSelected("Q4 2021")}
                  onChange={handleRadioClick}
                />
              </Form.Group>
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 3.04</th>
            <th className="table-warning">Total of Calculations</th>
            <td className="table-active">{calculated.calcThreePointFour}</td>
          </tr>
          <tr>
            <th className="table-primary"> 3.05</th>
            <th className="table-warning">
              Retention Credit Calculation (70%)
            </th>
            <td className="table-active">{calculated.calcThreePointFive}</td>
          </tr>
          <tr>
            <th className="table-primary"> 3.06</th>
            <th className="table-warning">Employer Social Security Tax</th>
            <td className="table-active">{calculated.calcThreePointSix}</td>
          </tr>
          <tr>
            <th className="table-primary"> 3.07</th>
            <th className="table-warning">
              Nonrefundable Credit for Sick and Family Leave
            </th>
            <td className="table-active">{calculated.calcThreePointSeven}</td>
          </tr>
          <tr>
            <th className="table-primary"> 3.08</th>
            <th className="table-warning">Reduction in Calculation</th>
            <td className="table-active">{calculated.calcThreePointEight}</td>
          </tr>
          <tr>
            <th className="table-primary"> 3.09</th>
            <th className="table-warning">
              Total Nonrefundable Amount on Line 23
            </th>
            <td className="table-active">{calculated.calcThreePointNine}</td>
          </tr>
          <tr>
            <th className="table-primary"> 3.10</th>
            <th className="table-warning">
              Nonrefundable Portion of Employee Retention Credit
            </th>
            <td className="table-active">{calculated.calcThreePointTen}</td>
          </tr>
          <tr>
            <th className="table-primary"> 3.11</th>
            <th className="table-warning">
              Refundable Portion of Employee Retention Credit
            </th>
            <td className="table-active">{calculated.calcThreePointEleven}</td>
          </tr>
        </tbody>
      </table>
    </Form>
  );
};

export default Basic9412021Part3;
