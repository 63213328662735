// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react"
import { Form } from "react-bootstrap"
import "../Basic941Functions/Basic941.css"

import { sumTwoInputs } from "../Basic941Functions/Basic941Math"
import { sumThreeInputs } from "../Basic941Functions/Basic941Math"
import { sumFourInputs } from "../Basic941Functions/Basic941Math"

import ReactTooltip from "react-tooltip"
import { TooltipMessage } from "../Utilities"

import { ERCPre941XContext } from "../Pre941X"

const Pre941XPart1 = () => {
  const [context, setContext]: any = React.useContext(ERCPre941XContext)

  const initialState: any = { ...context.partOne }
  const [state, setState] = React.useState(initialState)

  const calculatedInitialState: any = {}
  const [calculated, setCalculated] = React.useState(calculatedInitialState)

  const updateField = event => {
    event.preventDefault()
    const targetValue = parseFloat(event.target.value)
    setState(state => ({ ...state, [event.target.name]: targetValue }))
  }

  //Colors Error Message
  const isNegative = value => {
    const color: string = value > 0 ? "table-danger" : "edit-field"
    return color
  }

  const oneDError = (oneDErr, twoAErr, twoCErr) => {
    const color: string =
      parseFloat(oneDErr) > parseFloat(twoAErr) + parseFloat(twoCErr) || parseFloat(oneDErr) === 0
        ? "edit-field"
        : "table-danger"
    return color
  }

  const isInputNaN = value => {
    return isNaN(value) ? "" : value
  }

  //Calculates One D
  React.useEffect(() => {
    const { oneB, oneC } = state

    const calcOneD: number = sumTwoInputs(oneB, oneC)
    setCalculated(c => ({
      ...c,
      calcOneD,
    }))
  }, [state.oneB, state.oneC])

  //Calculates One E
  React.useEffect(() => {
    const { calcOneD } = calculated

    const calcOneE = calcOneD * 0.062
    setCalculated(c => ({
      ...c,
      calcOneE,
    }))
  }, [calculated.calcOneD])

  //Calculates One I
  React.useEffect(() => {
    const { oneF, oneG, oneH } = state
    const { calcOneE } = calculated

    const calcOneI: number = sumFourInputs(oneF, oneG, oneH, calcOneE)
    setCalculated(c => ({
      ...c,
      calcOneI,
    }))
  }, [calculated.calcOneE, state.oneF, state.oneG, state.oneH])

  //Calculates One M
  React.useEffect(() => {
    const { oneJ, oneK, oneL } = state

    const calcOneM: number = sumThreeInputs(oneJ, oneK, oneL)

    setCalculated(c => ({
      ...c,
      calcOneM,
    }))
  }, [state.oneJ, state.oneK, state.oneL])

  //Calculates One N
  React.useEffect(() => {
    const { calcOneI, calcOneM } = calculated

    const calcOneN = parseFloat((calcOneI - calcOneM).toFixed(2))
    setCalculated(c => ({
      ...c,
      calcOneN,
    }))
  }, [calculated.calcOneI, calculated.calcOneM])

  React.useEffect(() => {
    setContext({ ...context, partOne: state })
  }, [state])

  React.useEffect(() => {
    setContext({
      ...context,
      calculated: { ...context.calculated, partOne: calculated },
    })
  }, [calculated])

  return (
    <div class="all-font">
      <h2 className="text-center">941-x Pre July 2021</h2>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th className="all-table-head">Part 1</th>
            <th className="all-table-head">
              Determine the corrected employer share of social security tax this
              quarter after it is reduced by any credit claimed on Form 8974,
              Form 5884-C, and/or Form 5884-D
            </th>
            <th className="all-table-head">Calculations Left</th>
            <th className="all-table-head">Calculations Right</th>
          </tr>
          <tr>
            <th className=""> 1a</th>
            <th className="">
              If you completed Worksheet 1 to claim a credit for qualified sick
              and family leave wages for leave taken before April 1, 2021, enter
              the amount from Worksheet 1, Step 1, line 1l, and go to Step 2. If
              you're not claiming a credit for qualified sick and family leave
              wages for leave taken before April 1, 2021, continue by completing
              lines 1b–1n below and then go to Step 2
            </th>
            <td className="table-active"></td>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="oneA"
                value={isInputNaN(state.oneA)}
                onChange={e => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className=""> 1b</th>
            <th className="">
              Enter the amount of social security wages from Form 941, Part 1,
              line 5a, column 1, or, if corrected, the amount from Form 941-X,
              line 8, column 1
              <TooltipMessage
                message={
                  <p>
                    Enter the{" "}
                    <a
                      target="_blank"
                      href="https://www.irs.gov/instructions/i941#en_US_202106_publink1000294678"
                    >
                      total wages
                    </a>
                    , including qualified sick leave wages and qualified family
                    leave wages, qualified wages (other than qualified health
                    plan expenses) for the employee retention credit; sick pay;
                    and taxable fringe benefits subject to social security taxes
                    you paid to your employees during the quarter.
                  </p>
                }
              />
            </th>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="oneB"
                value={isInputNaN(state.oneB)}
                onChange={e => updateField(e)}
              />
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 1c</th>
            <th className="">
              Enter the amount of social security tips from Form 941, Part 1,
              line 5b, column 1, or, if corrected, the amount from Form 941-X,
              line 11, column 1
              <TooltipMessage
                message={
                  <p>
                    Enter all{" "}
                    <a
                      target="_blank"
                      href="https://www.irs.gov/instructions/i941#en_US_202106_publink1000294678"
                    >
                      tips{" "}
                    </a>{" "}
                    your employees reported to you during the quarter until the
                    total of the tips and taxable wages, including wages
                    reported on line 5a, qualified sick leave wages reported on
                    line 5a(i), and qualified family leave wages reported on
                    line 5a(ii), for an employee reach $142,800 for the year.
                  </p>
                }
              />
            </th>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="oneC"
                value={isInputNaN(state.oneC)}
                onChange={e => updateField(e)}
              />
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 1d</th>
            <th className="">
              Add lines 1b and 1c
              <TooltipMessage message="The total Social Security wages and tips is added together here. " />
            </th>
            <td
              className={oneDError(
                calculated.calcOneD,
                context.partTwo.twoA,
                context.partTwo.twoC
              )}
            >
              {calculated.calcOneD}
            </td>
            <td
              className={oneDError(
                calculated.calcOneD,
                context.partTwo.twoA,
                context.partTwo.twoC
              )}
            >
              {" "}
              {oneDError(
                calculated.calcOneD,
                context.partTwo.twoA,
                context.partTwo.twoC
              ) === "table-danger" ? (
                <p>
                  Are you sure you want to proceed? Wages calculated were
                  greater than the originally filed 941. (See 2a and 2c)
                </p>
              ) : null}
            </td>
          </tr>
          <tr>
            <th className=""> 1e</th>
            <th className="">
              Multiply line 1d by 6.2% (0.062){" "}
              <TooltipMessage message="In 2020, Social Security tax rate is 6.2% " />
            </th>
            <td className="edit-field">{calculated.calcOneE}</td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 1f</th>
            <th className="">
              If you're a third-party payer of sick pay that isn't an agent and
              you're claiming credits for amounts paid to your employees, enter
              the employer share of social security tax included on Form 941,
              Part 1, line 8, or, if corrected, the amount of the employer share
              of social security tax on sick pay that you included on Form
              941-X, line 15, column 1 (enter as a NEGATIVE number){" "}
              <TooltipMessage
                message={
                  <p>
                    For{" "}
                    <a
                      target="_blank"
                      href="https://www.irs.gov/instructions/i941"
                    >
                      line 8{" "}
                    </a>{" "}
                    - If your third-party payer of sick pay that isn't your
                    agent (for example, an insurance company) transfers the
                    liability for the employer share of the social security and
                    Medicare taxes to you, enter a negative adjustment. If
                    you’re the third-party sick pay payer and you transferred
                    the liability for the employer share of the social security
                    and Medicare taxes to the employer, enter a negative
                    adjustment. No adjustment is reported on line 8 for sick pay
                    that is paid through a third party as an employer’s agent.
                    An employer’s agent bears no insurance risk and is
                    reimbursed on a cost-plus-fee basis for payment of sick pay
                    and similar amounts. If an employer uses an agent to pay
                    sick pay, the employer reports the wages on line 5a, line
                    5c, and, if the withholding threshold is met, line 5d,
                    unless the employer has an agency agreement with the
                    third-party payer that requires the third-party payer to do
                    the collecting, reporting, and/or paying or depositing
                    employment taxes on the sick pay.
                  </p>
                }
              />
            </th>
            <td className={isNegative(state.oneF)}>
              <Form.Control
                type="number"
                placeholder="$"
                name="oneF"
                value={isInputNaN(state.oneF)}
                onChange={e => updateField(e)}
              />
            </td>
            <td className={isNegative(state.oneF)}>
              {isNegative(state.oneF) === "table-danger" ? (
                <p>Must be Negative</p>
              ) : null}
            </td>
          </tr>
          <tr>
            <th className=""> 1g</th>
            <th className="">
              Employer share of social security tax included on Form 941-X, line
              20, column 4{" "}
              <TooltipMessage
                message={
                  <p>
                    <a
                      target="_blank"
                      href="https://www.irs.gov/pub/irs-pdf/f941x.pdf"
                    >
                      Form 941x{" "}
                    </a>{" "}
                    is Adjusted Employer's Quarterly Federal Tax Return or Claim
                    for Refund{" "}
                  </p>
                }
              />
            </th>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="oneG"
                value={isInputNaN(state.oneG)}
                onChange={e => updateField(e)}
              />
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 1h</th>
            <th className="">
              If you received a Section 3121(q) Notice and Demand during the
              quarter, enter the amount of the employer share of social security
              tax from the notice{" "}
              <TooltipMessage
                message={
                  <p>
                    A{" "}
                    <a
                      target="_blank"
                      href="https://www.irs.gov/pub/irs-drop/rr-12-18.pdf"
                    >
                      Section 3121(q) Notice and Demand{" "}
                    </a>
                    is made by the IRS when it advises the employer in writing
                    of the amount of tips received by the employee (or
                    employees) who failed to report or under-reported tips to
                    the employer.{" "}
                  </p>
                }
              />
            </th>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="oneH"
                value={isInputNaN(state.oneH)}
                onChange={e => updateField(e)}
              />
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 1i</th>
            <th className="">
              Employer share of social security tax. Combine lines 1e, 1f, 1g,
              and 1h{" "}
              <TooltipMessage message="The total Social Security wages and tips multiplied by the 6.2% tax rate equals the employer share of social security tax. " />
            </th>
            <td className="table-active"></td>
            <td className="edit-field">{calculated.calcOneI}</td>
          </tr>
          <tr>
            <th className=""> 1j</th>
            <th className="">
              Enter the amount from Form 941, Part 1, line 11a, or, if
              corrected, the amount from Form 941-X, line 16, column 1 (credit
              from Form 8974){" "}
              <TooltipMessage
                message={
                  <p>
                    A new or small business may be{" "}
                    <a
                      target="_blank"
                      href="https://www.irs.gov/businesses/small-businesses-self-employed/qualified-small-business-payroll-tax-credit-for-increasing-research-activities"
                    >
                      eligible{" "}
                    </a>
                    for up to $250,000 per year for qualifying research
                    expenses. To apply, a business must complete{" "}
                    <a
                      target="_blank"
                      href="https://www.irs.gov/pub/irs-pdf/f6765.pdf"
                    >
                      Form 6765{" "}
                    </a>{" "}
                    and{" "}
                    <a
                      target="_blank"
                      href="https://www.irs.gov/pub/irs-pdf/f8974.pdf"
                    >
                      Form 8974{" "}
                    </a>{" "}
                    and attach to your payroll tax return.
                  </p>
                }
              />
            </th>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="oneJ"
                value={isInputNaN(state.oneJ)}
                onChange={e => updateField(e)}
              />
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 1k</th>
            <th className="">
              Enter the amount from Form 5884-C, line 11, for this quarter (for
              quarters ending before April 1, 2021, this amount was also
              included on Form 941, Part 3, line 23, or, if corrected, the
              amount from Form 941-X, line 32, column 1) .................. 1k
              1l Enter the amount from Form 5884-D, line 12, for this quarter{" "}
              <TooltipMessage
                message={
                  <p>
                    <a
                      target="_blank"
                      href="https://www.irs.gov/forms-pubs/about-form-5884-c"
                    >
                      Form 5884-C{" "}
                    </a>
                    is the Work Opportunity Credit for Qualified Tax-Exempt
                    Organizations Hiring Quailified Veterns This form applys to
                    any qualified tax exempt organization - defined in{" "}
                    <a
                      target="_blank"
                      href="https://www.irs.gov/charities-non-profits/charitable-organizations/exemption-requirements-501c3-organizations"
                    >
                      Section 501(c){" "}
                    </a>{" "}
                    - to claim the work opportunity credit for qualified
                    first-year wages paid to qualified veterans.
                  </p>
                }
              />
            </th>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="oneK"
                value={isInputNaN(state.oneK)}
                onChange={e => updateField(e)}
              />
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 1l</th>
            <th className="">
              Enter the amount from Form 5884-D, line 12, for this quarter{" "}
              <TooltipMessage
                message={
                  <p>
                    {" "}
                    <a
                      target="_blank"
                      href="https://www.irs.gov/pub/irs-pdf/f5884d.pdf"
                    >
                      Form 5884-d{" "}
                    </a>
                    is the Employee Retention Credit for Certain Tax-Exempt
                    Organizations Affected by Qualified Disasters. A qualified
                    <a
                      target="_blank"
                      href="https://www.irs.gov/charities-non-profits/charitable-organizations/exemption-requirements-501c3-organizations"
                    >
                      {" "}
                      tax-exempt organization{" "}
                    </a>{" "}
                    that continued to pay or incur wages after activities of the
                    organization (treated as an active trade or business for
                    this purpose) became inoperable because of damage from a
                    qualified disaster may be able to use Form 5884-D to claim
                    the 2020 qualified disaster employee retention credit
                    against certain payroll taxes.
                  </p>
                }
              />
            </th>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="oneL"
                value={isInputNaN(state.oneL)}
                onChange={e => updateField(e)}
              />
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 1m</th>
            <th className="">
              Total nonrefundable credits already used against the employer
              share of social security tax. Add lines 1j, 1k, and 1l{" "}
            </th>
            <td className="table-active"></td>
            <td className="edit-field">{calculated.calcOneM}</td>
          </tr>
          <tr>
            <th className=""> 1n</th>
            <th className="">
              Employer share of social security tax remaining. Subtract line 1m
              from line 1i
            </th>
            <td className="table-active"></td>
            <td className="edit-field">{calculated.calcOneN}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Pre941XPart1
