import * as React from "react"

export function QualifiedList({ qualified }) {
  const result = Object.keys(qualified)
    .filter(key => qualified[key] !== false)
    .map(key => key.replace(/_/g, " "))

  const result_list = result.map(item => <li>{item.toUpperCase()}</li>)

  return (
    <div className="col-md-3 m-auto d-flex justify-content-center flex-column">
      <h3 className="text-center">You will qualify for:</h3>
      <ul className="mx-auto justify-content-center">{result_list}</ul>
    </div>
  )
}