import * as React from 'react';
import { Form } from "react-bootstrap"

export default function Recovery({ recovery, checkChange, previousStep, nextStep }) {
  console.log('recovery', recovery)
  return (
    <div className="gr-table text-center">
    <div className="QualTable d-flex justify-content-center my-5">
      <table className=" w-50 table ">
        <tbody>
          <tr>
            <td className="input-border gr-table-head">
              Did you qualify as a Recovery Startup Business during Q3 2021 or
              Q4 2021?
            </td>

            <td className="input-border">
              Q3 2021
              <Form.Check
                checked={recovery.q3_2021}
                type="checkbox"
                value="q3_2021"
                id="flexCheckDefault"
                onChange={() => checkChange("q3_2021")}
              />
            </td>
            <td className="input-border">
              Q4 2021{" "}
              <Form.Check
                checked={recovery.q4_2021}
                type="checkbox"
                value=""
                id="flexCheckDefault"
                onChange={() => checkChange("q4_2021")}
              />
            </td>
          </tr>
        </tbody>
      </table>
      </div>
      <button
        className="btn back-btn"
        onClick={(e) => {
          e.preventDefault()
          previousStep()
        }}
      >
        Go Back
      </button>
      <button
        className="btn next-btn"
        onClick={(e) => {
          e.preventDefault()
          nextStep()
        }}
      >
        Next
      </button>
    
    </div>
  )
}
