import * as React from 'react';
import MaskedInput from 'react-text-mask';

const einMask = [
  /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/
];

const EinField = ({ fieldName, fieldId, defaultValue }) => {
  const [value, setValue] = React.useState(defaultValue);
  return (
    <MaskedInput
      mask={einMask}
      placeholder="XX-XXXXXXX"
      name={fieldName}
      id={fieldId}
      className="form-control"
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

export default EinField;