// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react"
import { submitBasic9412020 } from "./APIFunctions"
import Basic9412020Part1 from "./Basic941Components/Basic9412020Part1"
import Basic9412020Part2 from "./Basic941Components/Basic9412020Part2"
import Basic9412020Part3 from "./Basic941Components/Basic9412020Part3"

// Bootstrap
import Button from "react-bootstrap/Button"

export const ERCBasic941Context2020 = React.createContext({})

export default function Basic9412020({
  url,
  id,
  form_authenticity_token,
  partOne,
  partTwo,
  partThree,
}) {
  const initialState = {
    partOne,
    partTwo,
    partThree,
    calculated: {
      partOne: {},
      partTwo: {},
      partThree: {},
    },
  }

  const [state, setState] = React.useState(initialState)

  React.useEffect(() => {
    console.log("HOC State", state)
  }, [state])

  const submitForm = () => {
    const values = { ...state.partOne, ...state.partTwo, ...state.partThree }
    const options = { url, form_authenticity_token, values }
    submitBasic9412020(options)
  }

  return (
    <ERCBasic941Context2020.Provider value={[state, setState]}>
      <Basic9412020Part1 />
      <Basic9412020Part2 />
      <Basic9412020Part3 />
      <Button type="reset" className="btnReset col-sm-1" onClick={submitForm}>
        Submit
      </Button>
    </ERCBasic941Context2020.Provider>
  )
}
