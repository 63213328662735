// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react"
import ERCWageRow from "./MaximizePPP/ERCWageRow"
import Button from "react-bootstrap/Button"
import { Form } from "react-bootstrap"

import ReactTooltip from "react-tooltip"
import { TooltipMessage } from "./Utilities"

import {
  adjustedWagesOne,
  adjustedWagesThree,
  maxPotentialERC,
  potentialWagesPPP,
  pppWageInsideOverlap,
  pppWageMaximization,
  wagesBeyondERC,
} from "./MaximizePPPFunctions/MaxPPPMath"

export interface ERCRowProp {
  id: number
  name: string
  owner: "No" | "Owner" | "Family"
  wages_used: number
  total_wages_ppp: number
  eligible_ppp_outside_overlap: number
  total_wages_erc_covered: number
  total_health_cost_each_emp: number
  adjust_ppp_caps_max: number
  wages_ppp_inside_overlap: number
  max_potential_erc: number
  calc_wages_beyond_erc_cap: number
  adjusted_erc_wages_1: number
  potential_add_wages_ppp: number
  modify_erc_wage_3: number
}

const newPerson = (newId) => {
  return {
    id: newId,
    name: "",
    owner: "No",
    wages_used: 0,
    total_wages_ppp: 0,
    eligible_ppp_outside_overlap: 0,
    total_wages_erc_covered: 0,
    total_health_cost_each_emp: 0,
    adjust_ppp_caps_max: 0,
    wages_ppp_inside_overlap: 0,
    max_potential_erc: 0,
    calc_wages_beyond_erc_cap: 0,
    adjusted_erc_wages_1: 0,
    potential_add_wages_ppp: 0,
    modify_erc_wage_3: 0,
  }
}

const initialState: Array<ERCRowProp> = [
  {
    id: 1,
    name: "Frank",
    owner: "Owner",
    wages_used: 2,
    total_wages_ppp: 0,
    eligible_ppp_outside_overlap: 0,
    total_wages_erc_covered: 0,
    total_health_cost_each_emp: 0,
    adjust_ppp_caps_max: 0,
    wages_ppp_inside_overlap: 0,
    max_potential_erc: 0,
    calc_wages_beyond_erc_cap: 0,
    adjusted_erc_wages_1: 0,
    potential_add_wages_ppp: 0,
    modify_erc_wage_3: 0,
  },
  {
    id: 2,
    name: "Darcy Hernandez",
    owner: "No",
    wages_used: 2,
    total_wages_ppp: 65000,
    eligible_ppp_outside_overlap: 20000,
    total_wages_erc_covered: 35000,
    total_health_cost_each_emp: 1100,
    adjust_ppp_caps_max: 0,
    wages_ppp_inside_overlap: 0,
    max_potential_erc: 0,
    calc_wages_beyond_erc_cap: 0,
    adjusted_erc_wages_1: 0,
    potential_add_wages_ppp: 0,
    modify_erc_wage_3: 0,
  },
  {
    id: 3,
    name: "Chris Doe",
    owner: "No",
    wages_used: 3,
    total_wages_ppp: 31050,
    eligible_ppp_outside_overlap: 0,
    total_wages_erc_covered: 31050,
    total_health_cost_each_emp: 1100,
    adjust_ppp_caps_max: 0,
    wages_ppp_inside_overlap: 0,
    max_potential_erc: 0,
    calc_wages_beyond_erc_cap: 0,
    adjusted_erc_wages_1: 0,
    potential_add_wages_ppp: 0,
    modify_erc_wage_3: 0,
  },
  {
    id: 4,
    name: "Christina Henderson",
    owner: "No",
    wages_used: 4500,
    total_wages_ppp: 15000,
    eligible_ppp_outside_overlap: 12000,
    total_wages_erc_covered: 9200,
    total_health_cost_each_emp: 1100,
    adjust_ppp_caps_max: 0,
    wages_ppp_inside_overlap: 0,
    max_potential_erc: 0,
    calc_wages_beyond_erc_cap: 0,
    adjusted_erc_wages_1: 0,
    potential_add_wages_ppp: 0,
    modify_erc_wage_3: 0,
  },
  {
    id: 5,
    name: "Billy Bob",
    owner: "Owner",
    wages_used: 0,
    total_wages_ppp: 62320,
    eligible_ppp_outside_overlap: 0,
    total_wages_erc_covered: 62320,
    total_health_cost_each_emp: 1100,
    adjust_ppp_caps_max: 0,
    wages_ppp_inside_overlap: 0,
    max_potential_erc: 0,
    calc_wages_beyond_erc_cap: 0,
    adjusted_erc_wages_1: 0,
    potential_add_wages_ppp: 0,
    modify_erc_wage_3: 0,
  },
  {
    id: 6,
    name: "Raymond Polcari",
    owner: "Family",
    wages_used: 0,
    total_wages_ppp: 13583,
    eligible_ppp_outside_overlap: 10299,
    total_wages_erc_covered: 8546,
    total_health_cost_each_emp: 1100,
    adjust_ppp_caps_max: 0,
    wages_ppp_inside_overlap: 0,
    max_potential_erc: 0,
    calc_wages_beyond_erc_cap: 0,
    adjusted_erc_wages_1: 0,
    potential_add_wages_ppp: 0,
    modify_erc_wage_3: 0,
  },
  {
    id: 7,
    name: "Caroline Buckhardt",
    owner: "No",
    wages_used: 2,
    total_wages_ppp: 9000,
    eligible_ppp_outside_overlap: 7700,
    total_wages_erc_covered: 3250,
    total_health_cost_each_emp: 1100,
    adjust_ppp_caps_max: 0,
    wages_ppp_inside_overlap: 0,
    max_potential_erc: 0,
    calc_wages_beyond_erc_cap: 0,
    adjusted_erc_wages_1: 0,
    potential_add_wages_ppp: 0,
    modify_erc_wage_3: 0,
  },
  {
    id: 8,
    name: "Tommy Bahama",
    owner: "No",
    wages_used: 2,
    total_wages_ppp: 120000,
    eligible_ppp_outside_overlap: 0,
    total_wages_erc_covered: 45000,
    total_health_cost_each_emp: 1100,
    adjust_ppp_caps_max: 0,
    wages_ppp_inside_overlap: 0,
    max_potential_erc: 0,
    calc_wages_beyond_erc_cap: 0,
    adjusted_erc_wages_1: 0,
    potential_add_wages_ppp: 0,
    modify_erc_wage_3: 0,
  },
  {
    id: 9,
    name: "Sissy Adams",
    owner: "No",
    wages_used: 4,
    total_wages_ppp: 13000,
    eligible_ppp_outside_overlap: 9564,
    total_wages_erc_covered: 11250,
    total_health_cost_each_emp: 1100,
    adjust_ppp_caps_max: 0,
    wages_ppp_inside_overlap: 0,
    max_potential_erc: 0,
    calc_wages_beyond_erc_cap: 0,
    adjusted_erc_wages_1: 0,
    potential_add_wages_ppp: 0,
    modify_erc_wage_3: 0,
  },
  {
    id: 10,
    name: "Thomas Anderson",
    owner: "No",
    wages_used: 4,
    total_wages_ppp: 15000,
    eligible_ppp_outside_overlap: 0,
    total_wages_erc_covered: 21568,
    total_health_cost_each_emp: 1100,
    adjust_ppp_caps_max: 0,
    wages_ppp_inside_overlap: 0,
    max_potential_erc: 0,
    calc_wages_beyond_erc_cap: 0,
    adjusted_erc_wages_1: 0,
    potential_add_wages_ppp: 0,
    modify_erc_wage_3: 0,
  },
  {
    id: 11,
    name: "Jackson Miller",
    owner: "No",
    wages_used: 3,
    total_wages_ppp: 11258,
    eligible_ppp_outside_overlap: 10564,
    total_wages_erc_covered: 5778.75,
    total_health_cost_each_emp: 1100,
    adjust_ppp_caps_max: 0,
    wages_ppp_inside_overlap: 0,
    max_potential_erc: 0,
    calc_wages_beyond_erc_cap: 0,
    adjusted_erc_wages_1: 0,
    potential_add_wages_ppp: 0,
    modify_erc_wage_3: 0,
  },
]

export const ERCWageTableContext = React.createContext({})

function CalculateFields(props) {
  const {
    owner,
    wages_used,
    total_wages_ppp,
    eligible_ppp_outside_overlap,
    total_wages_erc_covered,
    total_health_cost_each_emp,
  } = props

  // TODO: Make this dependent on two dates (PPP Start and PPP End)
  const days_between = 168

  // PP Wage Maximization
  const ppp_wage_max = pppWageMaximization({
    owner,
    days_between,
    total_wages_ppp,
    eligible_ppp_outside_overlap,
  })

  // PPP Wage Inside Overlap
  const ppp_wage_inside = pppWageInsideOverlap({
    owner,
    total_wages_ppp,
    adjust_ppp_caps_max: ppp_wage_max,
  })

  // CalculateMaxPotentialERC
  const max_pot_erc = maxPotentialERC({
    owner,
    wages_used,
    total_wages_erc_covered,
  })

  // CalculateWagesBeyondERC
  const beyond_erc = wagesBeyondERC({
    owner,
    wages_used,
    wages_ppp_inside_overlap: ppp_wage_inside,
  })

  // CalculateAAdjustedWagesOne
  const adjusted_one = adjustedWagesOne({
    owner,
    wages_used,
    total_wages_erc_covered,
    calc_wages_beyond_erc_cap: beyond_erc,
  })

  //  CalculatePotentialWagesPPP
  const pot_ppp = potentialWagesPPP({
    total_health_cost_each_emp,
    adjusted_erc_wages_1: adjusted_one,
  })

  // CalculateAdjustedWageThree
  const adjusted_3 = adjustedWagesThree({
    owner,
    wages_used,
    total_health_cost_each_emp,
    adjusted_erc_wages_1: adjusted_one,
    potential_add_wages_ppp: pot_ppp,
  })

  const values = {
    adjust_ppp_caps_max: ppp_wage_max,
    wages_ppp_inside_overlap: ppp_wage_inside,
    max_potential_erc: max_pot_erc,
    calc_wages_beyond_erc_cap: beyond_erc,
    adjusted_erc_wages_1: adjusted_one,
    potential_add_wages_ppp: pot_ppp,
    modify_erc_wage_3: adjusted_3,
  }

  return { ...props, ...values }
}

export default function MaximizePPP() {
  const [rows, setRows] = React.useState(initialState)
  const [context, setContext] = React.useState({ ppp_loan_amount: 400000 })

  const updateRow = (id, event) => {
    let newRows = [...rows]
    const index = rows.findIndex((row) => row.id == id)
    const fieldName = event.target.name
    const fieldValue =
      event.target.name === "name"
        ? event.target.value
        : parseFloat(event.target.value)

    newRows[index][`${fieldName}`] = fieldValue

    const calculatedRows = newRows.map((row) => CalculateFields(row))
    setRows(calculatedRows)
  }

  const calculateAllFields = () => {
    const newRows = rows.map((row) => CalculateFields(row))
    setRows(newRows)
  }

  const updateRowByKey = (id, values) => {
    let newRows = [...rows]
    const index = rows.findIndex((row) => row.id == id)
    newRows[index] = { ...newRows[index], ...values }

    const calculatedRows = newRows.map((row) => CalculateFields(row))
    setRows(calculatedRows)
  }

  const createNewPerson = () => {
    const p = newPerson(rows.length + 1)
    setRows([...rows, p])
  }

  React.useEffect(() => {
    calculateAllFields()
  }, [])

  React.useEffect(() => {
    // console.log(rows)
  }, [rows])

  return (
    <ERCWageTableContext.Provider
      value={[context, setContext, { randomItem: "Hi Benny" }]}
    >
      <h2>PPP Maximization</h2>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="table-warning">Name</th>
            <th className="table-primary">
              Owner or Family Considered from Attribution Rules
              <TooltipMessage message="SEE Related Party" />
            </th>
            <th className="table-primary">
              Total wages used to file for previous ERC credit (up to $10K)
              <TooltipMessage
                message={
                  <p>
                    <a
                      target="_blank"
                      href="https://www.irs.gov/coronavirus/employee-retention-credit"
                    >
                      The Employee Retention Credit (ERC)
                    </a>{" "}
                    is a refundable tax credit for small businesses based on the
                    number of qualifying employees. In this box, enter the total
                    number of{" "}
                    <a
                      target="_blank"
                      href="https://www.irs.gov/newsroom/covid-19-related-employee-retention-credits-determining-qualified-wages-faqs"
                    >
                      qualified wages
                    </a>{" "}
                    previously used toward applying for ERC credit. This will
                    ONLY apply to credits used for the ERC in 2020.
                  </p>
                }
              />
            </th>
            <th className="table-primary">
              Total Wages for PPP Covered Period{" "}
              <TooltipMessage
                message={
                  <p>
                    <a
                      target="_blank"
                      href="https://home.treasury.gov/policy-issues/coronavirus/assistance-for-small-businesses/paycheck-protection-program"
                    >
                      The Payment Protection Plan (PPP){" "}
                    </a>
                    , established by the CARES Act, provides small businesses
                    with funds to pay payroll costs for an 8 - 24 week coverage
                    period. Enter the total number of wages for the 8 - 24 week
                    covered period. This will help determine the overall credit.{" "}
                  </p>
                }
              />
            </th>
            <th className="table-primary">
              Eligible Wages for PPP outside of ERC Overlap{" "}
              <TooltipMessage
                message={
                  <p>
                    Under the{" "}
                    <a
                      target="_blank"
                      href="https://www.congress.gov/bill/116th-congress/house-bill/133/text"
                    >
                      Consolidated Appropriations Act (CAA)
                    </a>
                    , employers who received a loan under the PPP are eligible
                    to apply for the ERC. Employers looking to maximize both
                    programs have a potential for each coverage period to
                    overlap. To establish what consitutes 'outside' of the
                    overlap, you will need to determine each program's coverage
                    period. EXAMPLE: Company A is eligible for ERC in quarter 3
                    of 2020, and their PPP 24 week coverage period starts on
                    July 1. - The ERC coverage period will be from July 1 -
                    September 31. - The PPP coverage period will be from July 1
                    - December 16 - Therefore, 'outside' of the overlap in
                    coverage will be from October 1 - December 16
                  </p>
                }
              />
            </th>
            <th className="table-primary">
              Total Wages for ERC Covered Period{" "}
              <TooltipMessage
                message={
                  <p>
                    Enter the total{" "}
                    <a
                      target="_blank"
                      href="https://www.irs.gov/newsroom/covid-19-related-employee-retention-credits-determining-qualified-wages-faqs"
                    >
                      qualified wages
                    </a>{" "}
                    for the ERC covered period.{" "}
                  </p>
                }
              />
            </th>
            <th className="table-primary">
              Total Health Care Costs for each employee
              <TooltipMessage message="Enter the total health care cost for each employer." />
            </th>
            <th className="table-primary">
              Adjust PPP Wages for Caps and then Maximize all Family and Owner
              outside of ERC Overlap
              <TooltipMessage
                message={
                  <p>
                    "For each individual employee, the total amount of{" "}
                    <a
                      target="_blank"
                      href="https://www.sba.gov/sites/default/files/resource_files/PPP_Forgiveness_as_of_August_3_2020.pdf"
                    >
                      cash compensation eligible{" "}
                    </a>{" "}
                    for forgiveness may not exceed an annual salary of $100,000,
                    as prorated for the Covered Period. This equates to a
                    maximum of $15,385 for the 8 week covered period and $46,154
                    for the 24 week covered period."
                  </p>
                }
              />
            </th>
            <th className="table-primary">
              Eligible Non Family or Owner Wages for PPP Inside of ERC Overlap
              <TooltipMessage
                message={
                  <p>
                    Under the{" "}
                    <a
                      target="_blank"
                      href="https://www.congress.gov/bill/116th-congress/house-bill/133/text"
                    >
                      Consolidated Appropriations Act (CAA)
                    </a>
                    , employers who received a loan under the PPP are eligible
                    to apply for the ERC. Employers looking to maximize both
                    programs have a potential for each coverage period to
                    overlap. To establish what consitutes 'inside' of the
                    overlap, you will need to determine each program's coverage
                    period. EXAMPLE: Company A is eligible for ERC in quarter 3
                    of 2020, and their PPP 24 week coverage period starts on
                    July 1. - The ERC coverage period will be from July 1 -
                    September 31. - The PPP coverage period will be from July 1
                    - December 16 - Therefore, 'outside' of the overlap in
                    coverage will be from October 1 - December 16"
                  </p>
                }
              />
            </th>
            <th className="table-primary">
              Maximum Potential ERC{" "}
              <TooltipMessage
                message="This box is calculated by taking the lowest total of either: 
                - Total Wages for ERC covered period minus the Total wages used to file for previous ERC credit 
                OR 
                - The $10,000 max credit minus Total wages used to file for previous ERC credit"
              />
            </th>
            <th className="table-primary">
              Wages available beyond ERC Quarter Cap{" "}
              <TooltipMessage message="ERC credit can be claimed up to $10,000 per employee each qualifying quarter. " />
            </th>

            <th className="table-primary">
              Adjusted ERC Wages Step 1{" "}
              <TooltipMessage
                message="If NO owner or family are considered for attribution rules, this is calculated by:
- Subtract 'Wages available beyond ERC Credit Cap' and 'Total wages used to file for previous ERC credit' from Total Wages for ERC Covered Period"
              />
            </th>

            <th className="table-primary">
              Potential additional Wages to be added to PPP{" "}
              <TooltipMessage
                message="This is determinded by calculating:
                - If Adjusted ERC Wages and Total health care costs add up to over $10,000, subtract the $10,000 ERC max credit. "
              />
            </th>
            <th className="table-primary">
              Modified ERC Wages Step 3{" "}
              <TooltipMessage
                message="If NO owner or family are considered for attribution rules, this is calculated by:
                - Adding 'Adjusted ERC wages' to 'Total Health Care costs' 
                - Subtract 'potential additional wages to be added to PPP' and 'Total wages used to file for previous ERC credit' from that total"
              />
            </th>
          </tr>
          <tr>
            <th>Totals</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <ERCWageRow
              key={row.id}
              {...row}
              updateMethod={(id, event) => updateRow(id, event)}
              updateRowByKey={updateRowByKey}
            />
          ))}
        </tbody>
      </table>
      <input type="button" value="New Row" onClick={createNewPerson} />
    </ERCWageTableContext.Provider>
  )
}
