import * as React from 'react';
import { Form } from 'react-bootstrap';

export default function QuarterRevInput({ keyName = "", keyValue = "", options = {} }) {
  const values = {
    id: keyName.toUpperCase(),
    name: keyName,
    value: keyValue,
    ...options
  }

  return <Form.Control {...values} />
}