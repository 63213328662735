// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react"
import SimpleERCWageRow from "./MaximizePPP/SimpleERCWageRow"
import Button from "react-bootstrap/Button"
import { Form } from "react-bootstrap"

import ReactTooltip from "react-tooltip"
import { TooltipMessage } from "./Utilities"

import { totalEligibleEarnings } from "./MaximizePPPFunctions/MaxPPPMath"

export interface SimpleERCRowProp {
  id: number
  name: string
  mv_wages: number
  health_premium: number
  total_wages_health: number
  eligible_earnings: number
  erc_calculation: number
}

const newPerson = (newId) => {
  return {
    id: newId,
    name: "",
    mv_wages: 0,
    health_premium: 0,
    total_wages_health: 0,
    eligible_earnings: 0,
    erc_calculation: 0,
  }
}

const initialState: Array<SimpleERCRowProp> = [
  {
    id: 1,
    name: "Frank",
    mv_wages: 0,
    health_premium: 0,
    total_wages_health: 0,
    eligible_earnings: 0,
    erc_calculation: 0,
  },
  {
    id: 2,
    name: "Benny Tam",
    mv_wages: 34132,
    health_premium: 4132,
    total_wages_health: 0,
    eligible_earnings: 0,
    erc_calculation: 0,
  },
  {
    id: 3,
    name: "Ben Tambu",
    mv_wages: 123414,
    health_premium: 1234,
    total_wages_health: 0,
    eligible_earnings: 0,
    erc_calculation: 0,
  },
]

export const SimpleERCWageTableContext = React.createContext({})

function CalculateFields(props) {
  const { mv_wages, health_premium } = props

  // total wages and health calculation
  const total_wages_and_health = mv_wages + health_premium

  // calculates total eligible earnings tab
  // May need fixing here because of :
  const total_eligible_earnings = totalEligibleEarnings({
    total_wages_health: total_wages_and_health,
  })

  // 2020 erc calculation
  const last_erc_calculation = total_eligible_earnings * 0.5

  const values = {
    total_wages_health: total_wages_and_health,
    eligible_earnings: total_eligible_earnings,
    erc_calculation: last_erc_calculation,
  }

  return { ...props, ...values }
}

export default function SimpleMaximizePPP() {
  const [rows, setRows] = React.useState(initialState)
  const [context, setContext] = React.useState({ ppp_loan_amount: 400000 })

  const updateRow = (id, event) => {
    let newRows = [...rows]
    const index = rows.findIndex((row) => row.id == id)
    const fieldName = event.target.name
    const fieldValue =
      event.target.name === "name"
        ? event.target.value
        : parseFloat(event.target.value)

    newRows[index][`${fieldName}`] = fieldValue

    const calculatedRows = newRows.map((row) => CalculateFields(row))
    setRows(calculatedRows)
  }

  const calculateAllFields = () => {
    const newRows = rows.map((row) => CalculateFields(row))
    setRows(newRows)
  }

  const createNewPerson = () => {
    const p = newPerson(rows.length + 1)
    setRows([...rows, p])
  }

  React.useEffect(() => {
    calculateAllFields()
  }, [])

  React.useEffect(() => {
    //console.log(rows)
  }, [rows])

  return (
    <SimpleERCWageTableContext.Provider
      value={[context, setContext, { randomItem: "Hi Frank" }]}
    >
      <h2>Simple PPP Maximization</h2>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="table-warning">Employee Name</th>
            <th className="table-primary">
              Eligible Q4 2020 MV Wages
              <TooltipMessage
                message={
                  <p>
                    <a
                      target="_blank"
                      href="https://www.irs.gov/newsroom/covid-19-related-employee-retention-credits-determining-qualified-wages-faqs"
                    >
                      Eligible wages{" "}
                    </a>{" "}
                    are any wages and compensation you pay or incur for services
                    performed by a qualified employee over the entire quarter."{" "}
                  </p>
                }
              />
            </th>
            <th className="table-primary">
              Health Premium Apportioned
              <TooltipMessage message="'Qualified health plan expenses' are amounts paid or incurred by the Eligible Employer to provide and maintain a group health plan, but only to the extent that those amounts are excluded from the gross income of employees." />
            </th>
            <th className="table-primary">
              Total Wages and Health
              <TooltipMessage message="This box adds the eligible wages and health premium together to determine the total. " />
            </th>
            <th className="table-primary">
              Total Eligible Earnings
              <TooltipMessage
                message={
                  <p>
                    <a
                      target="_blank"
                      href="https://www.irs.gov/coronavirus/employee-retention-credit"
                    >
                      In 2020{" "}
                    </a>
                    , credit can be claimed up to $10,000 per employee.{" "}
                  </p>
                }
              />
            </th>
            <th className="table-primary">
              2020 ERC Calculation
              <TooltipMessage
                message={
                  <p>
                    {" "}
                    <a
                      target="_blank"
                      href="https://www.irs.gov/coronavirus/employee-retention-credit"
                    >
                      In 2020
                    </a>
                    , credit can be claimed for 50% of qualified wages, up to
                    $10,000 per employee paid between March 12 and December 31,
                    2020, for a maximum credit of $5,000 per employee.{" "}
                  </p>
                }
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <SimpleERCWageRow
              key={row.id}
              {...row}
              updateMethod={(id, event) => updateRow(id, event)}
            //updateRowByKey={updateRowByKey}
            />
          ))}
        </tbody>
      </table>
      <input type="button" value="New Row" onClick={createNewPerson} />
    </SimpleERCWageTableContext.Provider>
  )
}
