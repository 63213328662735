// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import { CalculationTwoPointSix } from "../Basic941Functions/Basic941Math";
import { ERCBasic941Context } from "../Basic9412021";

const Basic9412021Part2 = () => {
  const [context, setContext]: any = React.useContext(ERCBasic941Context);

  const initialState: any = { ...context.partTwo };
  const [state, setState] = React.useState(initialState);

  const calculatedInitialState: any = {};
  const [calculated, setCalculated] = React.useState(calculatedInitialState);

  const updateField = (event) => {
    event.preventDefault();
    const targetValue = parseFloat(event.target.value);

    setState((state) => ({
      ...state,
      [event.target.name]: targetValue,
    }));
  };

  React.useEffect(() => {
    const { twoPointOne, twoPointTwo } = state;
    if (
      (twoPointOne && twoPointTwo) ||
      twoPointOne === 0 ||
      twoPointTwo === 0
    ) {
      const calcTwoPointThree: number = twoPointOne + twoPointTwo;

      setCalculated((c) => ({ ...c, calcTwoPointThree }));
    } else {
      setCalculated((c) => ({ ...c, calcTwoPointThree: 0 }));
    }
  }, [state.twoPointOne, state.twoPointTwo]);

  React.useEffect(() => {
    if (calculated.calcTwoPointThree > 0) {
      const calcTwoPointSix: number = CalculationTwoPointSix(
        calculated.calcTwoPointThree
      );
      setCalculated((c) => ({ ...c, calcTwoPointSix }));
    } else {
      setCalculated((c) => ({ ...c, calcTwoPointSix: 0 }));
    }
  }, [calculated.calcTwoPointThree]);

  React.useEffect(() => {
    const { twoPointFour, twoPointFive } = state;
    const { calcTwoPointSix, calcTwoPointThree } = calculated;
    if (
      (twoPointFour && twoPointFive && calcTwoPointSix) ||
      twoPointFour === 0 ||
      twoPointFive === 0 ||
      calcTwoPointSix === 0
    ) {
      const calcTwoPointSeven =
        twoPointFour + twoPointFive + calcTwoPointSix + calcTwoPointThree;
      setCalculated((c) => ({ ...c, calcTwoPointSeven }));
    } else {
      setCalculated((c) => ({ ...c, calcTwoPointSeven: 0 }));
    }
  }, [calculated.calcTwoPointSix, state.twoPointFour, state.twoPointFive]);

  React.useEffect(() => {
    const { twoPointEight, twoPointNine } = state;
    if (
      (twoPointEight && twoPointNine) ||
      twoPointEight === 0 ||
      twoPointNine === 0
    ) {
      const calcTwoPointTen: number = twoPointEight + twoPointNine;

      setCalculated((c) => ({ ...c, calcTwoPointTen }));
    } else {
      setCalculated((c) => ({ ...c, calcTwoPointTen: 0 }));
    }
  }, [state.twoPointEight, state.twoPointNine]);

  React.useEffect(() => {
    if (calculated.calcTwoPointTen > 0) {
      const calcTwoPointThirteen: number = CalculationTwoPointSix(
        calculated.calcTwoPointTen
      );
      setCalculated((c) => ({ ...c, calcTwoPointThirteen }));
    } else {
      setCalculated((c) => ({ ...c, calcTwoPointThirteen: 0 }));
    }
  }, [calculated.calcTwoPointTen]);

  React.useEffect(() => {
    const { twoPointEleven, twoPointTwelve } = state;
    const { calcTwoPointTen, calcTwoPointThirteen } = calculated;
    if (
      (twoPointEleven && twoPointTwelve && calcTwoPointThirteen) ||
      twoPointEleven === 0 ||
      twoPointTwelve === 0 ||
      calcTwoPointThirteen === 0
    ) {
      const calcTwoPointFourteen =
        twoPointEleven +
        twoPointTwelve +
        calcTwoPointTen +
        calcTwoPointThirteen;
      setCalculated((c) => ({ ...c, calcTwoPointFourteen }));
    } else {
      setCalculated((c) => ({ ...c, calcTwoPointFourteen: 0 }));
    }
  }, [
    calculated.calcTwoPointThirteen,
    state.twoPointEleven,
    state.twoPointTwelve,
  ]);

  React.useEffect(() => {
    const { calcTwoPointSeven, calcTwoPointFourteen } = calculated;
    if (
      (calcTwoPointSeven && calcTwoPointFourteen) ||
      calcTwoPointSeven === 0 ||
      calcTwoPointFourteen === 0
    ) {
      const calcTwoPointFifteen = calcTwoPointSeven + calcTwoPointFourteen;
      setCalculated((c) => ({ ...c, calcTwoPointFifteen }));
    } else {
      setCalculated((c) => ({ ...c, calcTwoPointFifteen: 0 }));
    }
  }, [calculated.calcTwoPointFourteen, calculated.calcTwoPointSeven]);

  React.useEffect(() => {
    const one_twelve = context.calculated.partOne.calcOnePointTwelve;
    const two_fourteen = calculated.calcTwoPointFourteen;
    const calcTwoPointSixteen =
      one_twelve < two_fourteen ? one_twelve : two_fourteen;
    setCalculated((c) => ({ ...c, calcTwoPointSixteen }));
  }, [
    context.calculated.partOne.calcOnePointTwelve,
    calculated.calcTwoPointFourteen,
  ]);

  React.useEffect(() => {
    const { calcTwoPointFifteen, calcTwoPointSixteen } = calculated;
    if (
      (calcTwoPointFifteen && calcTwoPointSixteen) ||
      calcTwoPointFifteen === 0 ||
      calcTwoPointSixteen === 0
    ) {
      const calcTwoPointSeventeen = calcTwoPointFifteen - calcTwoPointSixteen;
      console.log(calcTwoPointSeventeen);
      setCalculated((c) => ({ ...c, calcTwoPointSeventeen }));
    } else {
      setCalculated((c) => ({ ...c, calcTwoPointSeventeen: 0 }));
    }
  }, [calculated.calcTwoPointFifteen, calculated.calcTwoPointSixteen]);

  // For Debug
  React.useEffect(() => {
    setContext({ ...context, partTwo: state });
  }, [state]);

  React.useEffect(() => {
    setContext({
      ...context,
      calculated: { ...context.calculated, partTwo: calculated },
    });
  }, [calculated]);

  return (
    <Form>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th className="table-primary">Part 2</th>
            <th className="table-primary">
              Determine Sick and Family Leave Credits
            </th>
            <th className="table-primary">Input Your Data</th>
          </tr>
          <tr>
            <th className="table-primary"> 2.01</th>
            <th className="table-warning">
              Qualified Sick Leave Wages from Form 941 (line 5a(i), column 1)
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoPointOne"
                value={state.twoPointOne}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 2.02</th>
            <th className="table-warning">
              Qualified Sick Leave Wages not included on previous entry but
              included in the Form 941 (line 5c). This is a portion of total
              wages that were Sick Leave Wages, but not accounted for above.
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoPointTwo"
                value={state.twoPointTwo}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 2.03</th>
            <th className="table-warning">Total Qualified Sick Leave Wages</th>
            <td className="table-active">{calculated.calcTwoPointThree}</td>
          </tr>
          <tr>
            <th className="table-primary"> 2.04</th>
            <th className="table-warning">
              Qualified Sick Leave Excluded from 3121(b) Employment Link:
              http://tiny.cc/ck3iuz
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoPointFour"
                value={state.twoPointFour}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 2.05</th>
            <th className="table-warning">
              Qualified Health Plan Expenses allocable to Qualified Sick Leave
              Form 941 (line 19)
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoPointFive"
                value={state.twoPointFive}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary">2.06</th>
            <th className="table-warning">
              Employer Share of Medicare Tax on Qualified Sick Leave
            </th>
            <td className="table-active">{calculated.calcTwoPointSix}</td>
          </tr>
          <tr>
            <th className="table-primary">2.07</th>
            <th className="table-warning">
              Credit for Qualified Sick Leave Wages
            </th>
            <td className="table-active">{calculated.calcTwoPointSeven}</td>
          </tr>

          <tr>
            <th className="table-primary"> 2.08</th>
            <th className="table-warning">
              Qualified Family Leave Wages Reported from Form 941 (line 5a(ii),
              column 1)
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoPointEight"
                value={state.twoPointEight}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 2.09</th>
            <th className="table-warning">
              Qualified Family Leave Wages not included on previous entry but
              included in the Form 941 (line 5c). This is a portion of total
              wages that were Family Leave, but not accounted for above.
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoPointNine"
                value={state.twoPointNine}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 2.10</th>
            <th className="table-warning">
              Total Qualified Family Leave Wages
            </th>
            <td className="table-active">{calculated.calcTwoPointTen}</td>
          </tr>
          <tr>
            <th className="table-primary"> 2.11</th>
            <th className="table-warning">
              Qualified Family Leave Excluded from 3121(b) Employment Link:
              http://tiny.cc/ck3iuz
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoPointEleven"
                value={state.twoPointEleven}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 2.12</th>
            <th className="table-warning">
              Qualified Health Plan Expenses allocable to Qualified Family Leave
              Wages from Form 941 (line 20)
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoPointTwelve"
                value={state.twoPointTwelve}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 2.13</th>
            <th className="table-warning">
              Employer Share of Medicare Tax on Qualified Family Leave Wages
            </th>
            <td className="table-active">{calculated.calcTwoPointThirteen}</td>
          </tr>
          <tr>
            <th className="table-primary"> 2.14</th>
            <th className="table-warning">
              Credit for Qualified Family Leave Wages
            </th>
            <td className="table-active">{calculated.calcTwoPointFourteen}</td>
          </tr>
          <tr>
            <th className="table-primary"> 2.15</th>
            <th className="table-warning">
              Credit for Qualifed Sick and Family Leave Wages
            </th>
            <td className="table-active">{calculated.calcTwoPointFifteen}</td>
          </tr>
          <tr>
            <th className="table-primary"> 2.16</th>
            <th className="table-warning">
              Nonrefundable Portion of The Sick and Family Leave Credit
            </th>
            <td className="table-active">{calculated.calcTwoPointSixteen}</td>
          </tr>
          <tr>
            <th className="table-primary"> 2.17</th>
            <th className="table-warning">
              Refundable Portion of The Sick and Family Leave Credit
            </th>
            <td className="table-active">{calculated.calcTwoPointSeventeen}</td>
          </tr>
        </tbody>
      </table>
    </Form>
  );
};

export default Basic9412021Part2;
