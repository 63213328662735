import * as React from 'react';

export default function TaskForm941x({
  refund_amount, form_941x, path, quarter, refund_type, activated
}: { refund_amount: number, form_941x: any, path: string, quarter: string, refund_type: string, activated: boolean }) {
  // {/* Add comma to thousands place refund amount */}
  const refund_amount_comma = refund_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Make sure that cents always has a 0 in tenths place
  const refund_amount_cents = refund_amount_comma.split(".");
  if (refund_amount_cents[1] === undefined) {
    refund_amount_cents[1] = "00";
  } else if (refund_amount_cents[1].length === 1) {
    refund_amount_cents[1] = refund_amount_cents[1] + "0";
  }
  const refund_amount_comma_cents = refund_amount_cents.join(".");

  return (
    <div className="card mb-3 hover-shadow-lg">
      <div className="card-body d-flex align-items-center flex-wrap flex-lg-nowrap py-0">
        <div className="col-lg-2  pt-3 pt-lg-0">
          <span className="h6 text-md task-name">
            Revise 941x for {quarter}
          </span></div>
        <div className="col col-lg-1 text-right px-0 order-lg-4 pt-3 pt-lg-0">
          <span className="text-muted text-sm">
          </span>
        </div>
        <div className="col-12 col-lg-8 d-flex align-items-center position-static pb-3 py-lg-4 px-0">
          <div className="col col-lg-11 position-static px-0">
            <div className="d-flex flex-wrap flex-lg-nowrap align-items-center">
              <div className="col-12 col-lg pl-0 text-limit text-sm text-muted d-none d-sm-block pl-lg-2 ms-3">
                {/* Refund Amount if refund_amount is not nil */}
                {refund_amount &&
                  // Green Text
                  <strong className="text-success">
                    <span className="text-md">
                      {refund_type == 'precalculated' && "Estimated "}
                      Refund Amount: ${refund_amount_comma_cents}
                    </span>
                  </strong>
                }
                {!refund_amount || refund_type == 'precalculated' &&
                  <strong className="text-danger"><br />
                    <span className="text-md">
                      Finalize your filing for this quarter for an exact calculation.
                    </span>
                  </strong>
                }
              </div>
            </div>
            <div className="pull-right pt-1 ps-4">

            </div>
          </div>
        </div>
        {/* Show Edit button if refund_amount isnt nil */}
        {refund_amount &&
          <a href={path} className="btn btn-sm continue-btn btn-block col-2">
            {!activated && 'Checkout and '}
            {refund_type == 'precalculated' && 'Begin Form'}
            {refund_type != 'precalculated' && 'Edit Form'}
          </a>
        }
        {/* Show Continue button if refund_amount is nil */}
        {!refund_amount &&
          <a href={path} className="btn btn-sm continue-btn btn-block col-2 ">
            Start Form
          </a>
        }
      </div>
    </div>

  )
}