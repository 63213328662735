// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from 'react'

function maximizeApi(id, setComplete, setLoadingState) {
  setLoadingState(true)
  fetch(`/ertc_filings/${`${id}`}/maximize_filing`, {
    method: 'GET',
  }).then(response => {
    const json = response.json()
    console.log("Response", json)
    setComplete(true)
    setLoadingState(false)
  })
}

// Get Request to '/maximize_filing/${`${id}`}'
export default function Maximize({ id }) {
  const [complete, setComplete] = React.useState(false)
  const [loading, setLoadingState] = React.useState(false)



  return (
    <div class="all-font">
      <h3>Maximizing Filing</h3>
      {complete && (
        <a href={`/ertc_filings/${id}`} className="btn next-btn">
          Continue
        </a>
      )}
      {loading ? <div class="d-flex justify-content-center pb-3">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div> : ""}
      {!complete && (
        <button className="btn add-sus" onClick={() => maximizeApi(id, setComplete, setLoadingState)}>
          Maximize Filing
        </button>
      )}
    </div>
  )
}
