// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react"
import Button from "react-bootstrap/Button"
import { Form } from "react-bootstrap"
import { CalculationTwoPointSix } from "../Basic941Functions/Basic941Math"
import { ERCBasic941Context2020 } from "../Basic9412020"

const Basic9412020Part2 = () => {
  const [context, setContext]: any = React.useContext(ERCBasic941Context2020)

  const initialState: any = { ...context.partTwo }
  const [state, setState] = React.useState(initialState)

  const calculatedInitialState: any = {}
  const [calculated, setCalculated] = React.useState(calculatedInitialState)

  const updateField = (event) => {
    event.preventDefault()
    const targetValue = parseFloat(event.target.value)

    setState((state) => ({
      ...state,
      [event.target.name]: targetValue,
    }))
  }

  React.useEffect(() => {
    const { twoPointOne, twoPointTwo } = state
    if (
      (twoPointOne && twoPointTwo) ||
      twoPointOne === 0 ||
      twoPointTwo === 0
    ) {
      const calcTwoPointThree: number = twoPointOne + twoPointTwo

      setCalculated((c) => ({ ...c, calcTwoPointThree }))
    } else {
      setCalculated((c) => ({ ...c, calcTwoPointThree: 0 }))
    }
  }, [state.twoPointOne, state.twoPointTwo])

  React.useEffect(() => {
    if (calculated.calcTwoPointThree > 0) {
      const calcTwoPointFive: number = CalculationTwoPointSix(
        calculated.calcTwoPointThree
      )
      setCalculated((c) => ({ ...c, calcTwoPointFive }))
    } else {
      setCalculated((c) => ({ ...c, calcTwoPointFive: 0 }))
    }
  }, [calculated.calcTwoPointThree])

  React.useEffect(() => {
    const { twoPointFour } = state
    const { calcTwoPointFive, calcTwoPointThree } = calculated
    if (
      (twoPointFour && calcTwoPointFive) ||
      twoPointFour === 0 ||
      calcTwoPointFive === 0
    ) {
      const calcTwoPointSix =
        twoPointFour + calcTwoPointFive + calcTwoPointThree
      setCalculated((c) => ({ ...c, calcTwoPointSix }))
    } else {
      setCalculated((c) => ({ ...c, calcTwoPointSix: 0 }))
    }
  }, [calculated.calcTwoPointFive, state.twoPointFour])

  React.useEffect(() => {
    const { twoPointSeven, twoPointEight } = state
    if (
      (twoPointSeven && twoPointEight) ||
      twoPointSeven === 0 ||
      twoPointEight === 0
    ) {
      const calcTwoPointNine: number = twoPointSeven + twoPointEight

      setCalculated((c) => ({ ...c, calcTwoPointNine }))
    } else {
      setCalculated((c) => ({ ...c, calcTwoPointNine: 0 }))
    }
  }, [state.twoPointSeven, state.twoPointEight])

  React.useEffect(() => {
    if (calculated.calcTwoPointNine > 0) {
      const calcTwoPointEleven: number = CalculationTwoPointSix(
        calculated.calcTwoPointNine
      )
      setCalculated((c) => ({ ...c, calcTwoPointEleven }))
    } else {
      setCalculated((c) => ({ ...c, calcTwoPointEleven: 0 }))
    }
  }, [calculated.calcTwoPointNine])

  React.useEffect(() => {
    const { twoPointTen } = state
    const { calcTwoPointNine, calcTwoPointEleven } = calculated
    if (
      (twoPointTen && calcTwoPointEleven) ||
      twoPointTen === 0 ||
      calcTwoPointEleven === 0
    ) {
      const calcTwoPointTwelve =
        twoPointTen + calcTwoPointNine + calcTwoPointEleven
      setCalculated((c) => ({ ...c, calcTwoPointTwelve }))
    } else {
      setCalculated((c) => ({ ...c, calcTwoPointTwelve: 0 }))
    }
  }, [calculated.calcTwoPointEleven, state.twoPointTen])

  React.useEffect(() => {
    const { calcTwoPointSix, calcTwoPointTwelve } = calculated
    if (
      (calcTwoPointSix && calcTwoPointTwelve) ||
      calcTwoPointSix === 0 ||
      calcTwoPointTwelve === 0
    ) {
      const calcTwoPointThirteen = calcTwoPointSix + calcTwoPointTwelve
      setCalculated((c) => ({ ...c, calcTwoPointThirteen }))
    } else {
      setCalculated((c) => ({ ...c, calcTwoPointThirteen: 0 }))
    }
  }, [calculated.calcTwoPointTwelve, calculated.calcTwoPointSix])

  React.useEffect(() => {
    const one_eleven = context.calculated.partOne.calcOnePointEleven
    const two_fourteen = calculated.calcTwoPointTwelve
    const calcTwoPointFourteen =
      one_eleven < two_fourteen ? one_eleven : two_fourteen
    setCalculated((c) => ({ ...c, calcTwoPointFourteen }))
  }, [
    context.calculated.partOne.calcOnePointEleven,
    calculated.calcTwoPointTwelve,
  ])

  React.useEffect(() => {
    const { calcTwoPointThirteen, calcTwoPointFourteen } = calculated
    if (
      (calcTwoPointThirteen && calcTwoPointFourteen) ||
      calcTwoPointThirteen === 0 ||
      calcTwoPointFourteen === 0
    ) {
      const calcTwoPointFifteen = calcTwoPointThirteen - calcTwoPointFourteen
      console.log(calcTwoPointFifteen)
      setCalculated((c) => ({ ...c, calcTwoPointFifteen }))
    } else {
      setCalculated((c) => ({ ...c, calcTwoPointFifteen: 0 }))
    }
  }, [calculated.calcTwoPointThirteen, calculated.calcTwoPointFourteen])

  // For Debug
  React.useEffect(() => {
    setContext({ ...context, partTwo: state })
  }, [state])

  React.useEffect(() => {
    setContext({
      ...context,
      calculated: { ...context.calculated, partTwo: calculated },
    })
  }, [calculated])

  return (
    <Form>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th className="table-primary">Part 2</th>
            <th className="table-primary">
              Determine Sick and Family Leave Credits
            </th>
            <th className="table-primary">Input Your Data</th>
          </tr>
          <tr>
            <th className="table-primary"> 2.01</th>
            <th className="table-warning">
              Qualified Sick Leave Wages from Form 941 (line 5a(i), column 1)
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoPointOne"
                value={state.twoPointOne}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 2.02</th>
            <th className="table-warning">
              Qualified Sick Leave Wages not included on previous entry but
              included in the Form 941 (line 5c). This is a portion of total
              wages that were Sick Leave Wages, but not accounted for above.
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoPointTwo"
                value={state.twoPointTwo}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 2.03</th>
            <th className="table-warning">Total Qualified Sick Leave Wages</th>
            <td className="table-active">{calculated.calcTwoPointThree}</td>
          </tr>
          <tr>
            <th className="table-primary"> 2.04</th>
            <th className="table-warning">
              Qualified Health Plan Expenses allocable to Qualified Sick Leave
              Form 941 (line 19)
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoPointFour"
                value={state.twoPointFour}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary">2.05</th>
            <th className="table-warning">
              Employer Share of Medicare Tax on Qualified Sick Leave
            </th>
            <td className="table-active">{calculated.calcTwoPointFive}</td>
          </tr>
          <tr>
            <th className="table-primary">2.06</th>
            <th className="table-warning">
              Credit for Qualified Sick Leave Wages
            </th>
            <td className="table-active">{calculated.calcTwoPointSix}</td>
          </tr>

          <tr>
            <th className="table-primary"> 2.07</th>
            <th className="table-warning">
              Qualified Family Leave Wages Reported from Form 941 (line 5a(ii),
              column 1)
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoPointSeven"
                value={state.twoPointSeven}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 2.08</th>
            <th className="table-warning">
              Qualified Family Leave Wages not included on previous entry but
              included in the Form 941 (line 5c). This is a portion of total
              wages that were Family Leave, but not accounted for above.
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoPointEight"
                value={state.twoPointEight}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 2.09</th>
            <th className="table-warning">
              Total Qualified Family Leave Wages
            </th>
            <td className="table-active">{calculated.calcTwoPointNine}</td>
          </tr>

          <tr>
            <th className="table-primary"> 2.10</th>
            <th className="table-warning">
              Qualified Health Plan Expenses allocable to Qualified Family Leave
              Wages from Form 941 (line 20)
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="twoPointTen"
                value={state.twoPointTen}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 2.11</th>
            <th className="table-warning">
              Employer Share of Medicare Tax on Qualified Family Leave Wages
            </th>
            <td className="table-active">{calculated.calcTwoPointEleven}</td>
          </tr>
          <tr>
            <th className="table-primary"> 2.12</th>
            <th className="table-warning">
              Credit for Qualified Family Leave Wages
            </th>
            <td className="table-active">{calculated.calcTwoPointTwelve}</td>
          </tr>
          <tr>
            <th className="table-primary"> 2.13</th>
            <th className="table-warning">
              Credit for Qualifed Sick and Family Leave Wages
            </th>
            <td className="table-active">{calculated.calcTwoPointThirteen}</td>
          </tr>
          <tr>
            <th className="table-primary"> 2.14</th>
            <th className="table-warning">
              Nonrefundable Portion of The Sick and Family Leave Credit
            </th>
            <td className="table-active">{calculated.calcTwoPointFourteen}</td>
          </tr>
          <tr>
            <th className="table-primary"> 2.15</th>
            <th className="table-warning">
              Refundable Portion of The Sick and Family Leave Credit
            </th>
            <td className="table-active">{calculated.calcTwoPointFifteen}</td>
          </tr>
        </tbody>
      </table>
    </Form>
  )
}

export default Basic9412020Part2
