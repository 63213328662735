import * as React from 'react'
import Button from "react-bootstrap/Button"


export default function SuspensionTable({ dateRangeArray, deleteDateRange }) {
  console.log("Date Range Array", dateRangeArray)
  return (
    <div className="SuspensionTable d-flex justify-content-center my-5">
      <table className=" w-50 table table-bordered ">
        <tbody>
          <tr>
            <th className="border-right tbl-border gr-table-head">Id</th>
            <th className="border-right tbl-border gr-table-head">Suspension Type</th>
            <th className="border-right tbl-border gr-table-head">Start Date</th>
            <th className="border-right tbl-border gr-table-head">End Date</th>
            <th className="border-right tbl-border gr-table-head">Suspension Reason</th>
          </tr>
          {dateRangeArray.map((suspension, index) => (
            <tr>
              <td className="">{suspension.id}</td>
              <td>{suspension.suspension_type}</td>
              <td>{suspension.date_range_start.toLocaleDateString("en-US", { timeZone: 'UTC' })}</td>
              <td>{suspension.date_range_end.toLocaleDateString("en-US", { timeZone: 'UTC' })}</td>
              <td>{suspension.suspension_reason !== "" ? suspension.suspension_reason : ""}</td>
              <td>
                <Button
                  type="Submit"
                  id="deleteDateRange"
                  className="delete-sus"
                  onClick={(event) => deleteDateRange(event, suspension.id)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}