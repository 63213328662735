import React from 'react'
import { defaultHeaders } from '../APIFunctions'

const batch_size = 500
const wage_import_api = '/wage_records/import'
const employee_import_api = '/employees/import'

export default function SubmitCsv({
  form_authenticity_token,
  ertc_filing_id,
  records,
  employees,
  columnHeaders,
  setComplete,
  complete
}) {
  // Create batches of batch_size records
  const wage_records = processRecords({ headers: columnHeaders, records })

  const [loading, setLoading] = React.useState(false)

  // Remove any with empty name or pay_date from wage_records
  const wage_records_filtered = wage_records.filter(record =>
    (record.name != undefined && record.pay_date != undefined)
  )

  const batches = []
  for (let i = 0; i < wage_records_filtered.length; i += batch_size) {
    batches.push(wage_records_filtered.slice(i, i + batch_size))
  }

  const submitData = () => {
    setLoading(true)
    submitBatches(batches, form_authenticity_token, ertc_filing_id).then(() => {
      setLoading(false)
      console.log('Wages submitted!')
      setComplete(true)
    })
  }

  return (
    <div className="text-center py-3">
      {!complete && (
        <>
          <h3>Importing {`${records.length}`} Entries</h3>
          <button className="btn add-sus"onClick={submitData}>Upload Wage Data</button>
        </>
      )}
      {loading?<div class="d-flex justify-content-center py-3">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>:""}
    </div>
  )
}

function submitBatches(batches, form_authenticity_token, ertc_filing_id) {
  // Create an api request for each batch and chain the requests

  const requests = batches.map(batch => {
    return fetch(wage_import_api, {
      method: 'POST',
      headers: defaultHeaders(form_authenticity_token),
      body: JSON.stringify({ ertc_filing_id, wage_records: batch }),
    })
  })

  return Promise.all(requests)
}

// Process the CSV file and return the headers and records
function processRecords({ headers, records }) {
  const output_array = records.map(record => {
    const output_hash = {}
    headers.forEach(header => {
      const processFunction = header.process ? header.process : (e => e)

      if (record.data[header.index] == undefined || record.data[header.index] == "") {
        return
      }
      if (header.field == "name" || header.field == "pay_date") {
        // Return if record.data[header.index] is undefined or blank
        output_hash[header.field] = processFunction(record.data[header.index])

      } else {
        // Check if record.data[header.index] is a number
        if (record.data[header.index]) {
          // Remove $ and , from the number and convert to double
          output_hash[header.field] = parseFloat(record.data[header.index].replace(/[$,]/g, ''))
        } else {
          // Set number to 0 if it is not a number
          output_hash[header.field] = 0
        }
      }
    })
    return output_hash
  })

  return output_array
}