// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react"
import Button from "react-bootstrap/Button"
import { Form } from "react-bootstrap"
import { CalculationThreePointSeven } from "../Basic941Functions/Basic941Math"
import { ERCBasic941Context2020 } from "../Basic9412020"

const Basic9412020Part3 = () => {
  const [context, setContext]: any = React.useContext(ERCBasic941Context2020)

  const initialState: any = { ...context.partThree }
  const [state, setState] = React.useState(initialState)

  const calculatedInitialState: any = {}
  const [calculated, setCalculated] = React.useState(calculatedInitialState)

  const [selectedRadioBtn, setSelectedRadioBtn] = React.useState("")

  const isRadioSelected = (value: string): boolean => selectedRadioBtn === value

  const handleRadioClick = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedRadioBtn(e.currentTarget.value)
  }

  const updateField = (event) => {
    event.preventDefault()
    const targetValue = parseFloat(event.target.value)

    setState((state) => ({
      ...state,
      [event.target.name]: targetValue,
    }))
  }

  React.useEffect(() => {
    const { threePointOne, threePointTwo, threePointFour, threePointFive } =
      state
    if (
      (threePointOne && threePointTwo && threePointFour && threePointFive) ||
      threePointOne === 0 ||
      threePointTwo === 0 ||
      threePointFour === 0 ||
      threePointFive === 0
    ) {
      const calcThreePointSix =
        threePointOne + threePointTwo + threePointFour + threePointFive
      setCalculated((c) => ({ ...c, calcThreePointSix }))
    } else {
      setCalculated((c) => ({ ...c, calcThreePointSix: 0 }))
    }
  }, [state.threePointOne, state.threePointTwo])

  React.useEffect(() => {
    const { calcThreePointSix } = calculated
    if (calcThreePointSix) {
      const calcThreePointSeven = CalculationThreePointSeven(calcThreePointSix)
      setCalculated((c) => ({ ...c, calcThreePointSeven }))
    } else {
      setCalculated((c) => ({ ...c, calcThreePointSeven: 0 }))
    }
  }, [calculated.calcThreePointSix])

  React.useEffect(() => {
    const calcThreePointEight = context.calculated.partOne.calcOnePointEleven
    if (calcThreePointEight) {
      setCalculated((c) => ({ ...c, calcThreePointEight }))
    } else {
      setCalculated((c) => ({ ...c, calcThreePointEight: 0 }))
    }
  }, [context.calculated.partOne.calcOnePointEleven])

  React.useEffect(() => {
    const calcThreePointNine = context.calculated.partTwo.calcTwoPointFourteen
    if (calcThreePointNine) {
      setCalculated((c) => ({ ...c, calcThreePointNine }))
    } else {
      setCalculated((c) => ({ ...c, calcThreePointNine: 0 }))
    }
  }, [context.calculated.partTwo.calcTwoPointFourteen])

  React.useEffect(() => {
    const { calcThreePointEight, calcThreePointNine } = calculated
    if (
      calcThreePointEight >= calcThreePointNine ||
      calcThreePointNine === 0 ||
      calcThreePointEight === 0
    ) {
      const calcThreePointTen =
        calcThreePointEight >= calcThreePointNine
          ? calcThreePointEight - calcThreePointNine
          : calcThreePointNine - calcThreePointEight

      setCalculated((c) => ({ ...c, calcThreePointTen }))
    } else {
      setCalculated((c) => ({ ...c, calcThreePointTen: 0 }))
    }
  }, [calculated.calcThreePointEight, calculated.calcThreePointNine])

  React.useEffect(() => {
    const { calcThreePointSeven, calcThreePointTen } = calculated
    if (
      (calcThreePointSeven && calcThreePointTen) ||
      calcThreePointSeven === 0 ||
      calcThreePointTen === 0
    ) {
      const calcThreePointTwelve =
        calcThreePointSeven < calcThreePointTen
          ? calcThreePointSeven
          : calcThreePointTen
      setCalculated((c) => ({ ...c, calcThreePointTwelve }))
    } else {
      setCalculated((c) => ({ ...c, calcThreePointTwelve: 0 }))
    }
  }, [calculated.calcThreePointSeven, calculated.calcThreePointTen])

  React.useEffect(() => {
    const { calcThreePointNine, calcThreePointTwelve } = calculated
    if (
      (calcThreePointNine && calcThreePointTwelve) ||
      calcThreePointNine === 0 ||
      calcThreePointTwelve === 0
    ) {
      const calcThreePointEleven = calcThreePointNine + calcThreePointTwelve

      setCalculated((c) => ({ ...c, calcThreePointEleven }))
    } else {
      setCalculated((c) => ({ ...c, calcThreePointEleven: 0 }))
    }
  }, [calculated.calcThreePointNine, calculated.calcThreePointTwelve])

  React.useEffect(() => {
    const { calcThreePointSeven, calcThreePointTwelve } = calculated
    if (
      (calcThreePointSeven && calcThreePointTwelve) ||
      calcThreePointSeven === 0 ||
      calcThreePointTwelve === 0
    ) {
      const calcThreePointThirteen =
        calcThreePointSeven >= calcThreePointTwelve
          ? calcThreePointSeven - calcThreePointTwelve
          : calcThreePointTwelve - calcThreePointSeven

      setCalculated((c) => ({ ...c, calcThreePointThirteen }))
    } else {
      setCalculated((c) => ({ ...c, calcThreePointThirteen: 0 }))
    }
  }, [calculated.calcThreePointSeven, calculated.calcThreePointTwelve])
  // For Debug
  React.useEffect(() => {
    console.log(selectedRadioBtn)
  }, [selectedRadioBtn])

  React.useEffect(() => {
    setContext({ ...context, partThree: state })
  }, [state])

  React.useEffect(() => {
    setContext({
      ...context,
      calculated: { ...context.calculated, partThree: calculated },
    })
  }, [calculated])

  return (
    <Form>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th className="table-primary">Part 3</th>
            <th className="table-primary">Employee Retention Credit</th>
            <th className="table-primary">Input Your Data</th>
          </tr>
          <tr>
            <th className="table-primary"> 3.01</th>
            <th className="table-warning">
              Qualified Wages for the Quarter (Excluding Health Plan Expenses) -
              Must be Calculated Separately
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="threePointOne"
                value={state.threePointOne}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 3.02</th>
            <th className="table-warning">
              Qualified Health Expenses for the Quarter Allocate to Wages - Must
              be Calculated Separately
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="threePointTwo"
                value={state.threePointTwo}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 3.03</th>
            <th className="table-warning">
              Which 2020 Quarter are you applying for the Credit?
            </th>
            <td className="table-active">
              <Form.Group>
                <Form.Check
                  value="Q2 2020"
                  type="radio"
                  label="Q2 2020"
                  name="quarter_2020"
                  checked={isRadioSelected("Q2 2020")}
                  onChange={handleRadioClick}
                />
                <Form.Check
                  value="Q3 2020"
                  type="radio"
                  label="Q3 2020"
                  name="quarter_2020"
                  checked={isRadioSelected("Q3 2020")}
                  onChange={handleRadioClick}
                />
                <Form.Check
                  value="Q4 2020"
                  type="radio"
                  label="Q4 2020"
                  name="quarter_2020"
                  checked={isRadioSelected("Q4 2020")}
                  onChange={handleRadioClick}
                />
              </Form.Group>
            </td>
          </tr>
          <tr>
            <th className="table-primary">3.04</th>
            <th className="table-warning">
              Qualified Wages (Excluding Health Plan Expenses) for the period
              March 13th 2020 to March 31st 2020 - Must be Calculated Separately
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="threePointFour"
                value={state.threePointFour}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary">3.05</th>
            <th className="table-warning">
              Qualified Health Expenses Allocated to Wages for the period March
              13th 2020 to March 31st 2020
            </th>
            <td className="table-active">
              <Form.Control
                type="number"
                placeholder="$"
                name="threePointFive"
                value={state.threePointFive}
                onChange={(e) => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="table-primary"> 3.06</th>
            <th className="table-warning">Total of Calculations</th>
            <td className="table-active">{calculated.calcThreePointSix}</td>
          </tr>
          <tr>
            <th className="table-primary"> 3.07</th>
            <th className="table-warning">
              Retention Credit Calculation (50%)
            </th>
            <td className="table-active">{calculated.calcThreePointSeven}</td>
          </tr>
          <tr>
            <th className="table-primary"> 3.08</th>
            <th className="table-warning">Employer Social Security Tax</th>
            <td className="table-active">{calculated.calcThreePointEight}</td>
          </tr>
          <tr>
            <th className="table-primary"> 3.09</th>
            <th className="table-warning">
              Nonrefundable Credit for Sick and Family Leave
            </th>
            <td className="table-active">{calculated.calcThreePointNine}</td>
          </tr>
          <tr>
            <th className="table-primary"> 3.10</th>
            <th className="table-warning">Reduction in Calculation</th>
            <td className="table-active">{calculated.calcThreePointTen}</td>
          </tr>
          <tr>
            <th className="table-primary"> 3.11</th>
            <th className="table-warning">
              Total Nonrefundable Amount on Line 23
            </th>
            <td className="table-active">{calculated.calcThreePointEleven}</td>
          </tr>
          <tr>
            <th className="table-primary"> 3.12</th>
            <th className="table-warning">
              Nonrefundable Portion of Employee Retention Credit
            </th>
            <td className="table-active">{calculated.calcThreePointTwelve}</td>
          </tr>
          <tr>
            <th className="table-primary"> 3.13</th>
            <th className="table-warning">
              Refundable Portion of Employee Retention Credit
            </th>
            <td className="table-active">
              {calculated.calcThreePointThirteen}
            </td>
          </tr>
        </tbody>
      </table>
    </Form>
  )
}

export default Basic9412020Part3
