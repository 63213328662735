import * as React from "react"

function Hello({ greeting }) {
  return (
    <React.Fragment>
      Greeting: {greeting}
    </React.Fragment>
  );
}

export default Hello