// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react"
import { Form, Dropdown } from "react-bootstrap"
import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"

export const initialState = (questions, ppp_loan) => {
  const defaultValues = {
    ppp_start: new Date(),
    weeks: "24",
    ppp_end: new Date(),
    ppp_loan_amt: "",
    apply_forgive: "",
    rece_forgive: "",
    other_wages_amt: "",
    other_wages: "",
    non_wages_amt: "",
    list_wages: "",
    ppp_loan_num: "",
    non_wages_max: "",
    wages_for_ppp: "",
  }

  var newPPPLoan = { ...defaultValues }

  if (ppp_loan) {
    try {
      newPPPLoan = {
        ...ppp_loan,
        ppp_start: new Date(ppp_loan.ppp_start),
        ppp_end: new Date(ppp_loan.ppp_end),
      }
    } catch {
      newPPPLoan = { ...defaultValues }
    }
  }

  return { ...defaultValues, ...questions, ...newPPPLoan }
}

export default function QuestionPPP({
  questions = {},
  ppp_loan = {},
  setPPPLoansState,
  index,
}) {
  const [state, setState] = React.useState(initialState(questions, ppp_loan))

  const updateFieldDropdown = (event, key) => {
    event.preventDefault()
    const targetValue = event.target.textContent
    setState(state => ({ ...state, [key]: targetValue }))
  }

  const updateField = event => {
    event.preventDefault()
    const targetValue = parseFloat(event.target.value)
    setState(state => ({ ...state, [event.target.name]: targetValue }))
  }

  React.useEffect(() => {
    const { ppp_loan_amt, non_wages_amt } = state

    if ((ppp_loan_amt && non_wages_amt) || ppp_loan_amt === 0 || non_wages_amt === 0) {
      if (non_wages_amt >= ppp_loan_amt * 0.4) {
        const non_wages_maximum = ppp_loan_amt * 0.4
        setState(c => ({ ...c, non_wages_max: non_wages_maximum }))
      } else {
        const non_wages_maximum = non_wages_amt
        setState(c => ({ ...c, non_wages_max: non_wages_maximum }))
      }
    } else {
      setState(c => ({ ...c, non_wages_max: 0 }))
    }
  }, [state.ppp_loan_amt, state.non_wages_amt])

  React.useEffect(() => {
    const { ppp_loan_amt, non_wages_max, other_wages_amt } = state

    const pppForgiveness =
      (ppp_loan_amt || 0) - (non_wages_max || 0) - (other_wages_amt || 0)
    if (pppForgiveness >= 0) {
      setState(c => ({ ...c, wages_for_ppp: pppForgiveness }))
    } else {
      setState(c => ({ ...c, wages_for_ppp: 0 }))
    }
  }, [state.ppp_loan_amt, state.non_wages_max, state.other_wages_amt])

  const minDate = new Date(2019, 12, 1)
  const updateDate = (date, key) => setState(c => ({ ...c, [key]: date }))

  React.useEffect(() => setPPPLoansState(state), [state])
  React.useEffect(() => console.log(state), [state])

  // generates [8, 9, ..., 24]
  const weeks_options = Array.from({ length: 17 }, (_, i) => i + 8);

  return (
    <Form>
      <h2 className="text-center">PPP Questionnaire #{index + 1}</h2>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th className="all-table-head">Details</th>
            <th className="all-table-head">Your Info</th>
          </tr>
          <tr>
            <th className="">PPP Start</th>
            <td>
              <DatePicker
                selected={state.ppp_start}
                onChange={date => updateDate(date, "ppp_start")}
                minDate={minDate}
              />
            </td>
          </tr>
          <tr>
            <th className="">8 to 24 weeks?</th>
            <td>
              <Dropdown>
                <Dropdown.Toggle className="add-sus" id="dropdown-basic">
                  {state.weeks}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {weeks_options.map(option => (
                    <Dropdown.Item
                      key={option}
                      name={option.toString()}
                      value={option.toString()}
                      onClick={e => updateFieldDropdown(e, "weeks")}
                    >
                      {option}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
          <tr>
            <th className="">PPP End</th>
            <td>
              <DatePicker
                selected={state.ppp_end}
                onChange={date => updateDate(date, "ppp_end")}
                minDate={minDate}
              />
            </td>
          </tr>

          <tr>
            <th className="">PPP Loan Amount</th>
            <td>
              <Form.Control
                type="number"
                placeholder=""
                name="ppp_loan_amt"
                value={state.ppp_loan_amt}
                onChange={e => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="">Have you APPLIED for forgiveness yet?</th>
            <td>
              <Dropdown>
                <Dropdown.Toggle className="add-sus" id="dropdown-basic">
                  {state.apply_forgive}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    name="Yes"
                    value="Yes"
                    onClick={e => updateFieldDropdown(e, "apply_forgive")}
                  >
                    Yes{" "}
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="No"
                    value="No"
                    onClick={e => updateFieldDropdown(e, "apply_forgive")}
                  >
                    No{" "}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
          <tr>
            <th className="">Have you RECIEVED forgiveness yet?</th>
            <td>
              <Dropdown>
                <Dropdown.Toggle className="add-sus" id="dropdown-basic">
                  {state.rece_forgive}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    name="Yes"
                    value="Yes"
                    onClick={e => updateFieldDropdown(e, "rece_forgive")}
                  >
                    Yes{" "}
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="No"
                    value="No"
                    onClick={e => updateFieldDropdown(e, "rece_forgive")}
                  >
                    No{" "}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
          <tr>
            <th className="">
              How much of the loan did you attribute to other wages (retirement,
              insurance, payroll costs, etc)?{" "}
            </th>
            <td>
              <Form.Control
                type="number"
                placeholder="$"
                name="other_wages_amt"
                value={state.other_wages_amt}
                onChange={e => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="">Please list all the other wages used</th>
            <td>
              <Form.Control
                as="textarea"
                rows={3}
                value={state.other_wages}
                onChange={e =>
                  setState(c => ({ ...c, other_wages: e.target.value }))
                }
              />
            </td>
          </tr>
          <tr>
            <th className="">
              How much of the loan did you attribute to qualified non-wages
              (rent, interest, etc)?{" "}
            </th>
            <td>
              <Form.Control
                type="number"
                placeholder="$"
                name="non_wages_amt"
                value={state.non_wages_amt}
                onChange={e => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className="">
              Please list all the qualifying costs other than wages used
            </th>
            <td>
              <Form.Control
                as="textarea"
                rows={3}
                value={state.list_wages}
                onChange={e =>
                  setState(c => ({ ...c, list_wages: e.target.value }))
                }
              />
            </td>
          </tr>
          <tr>
            <th className="">PPP Loan Number</th>
            <td>
              <Form.Control type="number" name="ppp_loan_num" value={state.ppp_loan_num} onChange={e => updateField(e)} />
            </td>
          </tr>
          <tr>
            <th className="">Non-Wages Max </th>
            <td>{state.non_wages_max}</td>
          </tr>
          <tr>
            <th className="">Wages which must be used for PPP Forgiveness </th>
            <td>{state.wages_for_ppp}</td>
          </tr>
        </tbody>
      </table>
    </Form>
  )
}

