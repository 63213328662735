// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from 'react'
import { CSVReader } from 'react-papaparse'
import { submitToAPI } from './APIFunctions'
import CsvTable from './CSVComponents/CsvTable'
import PPPNameRelation from './PPPNameRelation'
import moment from 'moment'
import StepWizard from "react-step-wizard";
import SubmitCsv from './CSVComponents/SubmitCsv'

function handleOnDrop(data, setCsvHeaders, setRecords) {
  const headers = data[0].meta.fields
  setCsvHeaders(headers)
  setRecords(data)
  console.log("File Drop", data)
}

function handleOnError(err, file, inputElem, reason) {
  console.log("Error", err, file, inputElem, reason)
}

function handleOnRemoveFile(data) {
  console.log("Remove File", data)
}

const initialColumnHeaders = [
  { name: 'Name', field: 'name', index: undefined, process: undefined },
  { name: 'Pay Date', field: 'pay_date', index: undefined, process: e => moment(e).format('YYYY-MM-DD') },
  { name: 'Wages', field: 'wages', index: undefined, process: undefined },
  { name: 'Healthcare Premiums Paid By Employer', field: 'hp_employer', index: undefined, process: undefined },
  // { name: 'Insurance Paid By Employer', field: 'insurance_employee', index: undefined, process: undefined },
  // { name: 'Retirement Contributions Paid By Employer', field: 'retirement_employer', index: undefined, process: undefined },
  // { name: 'Other Credits', field: 'other_credits', index: undefined, process: undefined },
]

function selectFromRecord(record, index) {
  return record.data[index]
}

// Unique Array Values
function unique(value, index, self) {
  return self.indexOf(value) === index
}

function getNameValues(records, headers) {
  // Get first columneHeader
  const columnHeaderIndex = headers[0].index
  const names = records.map(record => selectFromRecord(record, columnHeaderIndex))
  // Get unique values from names
  const uniqueNames = names.filter(unique)
  return uniqueNames
}

// Process the CSV file and return the headers and records
function processRecords({ headers, records }) {
  const output_array = records.map(record => {
    const output_hash = {}
    headers.forEach(header => {
      const processFunction = header.process ? header.process : (e => e)

      if (record.data[header.index] == undefined || record.data[header.index] == "") {
        return
      }
      if (header.field == "name" || header.field == "pay_date") {
        // Return if record.data[header.index] is undefined or blank
        output_hash[header.field] = processFunction(record.data[header.index])

      } else {
        // Check if record.data[header.index] is a number
        if (record.data[header.index]) {
          // Remove $ and , from the number and convert to double
          output_hash[header.field] = parseFloat(record.data[header.index].replace(/[$,]/g, ''))
        } else {
          // Set number to 0 if it is not a number
          output_hash[header.field] = 0
        }
      }
    })
    return output_hash
  })

  return output_array
}

// Upload Wage Records
function uploadWageRecords(records, headers, form_authenticity_token, ertc_filing_id) {
  const wage_records = processRecords({ headers, records })
  // Remove any with empty name or pay_date from wage_records
  const wage_records_filtered = wage_records.filter(record =>
    (record.name != undefined && record.pay_date != undefined)
  )

  const wageRecords = {
    ertc_filing_id,
    wage_records: wage_records_filtered
  }

  console.log("Wage Records", wageRecords)

  // Submit the records to the server
  submitToAPI('/wage_records/import', 'POST', form_authenticity_token, wageRecords, (e) => console.log(e))
}


// Import Employee Data
function EmployeeImportStep({
  nameValues,
  form_authenticity_token,
  ertc_filing_id,
  nextStep = () => { },
  previousStep = () => { },
}) {
  // Get Employees from REST API /employees?ertc_filing_id=
  const [employees, setEmployees] = React.useState([])

  React.useEffect(() => {
    const fetchEmployees = async () => {
      const response = await fetch(`/employees.json?ertc_filing_id=${ertc_filing_id}`)
      const json = await response.json()
      console.log(json)
      setEmployees(json)
    }
    fetchEmployees()
  }, [nameValues])

  return (
    <div className="all-font text-center">
      <h3>Assign Employee Relationships</h3>
      <PPPNameRelation
        existing={employees}
        people={nameValues}
        form_authenticity_token={form_authenticity_token}
        ertc_filing_id={ertc_filing_id}
      />
      <button className="btn back-btn" onClick={() => previousStep()}>Go Back</button>
      <button className="btn next-btn" onClick={() => nextStep()}>Continue</button>
    </div>
  )
}

// Load CSV File
function CsvImportStep({ csvReaderOptions,
  nextStep = () => { },
  records = [],
}) {
  return (
    <div className="all-font text-center">
      <h3>Load Wage Records</h3>
      <CSVReader {...csvReaderOptions}>
        <span>Click to upload.</span>
      </CSVReader>
      {records.length > 0 && <button className="btn next-btn my-4" onClick={() => nextStep()}>Next</button>}
    </div>
  )
}

// Import Wages
function WageImportStep({
  csvHeaders,
  columnHeaders,
  records,
  setColumnHeaders,
  nextStep = () => { },
  previousStep = () => { },
}) {
  return (
    <div className="all-font text-center">
      <h3>Map Your Data</h3>
      {csvHeaders.length > 0 && (
        <CsvTable
          headers={columnHeaders}
          csvHeaders={csvHeaders}
          records={records}
          setHeaders={setColumnHeaders}
        />
      )}
      <div className="py-3">
        <button className="btn back-btn" onClick={() => previousStep()}>Go Back</button>
        <button className="btn next-btn" onClick={() => nextStep()}>Continue</button>
      </div>
      {/* <button onClick={() => uploadWageRecords(records, columnHeaders, form_authenticity_token, ertc_filing_id)}>Submit Wages</button> */}
    </div>
  )
}

// Submit Step
function SubmitStep({ previousStep = () => { }, records, employees, form_authenticity_token, ertc_filing_id, columnHeaders }) {
  const [complete, setComplete] = React.useState(false)

  return (
    <div className="text-center">
      <h3>Upload Your Data</h3>
      <SubmitCsv
        records={records}
        employees={employees}
        form_authenticity_token={form_authenticity_token}
        ertc_filing_id={ertc_filing_id}
        columnHeaders={columnHeaders}
        setComplete={setComplete}
        complete={complete}
      />
      <button className="btn back-btn" onClick={() => previousStep()}>Go Back</button>
      {complete && (
        <a className="btn next-btn" href={`/ertc_filings/${ertc_filing_id}`}>Finish</a>
      )}
    </div>
  )
}

export default function CSVImporter({ form_authenticity_token, ertc_filing_id }) {
  const [records, setRecords] = React.useState<Array<any>>([])
  const [columnHeaders, setColumnHeaders] = React.useState<Array<any>>(initialColumnHeaders)
  const [csvHeaders, setCsvHeaders] = React.useState<Array<any>>([])

  const csvReaderOptions = {
    config: { header: true, },
    onDrop: data => handleOnDrop(data, setCsvHeaders, setRecords),
    onError: handleOnError,
    handleOnRemoveFile: handleOnRemoveFile,
    addRemoveButton: true,
    preview: 10
  }

  const nameValues = getNameValues(records, columnHeaders)

  React.useEffect(() => console.log(nameValues), [nameValues])

  return (
    <StepWizard>
      <CsvImportStep
        records={records}
        csvReaderOptions={csvReaderOptions}
      />
      <WageImportStep
        records={records}
        csvHeaders={csvHeaders}
        columnHeaders={columnHeaders}
        setColumnHeaders={setColumnHeaders}
      />
      <EmployeeImportStep
        nameValues={nameValues}
        form_authenticity_token={form_authenticity_token}
        ertc_filing_id={ertc_filing_id}
      />
      <SubmitStep
        records={records}
        employees={nameValues}
        form_authenticity_token={form_authenticity_token}
        ertc_filing_id={ertc_filing_id}
        columnHeaders={columnHeaders}
      />
    </StepWizard>
  )
}