// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react"
import ReactTooltip from "react-tooltip"

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min
}

export function TooltipMessage({ id = undefined, message }) {
  const use_id = id ? `tip-${id}` : `tip-${getRandomArbitrary(1, 100000)}`

  return (
    <>
      <a
        className="TooltipSpan"
        data-tip
        data-for={use_id}
        data-event="click"
        style={tooltipSpanStyle}
      >
        ?
      </a>
      <ReactTooltip
        id={use_id}
        place="right"
        effect="solid"
        clickable={true}
        globalEventOff="click"
      >
        {message}
      </ReactTooltip>
    </>
  )
}

const tooltipSpanStyle = {
  color: "white",
  backgroundColor: "#999",
  borderRadius: "50%",
  margin: "0 4px",
  padding: "0px 6px",
}
