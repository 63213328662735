// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react"
import { Dropdown, DropdownButton } from "react-bootstrap"
import "../Basic941Functions/Basic941.css"

export default function PPPNameRelationRow(props) {
  const { id, name, owner, updateMethod, updateRowByKey, rowExists } = props

  const submitValue = (event) => {
    updateMethod(id, event)
  }

  //this updates the name of the dropdown
  const updateDropdown = (value): any => {
    updateRowByKey(id, { owner: value })
  }

  return (
    <tr>
      <td>
        <input type="text" name="name" id="" value={name} onChange={(event) => submitValue(event)} />
        {rowExists(name) && 'Exists'}
      </td>

      <td >
        <DropdownButton id={`dropdown-basic-button-${id}`} title={`${owner}`}>
          <Dropdown.Item
            value="Owner"
            name={`Owner-${id}`}
            onClick={() => updateDropdown("Owner")}
          >
            Owner
          </Dropdown.Item>
          <Dropdown.Item
            value="Family"
            name={`Family-${id}`}
            onClick={() => updateDropdown("Family")}
          >
            Family
          </Dropdown.Item>
          <Dropdown.Item
            value="No"
            name={`No-${id}`}
            onClick={() => updateDropdown("No")}
          >
            No
          </Dropdown.Item>
        </DropdownButton>
      </td>
    </tr>
  )
}
