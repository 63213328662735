// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react"
import { Form } from "react-bootstrap"
import "../Basic941Functions/Basic941.css"

import ReactTooltip from "react-tooltip"
import { TooltipMessage } from "../Utilities"

import { ERCPre941XContext } from "../Pre941X"

const Pre941XPart3 = () => {
  const [context, setContext]: any = React.useContext(ERCPre941XContext)

  const initialState: any = { ...context.partOne }
  const [state, setState] = React.useState(initialState)

  const calculatedInitialState: any = {}
  const [calculated, setCalculated] = React.useState(calculatedInitialState)

  const updateField = (event) => {
    event.preventDefault()
    const targetValue = parseFloat(event.target.value)

    setState((state) => ({
      ...state,
      [event.target.name]: targetValue,
    }))
  }

  const isInputNaN = (value) => {
    return isNaN(value) ? "" : value
  }

  React.useEffect(() => {
    setContext({ ...context, partThree: state })
  }, [state])

  React.useEffect(() => {
    setContext({
      ...context,
      calculated: { ...context.calculated, partThree: calculated },
    })
  }, [calculated])

  return (
    <Form className="all-font">
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th className="all-table-head">Part 3</th>
            <th className="all-table-head"></th>
            <th className="all-table-head">Calculations Left</th>
            <th className="all-table-head">Calculations Right</th>
          </tr>
          <tr>
            <th className="">3a</th>
            <th className="">
              Enter line 11c: Nonrefundable portion of employee retention credit
            </th>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="threeA"
                value={isInputNaN(state.threeA)}
                onChange={(e) => updateField(e)}
              />
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className="">3b</th>
            <th className="">
              Enter line 13d: Refundable portion of employee retention credit
            </th>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="threeB"
                value={isInputNaN(state.threeB)}
                onChange={(e) => updateField(e)}
              />
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className="">3c</th>
            <th className="">
              Enter line 21: Qualified wages for the employee retention credit{" "}
            </th>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="threeC"
                value={isInputNaN(state.threeC)}
                onChange={(e) => updateField(e)}
              />
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className="">3d</th>
            <th className="">
              Enter line 22: Qualified health plan expenses for the employee
              retention credit{" "}
            </th>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="threeD"
                value={isInputNaN(state.threeD)}
                onChange={(e) => updateField(e)}
              />
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className="">3e</th>
            <th className="">
              Enter line 24: Qualified health plan expenses allocable to
              qualified sick leave wages reported on line 23{" "}
            </th>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="threeE"
                value={isInputNaN(state.threeE)}
                onChange={(e) => updateField(e)}
              />
            </td>
            <td className="table-active"></td>
          </tr>
        </tbody>
      </table>
    </Form>
  )
}

export default Pre941XPart3