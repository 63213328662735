// # Copyright 2022 LYGS 4, Inc.
// # LYGS 4, Inc.Owns exclusive rights to all code.

import * as React from "react"
import { Form } from "react-bootstrap"
import "../Basic941Functions/Basic941.css"

import { sumFourInputs } from "../Basic941Functions/Basic941Math"

import ReactTooltip from "react-tooltip"
import { TooltipMessage } from "../Utilities"

import { ERCPost941XContext } from "../Post941X"

const Post941XPart1 = () => {
  const [context, setContext]: any = React.useContext(ERCPost941XContext)

  const initialState: any = { ...context.partOne }
  const [state, setState] = React.useState(initialState)

  const calculatedInitialState: any = {}
  const [calculated, setCalculated] = React.useState(calculatedInitialState)

  const updateField = event => {
    event.preventDefault()
    const targetValue = parseFloat(event.target.value)

    setState(state => ({
      ...state,
      [event.target.name]: targetValue,
    }))
  }

  const isNegative = value => {
    const color: string = value > 0 ? "table-danger" : "edit-field"
    return color
  }

  const isInputNaN = value => {
    return isNaN(value) ? "" : value
  }

  //Calculates one C
  React.useEffect(() => {
    const { oneB } = state

    const calcOneC: number = parseFloat((oneB * 0.0145).toFixed(2)) || 0
    setCalculated(c => ({
      ...c,
      calcOneC,
    }))
  }, [state.oneB])

  //Calculates two G
  React.useEffect(() => {
    const { oneD, oneE, oneF } = state
    const { calcOneC } = calculated

    const calcOneG: number = sumFourInputs(oneD, oneE, oneF, calcOneC)

    setCalculated(c => ({
      ...c,
      calcOneG,
    }))
  }, [calculated.calcOneC, state.oneD, state.oneE, state.oneF])

  React.useEffect(() => {
    setContext({ ...context, partOne: state })
  }, [state])

  React.useEffect(() => {
    setContext({
      ...context,
      calculated: { ...context.calculated, partOne: calculated },
    })
  }, [calculated])
  return (
    <div class="all-font">
      <h2 className="text-center">941-x Post July 2021</h2>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th className="all-table-head">Part 1</th>
            <th className="all-table-head">
              Determine the corrected employer share of Medicare tax
            </th>
            <th className="all-table-head">Calculations</th>
            <th className="all-table-head">Calculations</th>
          </tr>
          <tr>
            <th className=""> </th>
            <th className="">
              You must use this worksheet if you claimed the employee retention
              credit for wages paid after June 30, 2021, and before January 1,
              2022, on your original Form 941 and you correct any amounts used
              to figure the employee retention credit for wages paid after June
              30, 2021, and before January 1, 2022. You'll also use this
              worksheet to figure this credit if you're claiming it for the
              first time on Form 941-X. If you're a third-party payer, you must
              complete this worksheet for each client for which it is
              applicable, on a client-by-client basis.
            </th>
            <td className="table-active"></td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 1a</th>
            <th className="">
              If you completed Worksheet 3 to claim a credit for qualified sick
              and family leave wages for leave taken after March 31, 2021, enter
              the amount from Worksheet 3, Step 1, line 1f, and go to Step 2. If
              you're not claiming a credit for qualified sick and family leave
              wages for leave taken after March 31, 2021, continue by completing
              lines 1b–1g below and then go to Step 2{" "}
              <TooltipMessage
                message={
                  <p>
                    <a
                      target="_blank"
                      href="https://www.irs.gov/newsroom/determining-the-amount-of-the-tax-credit-for-qualified-sick-leave-wages"
                    >
                      Qualified sick leave wages{" "}
                    </a>{" "}
                    are wages and compensation paid to employees for periods of
                    leave during wich they are unable to work or telework due to
                    COVID-19 circumstances.{" "}
                  </p>
                }
              />
            </th>
            <td className="table-active"></td>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="oneA"
                value={isInputNaN(state.oneA)}
                onChange={e => updateField(e)}
              />
            </td>
          </tr>
          <tr>
            <th className=""> 1b</th>
            <th className="">
              Enter the amount of Medicare wages from Form 941, Part 1, line 5c,
              column 1, or, if corrected, the amount from Form 941-X, line 12,
              column 1.{" "}
              <TooltipMessage
                message={
                  <p>
                    Enter all{" "}
                    <a
                      target="_blank"
                      href="https://www.irs.gov/instructions/i941#en_US_202106_publink1000294678"
                    >
                      tips{" "}
                    </a>{" "}
                    your employees reported to you during the quarter until the
                    total of the tips and taxable wages, including wages
                    reported on line 5a, qualified sick leave wages reported on
                    line 5a(i), and qualified family leave wages reported on
                    line 5a(ii), for an employee reach $142,800 for the year.{" "}
                  </p>
                }
              />
            </th>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="oneB"
                value={isInputNaN(state.oneB)}
                onChange={e => updateField(e)}
              />
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 1c</th>
            <th className="">
              Multiply line 1b by 1.45% (0.0145){" "}
              <TooltipMessage message="In 2021, the Medicare tax rate is 1.45%" />
            </th>
            <td className="edit-field">{calculated.calcOneC}</td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 1d</th>
            <th className="">
              If you're a third-party payer of sick pay that isn't an agent and
              you're claiming credits for amounts paid to your employees, enter
              the employer share of Medicare tax included on Form 941, Part 1,
              line 8, or, if corrected, the amount of the employer share of
              Medicare tax on sick pay that you included on Form 941-X, line 15,
              column 1 (enter as a NEGATIVE number){" "}
              <TooltipMessage
                message={
                  <p>
                    For{" "}
                    <a
                      target="_blank"
                      href="https://www.irs.gov/instructions/i941"
                    >
                      line 8{" "}
                    </a>
                    - If your third-party payer of sick pay that isn't your
                    agent (for example, an insurance company) transfers the
                    liability for the employer share of the social security and
                    Medicare taxes to you, enter a negative adjustment. If
                    you’re the third-party sick pay payer and you transferred
                    the liability for the employer share of the social security
                    and Medicare taxes to the employer, enter a negative
                    adjustment. No adjustment is reported on line 8 for sick pay
                    that is paid through a third party as an employer’s agent.
                    An employer’s agent bears no insurance risk and is
                    reimbursed on a cost-plus-fee basis for payment of sick pay
                    and similar amounts. If an employer uses an agent to pay
                    sick pay, the employer reports the wages on line 5a, line
                    5c, and, if the withholding threshold is met, line 5d,
                    unless the employer has an agency agreement with the
                    third-party payer that requires the third-party payer to do
                    the collecting, reporting, and/or paying or depositing
                    employment taxes on the sick pay.
                  </p>
                }
              />
            </th>
            <td className={isNegative(state.oneD)}>
              <Form.Control
                type="number"
                placeholder="$"
                name="oneD"
                value={isInputNaN(state.oneD)}
                onChange={e => updateField(e)}
              />
            </td>
            <td className={isNegative(state.oneD)}>
              {isNegative(state.oneD) === "table-danger" ? (
                <p>Must be Negative</p>
              ) : null}
            </td>
          </tr>
          <tr>
            <th className=""> 1e</th>
            <th className="">
              Employer share of Medicare tax included on Form 941-X, line 21,
              column 4{" "}
              <TooltipMessage
                message={
                  <p>
                    <a
                      target="_blank"
                      href="https://www.irs.gov/pub/irs-pdf/f941x.pdf"
                    >
                      Form 941x{" "}
                    </a>
                    is Adjusted Employer's Quarterly Federal Tax Return or Claim
                    for Refund{" "}
                  </p>
                }
              />
            </th>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="oneE"
                value={isInputNaN(state.oneE)}
                onChange={e => updateField(e)}
              />
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 1f</th>
            <th className="">
              If you received a Section 3121(q) Notice and Demand during the
              quarter, enter the amount of the employer share of Medicare tax
              from the notice{" "}
              <TooltipMessage
                message={
                  <p>
                    A{" "}
                    <a
                      target="_blank"
                      href="https://www.irs.gov/pub/irs-drop/rr-12-18.pdf"
                    >
                      {" "}
                      Section 3121(q) Notice and Demand{" "}
                    </a>{" "}
                    is made by the IRS when it advises the employer in writing
                    of the amount of tips received by the employee (or
                    employees) who failed to report or under-reported tips to
                    the employer. "
                  </p>
                }
              />
            </th>
            <td className="edit-field">
              <Form.Control
                type="number"
                placeholder="$"
                name="oneF"
                value={isInputNaN(state.oneF)}
                onChange={e => updateField(e)}
              />
            </td>
            <td className="table-active"></td>
          </tr>
          <tr>
            <th className=""> 1g</th>
            <th className="">
              Employer share of Medicare tax. Combine lines 1c, 1d, 1e, and 1f
            </th>
            <td
              className={
                calculated.calcOneG < 0 ? "table-danger" : "table-active"
              }
            >
              {calculated.calcOneG < 0
                ? "1g cannot be negative, please check your inputs."
                : ""}
            </td>
            <td className="edit-field">{calculated.calcOneG}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Post941XPart1
