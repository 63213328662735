export const CalculationSubtract = (x, y): number => x - y

export const CalculationPercentageInDifference = (x, y): number => {
  const value = parseFloat(((CalculationSubtract(x, y) / x) * 100).toFixed(2))
  return value
}
export const CalculationSubtractionAndPercent = (x, y): Array<number> => {
  const subtracted = CalculationSubtract(x, y)
  const percentage = CalculationPercentageInDifference(x, y)
  return [subtracted, percentage]
}

// Functions to verify qualification by percentage
export const CalculationColorFifty = (percent) => percent > 50
export const CalculationColorTwenty = (percent) => percent >= 20

// TODO: Make sure we don't need this
//export const CalculationColorEighty = (percent) => percent > 80;
